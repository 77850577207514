import React, { useState, useEffect } from "react";
import "./SignUpPage.css";
import { Link, useNavigate } from "react-router-dom";
import makeHttpRequest from "../../utils/api";
import AddressAutocomplete from "../../Components/AddressAutocomplete";
import ExtensionApi from "../../Components/ExtensionApi";
import RegisterImage from "../../Components/RegisterImage";
import { useLanguage } from "../../utils/LanguageContext";
import { googleMapApiKey } from "../../utils/helpers";
import Toaster from "../../Components/Toaster";
import Navbar from "../../Components/Navbar/Navbar";

function SignUpPage() {
  const navigate = useNavigate();
  const [countries, setCountries] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirmation, setPasswordConfirmation] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [nameError, setNameError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [captchaError, setCaptchaError] = useState("");
  const [country_code, setCountryCode] = useState("IT");
  const [isCaptchaPassed, setIsCaptchaPassed] = useState(false);
  const { translate } = useLanguage();

  const emailIsValid = (email) => {
    if (!/\S+@\S+\.\S+/.test(email)) {
      return { status: false, msg: "Enter Valid Email" };
    } else {
      return { status: true, msg: "Valid Email" };
    }
  };

  const passwordIsValid = (password) => {
    if (password.length < 8) {
      return {
        status: false,
        msg: translate("Password must be greater than 8 characters"),
      };
    } else if (password !== password_confirmation) {
      return { status: false, msg: translate("Confirm Password must be same") };
    } else {
      return { status: true, msg: translate("Valid Password") };
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    // Clear previous errors
    setNameError("");
    setEmailError("");
    setAddressError("");
    setPhoneError("");
    setPasswordError("");
    // setCaptchaError("");

    // Validate required fields and set error messages
    if (!name.trim()) {
      setNameError(translate("Please enter your name"));
    }
    if (!email.trim()) {
      setEmailError(translate("Please enter your email"));
    }
    if (!address) {
      setAddressError(translate("Please select an address"));
    }
    if (!phone.trim()) {
      setPhoneError(translate("Please enter your phone number"));
    }
    if (!password.trim()) {
      setPasswordError(translate("Please create a password"));
    }
    if (!password_confirmation.trim()) {
      setPasswordError(translate("Please confirm your password"));
    }

    // Return if there are validation errors
    if (
      nameError ||
      emailError ||
      addressError ||
      phoneError ||
      passwordError
    ) {
      return;
    }

    // Validate email
    let valid = emailIsValid(email);
    if (!valid.status) {
      setEmailError(valid.msg);
      return;
    }

    // Validate password
    valid = passwordIsValid(password);
    if (!valid.status) {
      setPasswordError(valid.msg);
      return;
    }

    if (!isCaptchaPassed) {
      Toaster("warning", translate("Captcha Required"));
      return;
    }

    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("address", address);
      formData.append("mobile", phone);
      formData.append("country", country_code.toUpperCase());
      formData.append("password", password);
      formData.append("password_confirmation", password_confirmation);

      const response = await makeHttpRequest("POST", "register", formData);
      if (response.success) {
        navigate("/login");
      }
    } catch (error) {
      console.error("Error is :", error);
    }
  };

  useEffect(() => {
    const fetchCountryData = async () => {
      try {
        const res = await makeHttpRequest("GET", "country");
        setCountries(res.data.records);
      } catch (error) {
        console.error("Error is:", error);
      }
    };
    fetchCountryData();
  }, []);

  const handlePlaceChanged = (place) => {
    setAddress(place.formatted_address);
    const countryAddressComponent = place.address_components?.find(
      (addressComponent) => addressComponent.types[0] === "country"
    );
    if (countryAddressComponent) {
      setCountryCode(countryAddressComponent.short_name);
    }
  };

  return (
    <>
      <Navbar />
      <div className="p-5">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 signUp-content-wrapper">
            <div className="signUp-heading">
              <img src="/assets/icons/companyLogo.png" alt="..." />
              <div className="SignUpHeading-topIcon">
                <img src="/assets/icons/aa1.png" alt="..." />
              </div>
              <div className="SignUpHeading-bottomIcon">
                <img src="/assets/icons/aa2.jpeg" alt="..." />
              </div>
              <h1>{translate("Welcome to CAPRIEASY")}</h1>
              <div className="signUp-email">
                <label>{translate("Full name")}</label>
                <input
                  required
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                  placeholder={translate("Enter your name")}
                />
                <p className="error-message text-danger font-weight-bold">
                  {nameError}
                </p>
              </div>

              <div className="signUp-email">
                <label>{translate("Email")}</label>
                <input
                  required
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  placeholder={translate("Enter your email")}
                />
                <p className="error-message text-danger font-weight-bold">
                  {emailError}
                </p>
              </div>
              <div className="signUp-email">
                <label>{translate("Address")}</label>
                <AddressAutocomplete
                  apiKey={googleMapApiKey()}
                  onPlaceChanged={handlePlaceChanged}
                  AddressSet={setAddress}
                  placeHolder={translate("Enter a location")}
                />
                <p className="error-message text-danger font-weight-bold">
                  {addressError}
                </p>
              </div>
              {country_code && (
                <div className="signUp-phone">
                  <label>{translate("Phone")}</label>
                  <div className="input-group">
                    <div className="input-group-addon">
                      <select
                        className="text-center"
                        disabled
                        onChange={(e) => setCountry(e.target.value)}
                      >
                        {Object.keys(countries).map(
                          (code, i) =>
                            countries[code].code ===
                              country_code.toUpperCase() && (
                              <option
                                key={i}
                                value={code}
                                selected={
                                  countries[code].selected ? true : false
                                }
                              >
                                {/* <img style={{ width: '24px', marginRight: '5px' }} src={`https://flagcdn.com/w320/${countries[code].code.toLowerCase()}.png`} alt={`${countries[code].country} flag`}/> */}
                                {countries[code].dial}
                              </option>
                            )
                        )}
                      </select>
                    </div>
                    <input
                      required
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      type="number"
                      placeholder={translate("Enter Phone")}
                      min="111"
                    />
                  </div>
                  <p className="error-message text-danger font-weight-bold">
                    {phoneError}
                  </p>
                </div>
              )}
              <div className="signUp-password">
                <label>{translate("Password")}</label>
                <input
                  required
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                  placeholder={translate("Create a password")}
                />
                <p className="error-message text-danger font-weight-bold">
                  {passwordError}
                </p>
              </div>
              <div className="signUp-password">
                <label>{translate("Confirm Password")}</label>
                <input
                  required
                  onChange={(e) => setPasswordConfirmation(e.target.value)}
                  type="password"
                  placeholder={translate("Confirm password")}
                />

                <p className="text-info font-weight-bold">
                  {translate("Must be at least 8 characters")}
                </p>
              </div>
              <div className="signUp-password">
                <ExtensionApi
                  googlerecaptcha2="google-recaptcha2"
                  CaptchaPassed={setIsCaptchaPassed}
                />
              </div>
              <div className="signUp-btn mt-1">
                <button onClick={handleRegister}>
                  {translate("Get Started")}
                </button>
              </div>
              <div className="google-signUp">
                {/* <button>
                <img src="/assets/icons/googleIcon.png" alt="..." />
                <p>{translate("Sign in with Google")}</p>
              </button> */}
              </div>
              <div className="signIn-link">
                <p>
                  {translate("Already have an account?")}{" "}
                  <Link
                    style={{ fontSize: "16px", color: "#45AAF0" }}
                    to="/login"
                  >
                    {translate("Sign In")}
                  </Link>
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-6  d-none d-lg-block d-md-block">
            <RegisterImage type={"register"} />
          </div>
        </div>
      </div>
    </>
  );
}

export default SignUpPage;
