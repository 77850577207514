import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useState } from "react";
import makeHttpRequest from "../../../utils/api";
import Toaster from "../../../Components/Toaster";

const CheckoutForm = (prop) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    try {
      if (elements == null) {
        return;
      }
      const { error: submitError } = await elements.submit();
      if (submitError) {
        setErrorMessage(submitError.message);
        return;
      }

      setIsLoading(true);
      const res = await makeHttpRequest("POST", prop.intent, {
        trx: prop.trx,
      });
      const { clientSecret } = res.data;

      // const stripe = await stripePromise;
      const { error } = await stripe.confirmPayment({
        elements,
        clientSecret,
        confirmParams: {
          return_url:
            prop.redirect_url !== ""
              ? prop.redirect_url
              : window.location.protocol +
                "//" +
                window.location.hostname +
                "/booking-confirmed",
        },
      });
      setIsLoading(false);

      if (error) {
        console.log("error:", error);
        setErrorMessage(error.message);
        Toaster("error", error.message);
      } else {
        Toaster("success", "Payment");
      }
    } catch (err) {
      console.log("err:", err);
      setIsLoading(false);
    }
  };

  return (
    <>
      <PaymentElement />
      <button
        className="btn btn-outline-primary d-block py-3 font-weight-bold mt-3 w-100"
        type="submit"
        disabled={!stripe || !elements || isLoading}
        onClick={handleSubmit}
      >
        {isLoading ? "Loading..." : "Pay now"}
      </button>
      {errorMessage && <div>{errorMessage}</div>}
    </>
  );
};

export default CheckoutForm;