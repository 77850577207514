export function showAmount(amount, decimal = 2, separate = true, exceptZeros = false) {
    // Convert the amount to a float with the specified decimal places
    const formattedAmount = parseFloat(amount).toFixed(decimal);
  
    if (exceptZeros) {
      const [integerPart, decimalPart] = formattedAmount.split('.');
      if (parseInt(decimalPart, 10) === 0) {
        return integerPart;
      }
    }
  
    if (separate) {
      // Format the number with thousands separator
      return parseFloat(formattedAmount).toLocaleString('en-US');
    }
  
    return formattedAmount;
}