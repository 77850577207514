import React, { useRef, useState } from "react";
import { BiPaperclip, BiSend, BiSmile } from "react-icons/bi";
import Picker from "@emoji-mart/react";
import data from "@emoji-mart/data";
import { Link } from "react-router-dom";
import makeHttpRequest from "../../../../utils/api";

function InputMessage({
  item,
  id,
  inputState,
  inputIframeState,
  sidebar,
  selectedUser,
  setUserSentMessage,
  setAttachedFile,
  chats,
  setChats,
  classes,
  type,
}) {
  const { inputText, setInputText } = inputState;
  const { inputIframe, setInputIframe } = inputIframeState;
  const [showEmojiPicker, setShowEmojiPicker] = useState(false); // State to manage emoji picker visibility
  const textareaRef = useRef(null);

  const handleSendMessage = async (text) => {
    if (!selectedUser) return;

    try {
      let text_data = {
        item: item,
        id,
        type: type, // Ensure type is being sent
        user: selectedUser,
        content: text,
      };
      if (inputIframe) {
        text_data.meta = { iframe: inputIframe };
      }
      const res = await makeHttpRequest(
        "POST",
        `user/chat/message/store`,
        text_data
      );

      if (res.success) {
        setShowEmojiPicker(false);
        setInputIframe("");
        // setConversations(res?.data?.chat);
        // setSelectedUser(res?.data?.chat[0]);
      } else if (!res.success) {
      } else {
      }
    } catch (error) {
      console.error("Error is:", error);
    }

    // setChats(prevMessages => ({
    //   ...prevMessages,
    //   [selectedUser.id]: [...(prevMessage || []), newMessage]
    // }));
    setUserSentMessage(true); // Set the flag when the user sends a message
    setInputText("");
  };

  // Adjust height of textarea as the user types
  const handleInputChange = (e) => {
    setInputText(e.target.value);

    const textarea = textareaRef.current;
    // Reset height to auto before adjusting
    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`; // Adjust the height to fit the content
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    setAttachedFile(file);
  };

  const toggleEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };

  const handleEmojiSelect = (emoji) => {
    setInputText(inputText + emoji.native); // Add emoji to text
    const textarea = textareaRef.current;
    textarea.style.height = "auto"; // Reset height before adjusting
    textarea.style.height = `${textarea.scrollHeight}px`; // Adjust the height to fit the content
  };

  return (
    <div className={classes["chat-input-wrapper"]}>
      <div className={classes["chat-input"]}>
        <div className={classes["text-area-container"]}>
          <textarea
            ref={textareaRef}
            placeholder="Type a message..."
            value={inputText}
            disabled={!selectedUser}
            onChange={handleInputChange}
            className={classes["text-area"]}
            rows={1} // Initial rows
          />
          <div className={classes["text-area-actions"]}>
            {/* Emoji Button */}
            <BiSmile
              // style={{ fontSize: "20px", cursor: "pointer", color: "#666" }}
              style={{
                fontSize: "20px",
                cursor: "pointer",
                color: "#666",
                position: "absolute",
                bottom: "10px",
                right: "50px", // Adjust to position left of the send button
              }}
              onClick={toggleEmojiPicker}
            />
            {showEmojiPicker && (
              <div className={classes["emoji-container"]}>
                <Picker
                  data={data}
                  previewPosition="none"
                  onEmojiSelect={handleEmojiSelect}
                />
              </div>
            )}
            <BiSend
              // style={{ fontSize: "20px", cursor: "pointer", color: "#666" }}
              style={{
                fontSize: "20px",
                cursor: "pointer",
                color: "#666",
                position: "absolute",
                bottom: "10px",
                right: "20px", // Adjust to position left of the send button
              }}
              onClick={() => handleSendMessage(inputText)}
              disabled={!selectedUser}
            />
          </div>
          {/* Send Button */}
          {/* <div className={classes["send-button-container"]}>
            <button
              className={classes["send-button"]}
              onClick={() => handleSendMessage(inputText)}
              disabled={!selectedUser}
              style={{
                background: "transparent",
                border: "none",
                cursor: "pointer",
              }}
            >
              <BiSend style={{ fontSize: "24px", color: "#007bff" }} />
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
}
export default InputMessage;
