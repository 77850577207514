import React from "react";
import ReactDOM from "react-dom/client";
import "./main.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { Provider } from "react-redux";
import { store } from "./store";
import { LanguageProvider } from "./utils/LanguageContext";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { googleLoginApiKey } from "./utils/helpers";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <GoogleOAuthProvider clientId={googleLoginApiKey()}>
    <Provider store={store}>
      <LanguageProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
        <ToastContainer />
      </LanguageProvider>
    </Provider>
  </GoogleOAuthProvider>
);
