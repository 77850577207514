import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useLanguage } from '../../../../utils/LanguageContext';
import makeHttpRequest from '../../../../utils/api';

function NickName({ show, handleClose, handleShow, chat_person_id}) {
  const { langData, fetchLangData, translate } = useLanguage();
  const [nick_name, setNickName] = useState();

  async function submitNewName(){
    const res = await makeHttpRequest("POST", `owner/chat-info`,{nick_name,chat_person_id});
    
    if(res?.success){}else{
      console.log(res?.message);
    }

  }
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>{translate('Set Nick Name')}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div>
          <input type="text" 
            value={nick_name}
            onChange={(e) => setNickName(e.target.value)} 
            name="nick_name" 
            placeholder={translate('Enter Nick Name')} 
            className="form-control" />
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          {translate('Close')}
        </Button>
        <Button variant="primary" onClick={submitNewName}>
          {translate('Save changes')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default NickName;
