import React from "react";
import "./AccomodationIndividuals.css";
import { FaMapMarkerAlt } from "react-icons/fa";
import { BsStarFill, BsFillHeartFill } from "react-icons/bs";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Link, useParams } from "react-router-dom";
import { BsFillStarFill } from "react-icons/bs";
import Footer from "../../Components/Footer/Footer";
import FastBoatCards from "../../Components/FastBoatCards/FastBoatCards";
import { BsArrowRightShort, BsShare } from "react-icons/bs";
import { IoChevronForwardSharp } from "react-icons/io5";
import { useRef, useState } from "react";
import { useEffect } from "react";
import makeHttpRequest from "../../utils/api";
import { CircularProgress, Stack } from "@mui/material";
import Navbar from "../../Components/Navbar/Navbar";
import { showAmount } from "../../utils/showAmount";
import { BiCheck } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { MdClose, MdNavigateNext } from "react-icons/md";
import Toaster from "../../Components/Toaster";
import { useDispatch } from "react-redux";
import { userLogged } from "../../UserDashboardComponent/UserProfile/userSlice";
import { useLanguage } from "../../utils/LanguageContext";
// import DateRangeInput from "../Datepicker/DatePicker";

const style = {
  // position: "absolute",
  // top: "40%",
  // left: "50%",
  // transform: "translate(-50%, -50%)",
  width: "40%",
  bgcolor: "background.paper",
  boxShadow: "0px 4px 32px 0px rgba(26, 26, 26, 0.10)",
  borderRadius: "16px",
  border: "none",
  outline: "none",
};

function Rooms() {
  const navigate = useNavigate();
  const { id, slug, room_id } = useParams();
  const [details, setDetails] = useState("");

  let firstImg = details?.property?.images ? details?.property?.images[0] : "";
  let secondImg = details?.property?.images ? details?.property?.images[1] : "";
  let thirdImg = details?.property?.images ? details?.property?.images[2] : "";
  let fourthImg = details?.property?.images ? details?.property?.images[3] : "";
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const usernameRef = useRef(null);
  const passwordRef = useRef(null);
  const [state, setState] = useState({
    username: "",
    password: "",
  });
  const { langData, fetchLangData, translate } = useLanguage();

  // Calendar Variable
  const [checkInDate, setCheckInDate] = useState("");
  const [checkOutDate, setCheckOutDate] = useState("");
  const [days, setDays] = useState(0);
  const [platform_fee, setPlatformFee] = useState(0);
  const [room_price, setRoomPrice] = useState(0);
  const [selected_room_id, setRoomId] = useState(0);
  const [error, setError] = useState("");
  const [offerSent, setOfferSent] = useState(false);

  const handleCheckInChange = (event) => {
    setCheckInDate(event.target.value);
    calculateDateDifference(event.target.value, checkOutDate);
  };

  const handleCheckOutChange = (event) => {
    setCheckOutDate(event.target.value);
    calculateDateDifference(checkInDate, event.target.value);
  };

  const calculateDateDifference = (checkIn, checkOut) => {
    if (checkIn && checkOut) {
      const checkInDateObj = new Date(checkIn);
      const checkOutDateObj = new Date(checkOut);

      if (checkOutDateObj <= checkInDateObj) {
        alert("Check-out date must be later than check-in date");
        setDays(0);
        setCheckOutDate(checkIn + 1);
      } else {
        const differenceInMilliseconds = checkOutDateObj - checkInDateObj;
        const differenceInDays =
          differenceInMilliseconds / (1000 * 60 * 60 * 24);
        setDays(differenceInDays);
      }
    } else {
      setDays(0);
    }
  };

  const handleOpen = async (event) => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (id) {
      getData();
    }
  }, []);

  const getData = async () => {
    try {
      setLoading(true);
      const res = await makeHttpRequest(
        "GET",
        `property/${id}/${slug}/room-category/${room_id}`
      );
      setDetails(res.data);
      setLoading(false);
    } catch (error) {
      console.error("Error is:", error);
      setLoading(false);
    }
  };
  const getInquiry = async () => {
    try {
      setLoading(true);
      const res = await makeHttpRequest("GET", `property/${id}/${slug}`);
      setDetails(res.data);
      setLoading(false);
    } catch (error) {
      console.error("Error is:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getInquiry();
  }, []);

  function generateSlug(inputString) {
    const slug = inputString.toLowerCase().replace(/[^a-z0-9]+/g, "-");
    return slug.replace(/^-+|-+$/g, "");
  }

  const handleDetails = (item) => {
    setRoomPrice(item?.price);
    setRoomId(item?.id);
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const handleLogin = async () => {
    try {
      if (!state.username) {
        Toaster("info", "Enter username");
        return;
      }
      if (!state.password) {
        Toaster("info", "Enter password");
        return;
      }
      setLoading(true);
      const data = {
        username: state.username,
        password: state.password,
        user_type: "user",
      };
      const res = await makeHttpRequest("POST", "login", data);

      setLoading(false);
      if (res.success === true) {
        localStorage.setItem("bearerToken", res.data.token);
        localStorage.setItem("userType", res.data.type);
        localStorage.setItem("userEmail", res.data.user.email);

        Toaster("success", res.message);
        dispatch(userLogged("user"));
        window.reload();
      } else {
        Toaster("error", "Invalid credentials");
      }
    } catch (error) {
      console.error("Error is :", error);
      setLoading(false);
    }
  };

  const handleEnter = (name) => {
    if (name == "username") {
      if (!state.username) {
        Toaster("info", "Enter email");
      } else if (state.username && !state.password) {
        passwordRef.current.focus();
      }
      if (state.username && state.password) {
        handleLogin();
      }
    } else {
      if (!state.password) {
        Toaster("info", "Enter password");
      } else if (!state.username && state.password) {
        usernameRef.current.focus();
      }
      if (state.username && state.password) {
        handleLogin();
      }
    }
  };

  const handleInquiry = async () => {
    if (selected_room_id > 0) {
      if (!details?.property?.is_complete) {
        if (checkInDate && checkOutDate) {
          try {
            setLoading(true);
            const res = await makeHttpRequest("POST", `user/property/booking`, {
              room_list: selected_room_id,
              start: checkInDate,
              end: checkOutDate,
              days: days,
            });
            Toaster("success", res.message);
            setOfferSent(true);
            setLoading(false);
          } catch (error) {
            console.error("Error is:", error);
            setLoading(false);
          }
        } else {
          Toaster("error", "Select Check in and Check out date");
        }
      } else {
        console.log("console");
      }
    } else {
      Toaster("error", "Select any room");
    }
  };

  return (
    <>
      <Navbar />
      {loading && (
        <Stack height="80vh" alignItems="center" justifyContent="center">
          <CircularProgress size={100} sx={{ color: "#8bc53e" }} />
        </Stack>
      )}
      {!loading && details && details?.property?.length === 0 && (
        <Stack my={5} alignItems="center" justifyContent="center">
          <h2>{details?.empty_message}</h2>
        </Stack>
      )}
      {!loading && (
        <div className="container">
          <Modal
            open={open}
            // onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div className="modal-wrapper">
              <Box sx={style}>
                <div className="modal-heading">
                  <h1>Login</h1>
                  <MdClose
                    onClick={handleClose}
                    style={{
                      color: "#FFF",
                      cursor: "pointer",
                      fontSize: "38px",
                      paddingRight: "10px",
                    }}
                  />
                </div>
                <div className="modal-inputs">
                  <div className="scrollable-content">
                    <div className="form-group">
                      <label>User name</label>
                      <input
                        type="username"
                        name="username"
                        className="form-control-sm"
                        placeholder="Enter Username"
                        ref={usernameRef}
                        value={state.username}
                        onChange={handleInput}
                        onKeyDown={(e) => {
                          if (e.key == "Enter") {
                            handleEnter("username");
                          }
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <label>Password</label>
                      <input
                        type="password"
                        name="password"
                        className="form-control-sm"
                        placeholder="Enter your password"
                        ref={passwordRef}
                        value={state.password}
                        onChange={handleInput}
                        onKeyDown={(e) => {
                          if (e.key == "Enter") {
                            handleEnter("password");
                          }
                        }}
                      />
                    </div>
                    <div className="mt-1">
                      <button className="btn btn-primary" onClick={handleLogin}>
                        Login
                      </button>
                    </div>
                  </div>
                </div>
              </Box>
            </div>
          </Modal>
          <div className="row">
            <div className="col-lg-12 col-md-6 d-none d-lg-block d-md-block">
              <div className="accomodationPage-links">
                <div class="d-flex flex-row justify-content-center mt-3">
                  <div class="p-1">
                    {" "}
                    <Link to="/">
                      <p className="text-black fw-bold"> {translate("Home")}</p>
                    </Link>
                  </div>
                  <div class="p-1">
                    <MdNavigateNext />
                  </div>
                  <Link to="/accomodation">
                    <div class="p-1">
                      {" "}
                      <p
                        style={{
                          color: "#000",
                          fontSize: "18px",
                          fontWeight: "bold",
                        }}
                      >
                        {translate("Accomodations")}
                      </p>
                    </div>
                  </Link>
                  <div class="p-1">
                    <MdNavigateNext />
                  </div>
                  <div class="p-1">
                    {" "}
                    <p
                      style={{
                        color: "#8bc53e",
                        fontSize: "18px",
                        fontWeight: "bold",
                      }}
                    >
                      {translate("Property Room Detail")}
                    </p>
                  </div>
                </div>
                {/* <p>
                  Home <IoChevronForwardSharp /> Property{" "}
                  <IoChevronForwardSharp />{" "}
                  <p style={{ display: "inline", textTransform: "capitalize" }}>
                    {slug.replaceAll("-", " ")}
                  </p>
                </p> */}
                <p>
                  <BsShare /> Share
                </p>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-lg-8 col-md-8 cols-sm-12">
              <div className="row border rounded-4 p-2">
                <div className="col-lg-6 col-md-6 col-12 acommondationIndividual-img">
                  <img
                    width={576}
                    height={380}
                    src={
                      firstImg
                        ? firstImg
                        : "/assets/icons/acommondationIndividual1.png"
                    }
                    alt="....."
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                  <div className="col-lg-12 col-md-6 d-none d-lg-block d-md-block acommondationIndividual-img p-0">
                    <img
                      width={576}
                      height={186}
                      src={
                        secondImg
                          ? secondImg
                          : "/assets/icons/acommondationIndividual2.png"
                      }
                      alt="....."
                    />
                  </div>
                  <div className="row" style={{ marginTop: "19px" }}>
                    <div
                      className="col-lg-6 col-md-6 d-none d-lg-block d-md-block acommondationIndividual-img"
                      alt="....."
                    >
                      <img
                        width={276}
                        height={180}
                        src={
                          thirdImg
                            ? thirdImg
                            : "/assets/icons/acommondationIndividual3.png"
                        }
                        alt="....."
                      />
                    </div>
                    <div className="col-lg-6  col-md-6 d-none d-lg-block d-md-block acommondationIndividual-img">
                      <img
                        width={276}
                        height={180}
                        src={
                          fourthImg
                            ? fourthImg
                            : "/assets/icons/acommondationIndividual4.png"
                        }
                        alt="....."
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="accommodation-right-bg border rounded-4 p-3">
                <div>
                  <div className="accommodation-ranking-wrapper">
                    <div className="accommodation-ranking">
                      {details?.property?.price > 0 && (
                        <h3>
                          {details?.curr_sym}
                          {showAmount(details?.property?.price)}{" "}
                          <span>{details?.property?.label}</span>
                        </h3>
                      )}
                    </div>
                    <div className="accommodation-reviewsRating">
                      <p>
                        <BsFillStarFill
                          style={{
                            color: "#8BC53E",
                            marginRight: "7px",
                            fontSize: "15px",
                          }}
                        />
                        <span>
                          <a href="#">
                            {showAmount(details?.property?.rating)} (
                            {details?.property?.review}) reviews
                          </a>
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-12 col-sm-12 mb-3">
                      <label className="form-label fw-bold">Check In</label>
                      <input
                        id="checkInDate"
                        type="date"
                        placeholder="Check In"
                        value={checkInDate}
                        onChange={handleCheckInChange}
                        min={new Date().toISOString().split("T")[0]}
                        className="form-control date-input"
                      />
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 mb-3">
                      <label className="form-label fw-bold">Check Out</label>
                      <input
                        id="checkOutDate"
                        type="date"
                        placeholder="Check Out"
                        value={checkOutDate}
                        onChange={handleCheckOutChange}
                        min={(function () {
                          const tomorrow = new Date();
                          tomorrow.setDate(tomorrow.getDate() + 1);
                          return tomorrow.toISOString().split("T")[0];
                        })()}
                        className="form-control date-input"
                      />
                    </div>
                    <div className="accomodation-content-para">
                    <p className="mt-2">
                      Selected Dates:{" "}
                      {checkInDate
                        ? new Date(checkInDate).toLocaleDateString()
                        : "N/A"}{" "}
                      -{" "}
                      {checkOutDate
                        ? new Date(checkOutDate).toLocaleDateString()
                        : "N/A"}
                    </p>
                    </div>
                
                  </div>


                  <div className="accomodationRent-perNight mt-3 p-0">
                    <p>
                      {details?.curr_sym}
                      {showAmount(room_price)} x {days}{" "}
                      {details?.property?.label}
                    </p>
                    <p>
                      {details?.curr_sym}
                      {parseInt(showAmount(room_price)) * parseInt(days)}
                    </p>
                  </div>
                  <div className="accomodationRent-perNight mt-2 p-0 ">
                    <p>Discount</p>
                    <p>{showAmount(details?.property?.discount, 0)}%</p>
                  </div>
                  <div className="accomodationRent-perNight mt-2 p-0 ">
                    <p>Capri Easy Service</p>
                    <p>
                      {details?.curr_sym}
                      {platform_fee}
                    </p>
                  </div>
                  <hr />
                  <div className="accomodationRent-perNight-total mt-2 p-0 ">
                    <p>Total</p>
                    <p>
                      {details?.curr_sym}
                      {showAmount(details?.property?.discount, 0) > 0
                        ? parseInt(
                            showAmount(
                              room_price -
                                (room_price *
                                  showAmount(details?.property?.discount, 0)) /
                                  100
                            )
                          ) *
                            parseInt(days) +
                          parseInt(platform_fee)
                        : parseInt(showAmount(room_price)) * parseInt(days) +
                          parseInt(platform_fee)}
                    </p>
                  </div>

                  <div className="accomdation-reserver">
                    {(details?.property?.owner?.property_inquiry === 1 ||
                      details?.property?.owner?.property_custom_inquiry ===
                        1) && (
                      <>
                        {localStorage.getItem("bearerToken") ? (
                          <>
                            {offerSent ? (
                              <>
                                <button onClick={() => navigate("/dashboard")}>
                                  Visit Dashboard
                                </button>
                              </>
                            ) : (
                              <>
                                <button onClick={handleInquiry}>
                                  {translate("Wanna Custom Offer, Contact")}
                                </button>
                                <button onClick={handleInquiry}>
                                  {translate("Send Inquiry")}
                                </button>
                                <p>{translate("You won’t be charged yet")}</p>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {offerSent ? (
                              <>
                                <button onClick={() => navigate("/dashboard")}>
                                  Visit Dashboard
                                </button>
                              </>
                            ) : (
                              <>
                                <button onClick={handleOpen}>
                                  {translate("Wanna Custom Offer, Contact")}
                                </button>
                                <button onClick={handleOpen}>
                                  {translate("Send Inquiry")}
                                </button>
                                <p>{translate("You won’t be charged yet")}</p>
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-lg-8 col-md-8 col-sm-12">
              <div className=" acommodation-left-bg">
                <div className="accomodation-location-wrapper">
                  <h3 className="accomodation-location">
                    {details?.property?.name}
                  </h3>
                  <div className="accomodation-wishlist-wrapper">
                    <BsFillHeartFill
                      style={{
                        background: "#8BC53E",
                        padding: "3px 6px 0px 6px",
                        color: "#FFF",
                        fontSize: "30px",
                        borderRadius: "50px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    />
                    <p>Wishlist</p>
                  </div>
                </div>
                <div className="accomodation-location-icon">
                  <p>
                    <FaMapMarkerAlt
                      style={{
                        color: "#8BC53E",
                        marginRight: "7px",
                        fontSize: "15px",
                      }}
                    />
                    <span>{details?.property?.location}</span>
                  </p>
                </div>

                {details?.property?.rooms?.length > 0 && (
                  <h1 className="accomodation-feature-heading mt-5">Rooms</h1>
                )}

                <div className="row mt-2">
                  {details?.property?.rooms?.length > 0 &&
                    details?.property?.rooms?.map((room, i) => {
                      return (
                        <div key={i} className="col-lg-4 col-md-6 col-12 ">
                          <div className="accomodation-bedsWrapper">
                            <div className="accomodation-beds">
                              <img src="/assets/icons/Bed.png" alt="....." />
                              <img
                                src="/assets/icons/Bedkids.png"
                                alt="....."
                              />
                            </div>
                            <div>{room.name}</div>
                            <p>
                              {details?.curr_sym}
                              {showAmount(room.price)}
                              <sub style={{ color: "grey", fontSize: "small" }}>
                                {" "}
                                /{room?.label ? room?.label : "Night"}
                              </sub>
                            </p>

                            <p>
                              <span>{room.adult}</span> Adult, {room.child}{" "}
                              Child
                            </p>
                            <button
                              className={`btn ${
                                selected_room_id === room.id
                                  ? "btn-success"
                                  : "btn-primary"
                              } w-100`}
                              onClick={() => handleDetails(room)}
                            >
                              {`${
                                selected_room_id === room.id
                                  ? "Selected"
                                  : "Select Room"
                              }`}
                            </button>
                          </div>
                        </div>
                      );
                    })}
                </div>

                {details?.property?.amenities?.length > 0 && (
                  <h1 className="accomodation-feature-heading mt-5 mb-3">
                    Amenities
                  </h1>
                )}

                <div className="row">
                  {details?.property?.amenities?.map((amn, i) => {
                    return (
                      <div
                        key={i}
                        className="col-lg-4 col-md-6 col-12 mt-2 acommodation-feature-wrapper"
                      >
                        {/* <div
                          dangerouslySetInnerHTML={createMarkup(amn.icon)}
                        ></div> */}
                        <i className="las la-paw"></i>
                        {/* <img src="/assets/icons/poolIcon.png" alt="....." /> */}
                        <div className="accomodation--feature">
                          <p>
                            <BiCheck style={{ fontSize: "20px" }} />
                            {amn?.name}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className=" acommodation-left-bg mt-5">
                <h1 className="accomodation-feature-heading mb-3">
                  Discription
                </h1>
                <div className="accomodation-discription">
                  <p>{details?.property?.description}</p>
                </div>
              </div>
              <div className="row accomodation-LocationImg mt-5">
                <h1 className="accomodation-feature-heading mb-3">Location</h1>
                <div className="col-lg-12 col-md-12 col-12">
                  <div className="mapouter w-100">
                    <div className="gmap_canvas">
                      <iframe
                        id="gmap_canvas"
                        src="https://maps.google.com/maps?q=Washington+Avenue+Southeast,+Manchester/&t=&z=13&ie=UTF8&iwloc=&output=embed"
                        frameBorder="0"
                        scrolling="no"
                        marginHeight="0"
                        marginWidth="0"
                      ></iframe>
                    </div>
                  </div>
                </div>
                {/* <img src="/assets/icons/boatDetailLocation.png" alt="....." /> */}
              </div>

              <div className=" acommodation-left-bg mt-5">
                <h1 className="accomodation-feature-heading mb-3">
                  Host Information
                </h1>
                <div className="col-12 pl-0 mt-4 ">
                  <div className="Accomodationhost-name-wrapper">
                    <img src="/assets/icons/hostImg.png" alt="....." />
                    <div className="Accomodationhost-name">
                      <h2>Brooklyn Simmons</h2>
                      <p>
                        <BsStarFill style={{ color: "yellow" }} />
                        <span>4.8 (17 Review)</span>
                      </p>
                    </div>
                  </div>
                  <div className="Accomodationhost-discription">
                    <p>
                      Vivamus ut enim ullamcorper, porta urna eu, vulputate
                      elit. Nulla facilisi. Proin pretium arcu in massa mollis,
                      vel dapibus neque ultrices. In hac habitasse
                    </p>
                  </div>
                  <div className="col-lg-12 pl-0 mt-4 accomodationHost-feature-wrapper">
                    <img src="/assets/icons/Calendar.png" alt="....." />
                    <div className="accomodation--feature">
                      <p>Join in march 2016 </p>
                    </div>
                  </div>
                  <div className="col-lg-12 pl-0 mt-4 accomodationHost-feature-wrapper">
                    <img src="/assets/icons/RankingRate.png" alt="....." />
                    <div className="accomodation--feature">
                      <p>Response Rate 100%</p>
                    </div>
                  </div>
                  <div className="col-lg-12 pl-0 mt-4 accomodationHost-feature-wrapper">
                    <img src="/assets/icons/Bookmark.png" alt="....." />
                    <div className="boatHost-feature">
                      <p>Fost response - within a few hours</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-5">
                <h1 className="accomodation-reviewHeading">
                  Reviews<span>{details?.property?.reviews?.length}</span>
                </h1>
                <div className="row">
                  <div className="col-lg-12 col-md-6 d-none d-lg-block d-md-block ">
                    <div className="reviews-wrapper">
                      <div className="Accomodationhost-name-wrapper">
                        <img src="/assets/icons/reviewsImg1.png" alt="....." />
                        <div className="ml-4">
                          <div className="accomodation-reviewStars mb-2">
                            <BsStarFill style={{ color: "rgb(254, 194, 35" }} />
                            <BsStarFill style={{ color: "rgb(254, 194, 35" }} />
                            <BsStarFill style={{ color: "rgb(254, 194, 35" }} />
                            <BsStarFill style={{ color: "rgb(254, 194, 35" }} />
                          </div>
                          <div className="client-review">
                            <h2>Guy Hawkins</h2>
                            <p>July 18,2023</p>
                          </div>

                          <p className="review-discription">
                            Sed ut perspiciatis unde omnis iste natus error sit
                            voluptatem accusantium doloremque laudantium, to
                          </p>
                        </div>
                      </div>
                      <div className="reply-reviews">
                        <button>Login to reply</button>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-6 d-none d-lg-block d-md-block">
                    <div className="reviews-wrapper">
                      <div className="Accomodationhost-name-wrapper">
                        <img src="/assets/icons/reviewsImg2.png" alt="....." />
                        <div className="ml-4">
                          <div className="accomodation-reviewStars mb-2">
                            <BsStarFill style={{ color: "yellow" }} />
                            <BsStarFill style={{ color: "yellow" }} />
                            <BsStarFill style={{ color: "yellow" }} />
                            <BsStarFill style={{ color: "yellow" }} />
                          </div>
                          <div className="client-review">
                            <h2>Eleanor Pena</h2>
                            <p>July 18,2023</p>
                          </div>

                          <p className="review-discription">
                            Sed ut perspiciatis unde omnis iste natus error sit
                            voluptatem accusantium doloremque laudantium, to
                          </p>
                        </div>
                      </div>
                      <div className="reply-reviews">
                        <button>Login to reply</button>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-6 d-none d-lg-block d-md-block mt-4">
                    <div className=" addReviview-wrapper">
                      <div className="add-review ">
                        <h1>Add a review</h1>
                        <p>You must be logged in to post a comment.</p>
                      </div>
                      <div className="older-comment-btn">
                        <Link to="/Home/allLocations">
                          <Button variant="contained">
                            Older Comment
                            <BsArrowRightShort
                              style={{ fontSize: "18px", marginLeft: "10px" }}
                            />
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="similar-accomdation-heading">
            <h1>Similar Boats</h1>
          </div>
          <div className="row" style={{ marginBottom: "100px" }}>
            {details?.boats?.length > 0 ? (
              details?.boats?.map((d, index) => {
                return (
                  <div className="col-lg-4 mt-4" key={index}>
                    <Card>
                      <CardMedia>
                        <div
                          id={`carouselExampleIndicators_${index}`}
                          className="carousel slide"
                          data-bs-ride="carousel"
                        >
                          <div>
                            <img
                              src={
                                d.image !== ""
                                  ? d.image
                                  : "/assets/icons/shipList1.png"
                              }
                              className="d-block w-100"
                              alt=""
                            />
                          </div>
                          <div className="boat-card-offer">
                            <h4>{d.discount}%</h4>
                            <div
                              style={{
                                position: "absolute",
                                top: "10px",
                                right: "20px",
                                color: "#FFF",
                                background: "#8BC53E",
                                padding: "4px 7px",
                                borderRadius: "50px",
                              }}
                            >
                              <BsFillHeartFill />
                            </div>
                          </div>
                        </div>
                      </CardMedia>
                      <CardContent>
                        <div className="shipList-ranking">
                          <Typography className="shiplist-card-location">
                            <p>
                              <FaMapMarkerAlt
                                style={{
                                  color: "#8BC53E",
                                  marginRight: "7px",
                                  fontSize: "15px",
                                }}
                              />
                              <span>{d.location}</span>
                            </p>
                          </Typography>
                          <Typography className="shiplist-card-location">
                            <p>
                              <BsFillStarFill
                                style={{
                                  color: "#8BC53E",
                                  marginRight: "7px",
                                  fontSize: "15px",
                                }}
                              />
                              <span>{d.review}</span>
                            </p>
                          </Typography>
                        </div>
                        <div>
                          <Typography
                            gutterBottom
                            variant="h5"
                            component="div"
                            className="shiplist-card-heading"
                          >
                            <h1>{d.name}</h1>
                          </Typography>
                          <Typography
                            gutterBottom
                            variant="h5"
                            component="div"
                            className="shiplist-card-discription"
                          >
                            <p>{d.boat_type}</p>
                          </Typography>
                        </div>
                        <Typography className="shiplist-card-deatil-button">
                          <h3>
                            {details?.curr_sym}
                            {d.price} <span>{d.label}</span>
                          </h3>
                          <Link>
                            <button>View Detail</button>
                          </Link>
                        </Typography>
                      </CardContent>
                    </Card>
                  </div>
                );
              })
            ) : (
              <div className="col-12 text-center mt-5">
                <h3>No Data Found</h3>
              </div>
            )}
          </div>
        </div>
      )}
      {/* {!loading && (
        <div className="boatDetailPage-fastBoatCards">
          <FastBoatCards />
        </div>
      )} */}
      {!loading && (
        <div className="boatDetailPage-footer">
          <Footer />
        </div>
      )}
    </>
  );
}

export default Rooms;
