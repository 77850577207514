import React from "react";
import "./BoatDetail.css";
import Navbar from "../../Components/Navbar/Navbar";
import { FaMapMarkerAlt } from "react-icons/fa";
import { BsStarFill, BsFillHeartFill } from "react-icons/bs";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { BsFillStarFill } from "react-icons/bs";
import Footer from "../../Components/Footer/Footer";
import FastBoatCards from "../../Components/FastBoatCards/FastBoatCards";
import { CircularProgress, Stack } from "@mui/material";
import { showAmount } from "../../utils/showAmount";
import { useRef, useState } from "react";
import { useEffect } from "react";
import makeHttpRequest from "../../utils/api";
import { BsArrowRightShort, BsShare } from "react-icons/bs";
import { BiCheck, BiChevronsRight } from "react-icons/bi";
import he from "he";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { MdClose } from "react-icons/md";
import Toaster from "../../Components/Toaster";
import { useDispatch, useSelector } from "react-redux";
import { userLogged } from "../../UserDashboardComponent/UserProfile/userSlice";
import SocialShare from "../../Components/SocialShare";
import Wishlist from "../../Components/Wishlist";
import { useLanguage } from "../../utils/LanguageContext";
import DetailCarousal from "../../Components/LocationCarousal/DetailCarousal";
import { generateSlug, title, roundTowardsMax } from "../../utils/helpers";
import ExtensionApi from "../../Components/ExtensionApi";
import Loader from "../../Components/Loader";
import LoginPage from "../login";
import LoginCanvas from "../LoginCanvas/LoginCanvas";
import BoatDetailMobileCarousel from "../../Components/BoatDetailMobileCarousel/BoatDetailMobileCarousel";
import BoatDetailMobileLeftbar from "../../Components/BoatDetailMobileLefbar/BoatDetailMobileLeftbar";

const style = {
  // position: "absolute",
  // top: "40%",
  // left: "50%",
  // transform: "translate(-50%, -50%)",
  width: "40%",
  bgcolor: "background.paper",
  boxShadow: "0px 4px 32px 0px rgba(26, 26, 26, 0.10)",
  borderRadius: "16px",
  border: "none",
  outline: "none",
};

function BoatDetail() {
  const navigate = useNavigate();
  const { id, slug } = useParams();
  const [details, setDetails] = useState("");
  const [loading, setLoading] = useState(false);
  const { translate } = useLanguage();
  const { isLoggedIn } = useSelector((state) => state.user);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [checkInDate, setCheckInDate] = useState("");
  const [checkOutDate, setCheckOutDate] = useState("");
  const [show, setShow] = useState(false);

  const [days, setDays] = useState(0);

  useEffect(() => {
    if (id) {
      getData();
    }
  }, []);

  useEffect(() => {
    if (id) {
      getData();
    }
  }, [slug]);

  const getData = async () => {
    try {
      setLoading(true);
      const res = await makeHttpRequest("GET", `boat/${id}/${slug}`);
      if (res.success) {
        setDetails(res.data);
        console.log(details?.boat?.owner_review);
        document.title = title(res?.data?.boat?.name);
        setLoading(false);
      } else if (!res.success) {
        navigate("/boat");
      } else {
        navigate("/boat");
      }
    } catch (error) {
      console.error("Error is:", error);
      setLoading(false);
    }
  };

  const handleInquiry = async (req) => {
    if (checkInDate && checkOutDate) {
      try {
        setLoading(true);
        if (req === "inquiry") {
          const res = await makeHttpRequest("POST", `user/boat/booking`, {
            boat_list: id,
            start: checkInDate,
            end: checkOutDate,
            days: days,
          });
          Toaster("success", res.message);
        } else {
          const res = await makeHttpRequest("POST", `user/chat/message/store`, {
            slug,
            id,
            type: "item",
            item: "boat",
            start: checkInDate,
            end: checkOutDate,
            days: days,
            url: window.location.href,
          });
          if (res.success) {
            navigate("/user-dashboard", { state: { selectedTab: 8 } });
          } else {
            Toaster("error", "Someting went Wrong");
          }
        }

        setLoading(false);
      } catch (error) {
        console.error("Error is:", error);
        setLoading(false);
      }
    } else {
      Toaster("error", "Select Check in and Check out date");
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <Navbar />
      {loading && (
        <Stack height="80vh" alignItems="center" justifyContent="center">
          <CircularProgress size={100} sx={{ color: "#8bc53e" }} />
        </Stack>
      )}
      {!loading && details && details?.boat?.length === 0 && (
        <Stack my={5} alignItems="center" justifyContent="center">
          <h2>{translate(details?.empty_message)}</h2>
        </Stack>
      )}
      {!loading && (
        <div className="container">
          <div className="row mt-5">
            <div className="col-lg-8 col-md-12 col-sm-12">
              {screenWidth < 768 ? (
                <BoatDetailMobileCarousel images={details?.boat?.images} />
              ) : (
                <DetailCarousal images={details?.boat?.images} />
              )}

              <div className=" boatDetail-left-bg mt-3">
                <div className="boatDetail-location-wrapper">
                  <h3 className="boatDetail-location">
                    {translate(details?.boat?.name)}{" "}
                  </h3>
                </div>
                <div className="boatDetail-location-icon">
                  <p>
                    <FaMapMarkerAlt
                      style={{
                        color: "#8BC53E",
                        marginRight: "7px",
                        fontSize: "15px",
                      }}
                    />
                    <span>{translate(details?.boat?.location.name)}</span>
                  </p>
                </div>
              </div>

              {details?.boat?.extra_features?.length > 0 && (
                <div className="boatDetail-left-bg mt-3">
                  <div className="things-to-know-section">
                    <h1 className="boatThings-heading">
                      {translate("Equipment available on Boat")}
                    </h1>
                    {details.boat.extra_features.map((feature, i) => (
                      <>
                        <div key={i} className="boatThings-content-wrapper">
                          <BiChevronsRight className="boatThings-icon" />
                          <div className="boatThings-content">
                            <h2>{translate(feature)}</h2>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              )}

              <div className="boatDetail-left-bg mt-3">
                <div className="things-to-know-section">
                  {details?.boat?.extra_features?.length && (
                    <h1 className="boatThings-heading">
                      {translate("Service Offerings")}
                    </h1>
                  )}
                  {details?.boat?.amenities?.map((am, i) => (
                    <div key={i} className="boatThings-content-wrapper">
                      <BiCheck
                        style={{ fontSize: "20px", color: "#8bc53e" }}
                        className="boatThings-icon"
                      />
                      <div className="boatThings-content">
                        <h2>{translate(am?.name)}</h2>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className=" boatDetail-left-bg mt-3">
                <h1 className="boatDetail-amenities-heading mb-3">
                  {translate("Discription")}
                </h1>
                <div
                  className="boatDetail-discription"
                  dangerouslySetInnerHTML={{
                    __html: details?.boat?.description
                      ? he.decode(details.boat.description)
                      : "",
                  }}
                ></div>
              </div>

              <div className="boatDetail-left-bg mt-3">
                <div className="boatDetail-ocation">
                  <h1 className="boatDetail-amenities-heading mb-3">
                    {translate("Location")}
                  </h1>
                  <div className="col-lg-12 col-md-12 col-12">
                    <div className="mapouter w-100">
                      <div className="gmap_canvas">
                        <iframe
                          title="Location of your boat"
                          id="gmap_canvas"
                          // src="https://maps.google.com/maps?q=Washington+Avenue+Southeast,+Manchester/&t=&z=13&ie=UTF8&iwloc=&output=embed"
                          src={`${details?.boat?.map_url}=UTF8&iwloc=&output=embed`}
                          frameBorder="0"
                          scrolling="no"
                          marginHeight="0"
                          marginWidth="0"
                        ></iframe>
                      </div>
                    </div>
                  </div>

                  {/* <img src="/assets/icons/boatDetailLocation.png" alt="....." /> */}
                </div>
              </div>

              {/* HOST */}
              <div className="boatDetail-left-bg mt-3">
                <h1 className="boatDetail-amenities-heading mb-3">
                  {translate("Host Information")}
                </h1>
                <div className="col-12 pl-0 mt-4 ">
                  <div className="host-name-wrapper">
                    <img
                      src="/assets/icons/avatar.png"
                      alt="....."
                      className="border rounded-circle"
                    />
                    <div className="host-name">
                      <h2>
                        {details?.boat?.owner?.firstname}{" "}
                        {details?.boat?.owner?.lastname}
                      </h2>
                      <p>
                        <BsStarFill style={{ color: "yellow" }} />
                        <span>
                          {showAmount(details?.boat?.owner_rating)} (
                          {details?.boat?.owner_review}) {translate("Review")}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="host-discription">
                    <p>{details?.boat?.owner?.bio}</p>
                  </div>
                  <div className="col-lg-12 pl-0 mt-4 boatHost-feature-wrapper">
                    <img src="/assets/icons/Calendar.png" alt="....." />
                    <div className="boatAmenities--feature">
                      <p>
                        {translate("Join in")}{" "}
                        {new Date(
                          details?.boat?.owner?.created_at
                        ).toLocaleDateString("en-US", {
                          year: "numeric",
                          month: "long",
                        })}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-12 pl-0 mt-4 boatHost-feature-wrapper">
                    <img src="/assets/icons/RankingRate.png" alt="....." />
                    <div className="boatAmenities--feature">
                      <p>{translate("Response Rate 100%")}</p>
                    </div>
                  </div>
                  <div className="col-lg-12 pl-0 mt-4 boatHost-feature-wrapper">
                    <img src="/assets/icons/Bookmark.png" alt="....." />
                    <div className="boatHost-feature">
                      <p>{translate("Fast response - within a few hours")}</p>
                    </div>
                  </div>
                </div>
              </div>

              {/* REVIEWS */}
              <div className="mt-3">
                <h1 className="accomodation-reviewHeading">
                  {translate("Reviews")}
                  <span style={{ fontSize: "25px", fontWeight: "700" }}>
                    ({details?.boat?.reviews?.length})
                  </span>
                </h1>
                <div className="row p-1">
                  {details?.boat?.reviews?.length > 0 &&
                    details?.boat?.reviews?.map((re, index) => (
                      <div
                        key={index}
                        className="col-lg-12 col-md-6 d-none d-lg-block d-md-block my-1"
                      >
                        <div className="reviews-wrapper">
                          <div className="Accomodationhost-name-wrapper">
                            <img
                              src="/assets/icons/avatar.png"
                              alt="....."
                              className="border rounded-circle"
                            />
                            <div className="ml-4">
                              <div className="accomodation-reviewStars mb-2">
                                {Array.from(
                                  { length: re?.rating },
                                  (_, starIndex) => (
                                    <BsStarFill
                                      key={starIndex}
                                      style={{ color: "yellow" }}
                                    />
                                  )
                                )}
                              </div>
                              <div className="client-review">
                                <h2>
                                  {re?.user?.firstname} {re?.user?.lastname}
                                </h2>
                                <p>
                                  {re?.created_at &&
                                    new Date(re?.created_at).toLocaleDateString(
                                      "en-US",
                                      {
                                        year: "numeric",
                                        month: "long",
                                        day: "numeric",
                                      }
                                    )}
                                </p>
                              </div>

                              <p className="review-discription">
                                {re?.description}
                              </p>
                            </div>
                          </div>
                          <div className="reply-reviews">
                            <h2>{translate("Login to reply")}</h2>
                          </div>
                        </div>
                      </div>
                    ))}
                  <div className="col-lg-12 col-md-6 d-none d-lg-block d-md-block mt-3">
                    <div className=" addReviview-wrapper">
                      <div className="add-review">
                        <h1>{translate("Add a review")}</h1>
                        {!isLoggedIn && (
                          <p>
                            {translate(
                              "You must be logged in to post a comment."
                            )}
                          </p>
                        )}
                      </div>
                      {isLoggedIn && (
                        <div className="older-comment-btn">
                          <Link to="/user-reviews">
                            <Button variant="contained">
                              {translate("My Reviews")}
                              <BsArrowRightShort
                                style={{ fontSize: "18px", marginLeft: "10px" }}
                              />
                            </Button>
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 d-none d-lg-block">
              <BoatDetailMobileLeftbar />
            </div>
          </div>

          <div className="similar-boat-heading">
            <h1>{translate("Check availability of similar boats")}</h1>
          </div>
          <div className="row" style={{ marginBottom: "100px" }}>
            {/* <div className="row class1 mb-5"> */}
            {details?.boats?.map((d, index) => {
              return (
                <div className="col-lg-4 mt-4">
                  <Card>
                    <CardMedia>
                      <div
                        id={`carouselExampleIndicators_${index}`}
                        className="carousel slide"
                        data-bs-ride="carousel"
                      >
                        <div className="carousel-indicators">
                          {d.image.map((image, i) => (
                            <button
                              type="button"
                              data-bs-target={`#carouselExampleIndicators_${index}`}
                              data-bs-slide-to={i}
                              className={i === 0 ? "active" : ""}
                              aria-current={i === 0 ? "true" : "false"}
                              key={i}
                            ></button>
                          ))}
                        </div>
                        <div className="carousel-inner">
                          {d.image.map((image, i) => (
                            <div
                              className={`carousel-item ${
                                i === 0 ? "active" : ""
                              } boat-detail-carouser-div`}
                              key={i}
                            >
                              <img
                                src={image}
                                className="d-block w-100"
                                alt={`Slide ${i + 1}`}
                              />
                            </div>
                          ))}
                        </div>
                        <div className="boat-card-offer">
                          {d?.discount > 0 && (
                            <h4>
                              {d?.discount} {translate("% off")}
                            </h4>
                          )}
                          <div
                            style={{
                              position: "absolute",
                              top: "10px",
                              right: "20px",
                              color: "#FFF",
                              background: "#8BC53E",
                              padding: "4px 7px",
                              borderRadius: "50px",
                            }}
                          >
                            <BsFillHeartFill />
                          </div>
                        </div>
                      </div>
                    </CardMedia>
                    <CardContent className="">
                      <div className="shipList-ranking">
                        <Typography className="shiplist-card-location">
                          <p>
                            <FaMapMarkerAlt
                              style={{
                                color: "#8BC53E",
                                marginRight: "7px",
                                fontSize: "15px",
                              }}
                            />
                            <span>{d.location.name}</span>
                          </p>
                        </Typography>
                        <Typography className="shiplist-card-location">
                          <p>
                            <BsFillStarFill
                              style={{
                                color: "yellow",
                                marginRight: "7px",
                                fontSize: "15px",
                              }}
                            />
                            <span>{showAmount(d.rating)}</span>
                          </p>
                        </Typography>
                      </div>

                      <div>
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="div"
                          className="shiplist-card-heading"
                        >
                          <h1>{d.name}</h1>
                        </Typography>
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="div"
                          className="shiplist-card-discription"
                        >
                          {/* <p>{d.guests} Guests</p>
                          <p>{d.bed} Bed</p>
                          <p>{d.cabin} Cabin</p>
                          <p>{d.bathroom} bathroom</p> */}
                        </Typography>
                      </div>

                      <Typography className="shiplist-card-deatil-button">
                        <h3>
                          {details?.curr_sym}
                          {showAmount(d.price)} <span>{d.label}</span>
                        </h3>
                        <Link to={`/boat/${d?.id}/${d?.slug}/details`}>
                          <button>{translate("View Detail")}</button>
                        </Link>
                      </Typography>
                    </CardContent>
                  </Card>
                </div>
              );
            })}
            {/* </div> */}
          </div>
        </div>
      )}

      <div className="fixed-buttons d-block d-lg-none">
        <div className="row">
          <div className="col-6">
            <button
              className="custom-offer-btn-mobile"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasLeft"
              aria-controls="offcanvasLeftLabel"
            >
              Check availability
            </button>
          </div>
          <div className="col-6">
            <button className="inquriy-send-btn-mobile" onClick={handleInquiry}>
              Send Inquiry
            </button>
          </div>
        </div>
      </div>

      <div class="container mt-5 text-center">
        <div>
          <div
            className="offcanvas offcanvas-end boat-detail-off-canvas"
            tabIndex={-1}
            id="offcanvasRight"
            aria-labelledby="offcanvasRightLabel"
          >
            <div className="offcanvas-header">
              <button
                type="button"
                className="btn-close text-reset"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              />
            </div>
            <div className="container">
              <LoginCanvas />
            </div>
          </div>
        </div>
      </div>
      <div class="container mt-5 text-center">
        <div>
          <div
            className="offcanvas offcanvas-start boat-detail-off-canvas"
            tabIndex={-1}
            id="offcanvasLeft"
            aria-labelledby="offcanvasBottomLabel"
          >
            <div className="offcanvas-header">
              <button
                type="button"
                className="btn-close text-reset"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              />
            </div>
            <div className="container">
              <BoatDetailMobileLeftbar />
            </div>
          </div>
        </div>
      </div>

      {!loading && (
        <div className="boatDetailPage-footer">
          <Footer />
        </div>
      )}
    </>
  );
}

export default BoatDetail;
