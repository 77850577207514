export default function calculateTimeDifference(lastReplyTimestamp) {
  const currentDate = new Date();
  const previousDateTime = new Date(lastReplyTimestamp);
  const timeDifference = currentDate - previousDateTime;

  // Calculate time units
  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  const years = Math.floor(months / 12);

  if (seconds < 60) {
    return seconds + " seconds ago";
  } else if (minutes < 60) {
    return minutes + " minutes ago";
  } else if (hours < 24) {
    return hours + " hours ago";
  } else if (days < 30) {
    return days + " days ago";
  } else if (months < 12) {
    return months + " months ago";
  } else {
    return years + " years ago";
  }
  // const currentTimestamp = Date.now();
  // const timeDifferenceInSeconds = Math.floor(
  //   (currentTimestamp - lastReplyTimestamp) / 1000
  // );

  // if (timeDifferenceInSeconds < 60) {
  //   return `${timeDifferenceInSeconds} seconds ago`;
  // } else if (timeDifferenceInSeconds < 3600) {
  //   const minutes = Math.floor(timeDifferenceInSeconds / 60);
  //   return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
  // } else if (timeDifferenceInSeconds < 86400) {
  //   const hours = Math.floor(timeDifferenceInSeconds / 3600);
  //   return `${hours} hour${hours > 1 ? "s" : ""} ago`;
  // } else {
  //   const days = Math.floor(timeDifferenceInSeconds / 86400);
  //   return `${days} day${days > 1 ? "s" : ""} ago`;
  // }
}
