import React, { useEffect, useState } from "react";
import "./BlogPage.css";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Link, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { BsArrowRightShort } from "react-icons/bs";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Footer from "../../Components/Footer/Footer";
import Navbar from "../../Components/Navbar/Navbar";
import {
  Box,
  CircularProgress,
  Stack,
  Typography,
  styled,
  Skeleton,
} from "@mui/material";
import makeHttpRequest from "../../utils/api";
import { addBlogs, selectBlog } from "./blogSlice";
import { useDispatch, useSelector } from "react-redux";
import { setEqualHeight } from "../../utils/heightUtils";
import { useLanguage } from "../../utils/LanguageContext";
import { MdNavigateNext } from "react-icons/md";

const Title = styled(Typography)({
  fontFamily: "Montserrat",
  fontSize: "60px",
  fontWeight: 700,
  textAlign: "center",
  marginTop: "50px",
});

function BlogPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { blogs } = useSelector((state) => state.blogs);
  const [loading, setLoading] = useState(false);
  const { translate } = useLanguage();
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageVisible, setImageVisible] = useState(false); // State to control image visibility

  useEffect(() => {
    setEqualHeight(".same-height");
    getData();
  }, []);

  useEffect(() => {
    // Set a delay to show the image after 3 seconds
    const timer = setTimeout(() => {
      setImageVisible(true);
    }, 3000); // 3000ms = 3 seconds

    return () => clearTimeout(timer); // Cleanup the timeout if component unmounts
  }, []);

  const handleDetails = (item) => {
    dispatch(selectBlog(item));
    navigate(`/blog/${item?.id}/${item?.slug}`);
  };

  const getData = async () => {
    try {
      setLoading(true);
      const response = await makeHttpRequest("GET", "blog");
      dispatch(addBlogs(response.data));
      setLoading(false);
    } catch (error) {
      console.error("Error is:", error);
      setLoading(false);
    }
  };

  return (
    <Box>
      <Navbar />

      {loading && (
        <Stack height="80vh" alignItems="center" justifyContent="center">
          <CircularProgress size={100} sx={{ color: "#8bc53e" }} />
        </Stack>
      )}
      {!loading && blogs?.blogs?.length === 0 && (
        <Stack height="80vh" alignItems="center" justifyContent="center">
          <Title>{translate(blogs?.empty_message)}</Title>
        </Stack>
      )}
      {!loading && (
        <Box>
          <div className="container">
            <div className="d-flex flex-row justify-content-center mt-3">
              <div className="p-1">
                <Link to="/">
                  <p className="text-black fw-bold">{translate("Home")}</p>
                </Link>
              </div>
              <div className="p-1">
                <MdNavigateNext />
              </div>
              <div className="p-1">
                <p
                  style={{
                    color: "#8bc53e",
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                >
                  {translate("Blog")}
                </p>
              </div>
            </div>

            <div className="row" style={{ marginTop: "80px" }}>
              {blogs?.blogs?.map((blog, index) => {
                return (
                  <div key={index} className="col-lg-4 mt-4">
                    <Card className="blog-card" sx={{ borderRadius: "10px" }}>
                      {/* Skeleton for shimmer effect */}
                      <div
                        className="blog-image-section"
                        style={{
                          height: "200px", // Set the height as per your design
                          position: "relative",
                        }}
                      >
                        {!imageVisible && (
                          <Skeleton
                            variant="rectangular"
                            width="100%"
                            height="100%"
                            sx={{
                              borderRadius: "10px",
                              position: "absolute",
                              top: 0,
                              left: 0,
                            }}
                          />
                        )}
                        <div
                          style={{
                            backgroundImage: `url(${blog.image})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            height: "100%",
                            borderRadius: "10px",
                            display: imageVisible ? "block" : "none", // Show image after the delay
                          }}
                          onLoad={() => setImageLoaded(true)} // Set imageLoaded to true when the image is loaded
                        ></div>
                      </div>
                      <CardContent>
                        <div className="blog-hotelName-wrapper">
                          <div className="blog-hotelName"></div>
                          <div className="blog-hotelName">
                            <p>{blog?.created_at}</p>
                          </div>
                        </div>

                        <div>
                          <div
                            gutterBottom
                            variant="h5"
                            component="div"
                            className="blog-card-content"
                          >
                            <h1 className="same-height">{blog?.title}</h1>
                            <p>{blog?.body}</p>
                          </div>
                        </div>

                        <div className="blog-deatil-button">
                          <Button
                            variant="contained"
                            onClick={() => handleDetails(blog)}
                          >
                            {translate("Read More")}
                            <BsArrowRightShort
                              style={{ fontSize: "15px", marginLeft: "10px" }}
                            />
                          </Button>
                        </div>
                      </CardContent>
                    </Card>
                  </div>
                );
              })}
            </div>
          </div>
        </Box>
      )}
      <div style={{ marginTop: "60px" }}>
        <div className="blogPage-footer">
          <Footer />
        </div>
      </div>
    </Box>
  );
}

export default BlogPage;
