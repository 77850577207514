import React, { useState, useEffect } from "react";
import { useLanguage } from "../../utils/LanguageContext";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import CardMedia from "@mui/material/CardMedia";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { addLeadingZero } from "../../utils/addLeadingZero";
import { BsArrowRightShort } from "react-icons/bs";
import Skeleton from "@mui/material/Skeleton";

function BoatType() {
  const { translate } = useLanguage();
  const { data } = useSelector((state) => state.landing);

  // Initialize loading state for all boat types
  const [loading, setLoading] = useState([]);

  useEffect(() => {
    // Reset loading state when data changes
    if (data?.boatTypes) {
      setLoading(Array(data.boatTypes.length).fill(true));
      
      // Set a timeout to hide skeletons after 2 seconds
      const timeout = setTimeout(() => {
        setLoading(Array(data.boatTypes.length).fill(false));
      }, 2000);

      // Cleanup timeout
      return () => clearTimeout(timeout);
    }
  }, [data?.boatTypes]);

  return (
    <div className="boat-wrapper">
      <div className="container p-3">
        <div className="best-boat-content-wrapper">
          <div className="best-boats-content">
            <div className="boatType-Images">
              <h1>{translate(data?.boat?.heading)}</h1>
              <img
                className="boatTypeImage-one"
                src="/assets/icons/aa2.jpeg"
                alt="..."
              />
              <img
                className="boatTypeImage-two"
                src="/assets/icons/aa1.png"
                alt="..."
              />
            </div>
            <p className="fs-7">{translate(data?.boat?.sub_heading)}</p>
          </div>
          <div className="best-boat-button">
            <Link to={"/boat"} className="link-wrapper">
              <Button variant="contained">
                {translate("Discover More")}
                <BsArrowRightShort
                  style={{ fontSize: "15px", marginLeft: "10px" }}
                />
              </Button>
            </Link>
          </div>
        </div>

        <div>
          {data?.boatTypes && data?.boatTypes.length > 0 && (
            <div className="row boat-wrapper class1">
              {data.boatTypes.map((boatType, index) => (
                <div className="col-lg-3" key={boatType.id}>
                  <Card
                    sx={{
                      borderTopRightRadius: "8px",
                      borderTopLeftRadius: "8px",
                      borderBottomRightRadius: "16px",
                      borderBottomLeftRadius: "16px",
                      boxShadow: "none",
                    }}
                    className="boot-card-content-wrapper"
                  >
                    <Link
                      to={`/boat/search-location/${boatType.id}/${boatType.slug}`}
                      className="link-wrapper"
                      style={{ textDecoration: "none" }}
                    >
                      {loading[index] ? (
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={180}
                        />
                      ) : (
                        <CardMedia
                          component="img"
                          alt={boatType.name}
                          image={boatType.image}
                          style={{ backgroundColor: "#8BC53E" }}
                        />
                      )}
                      <CardContent className="card-boot-content-wrapper">
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="div"
                          className="boat-card-footer"
                        >
                          {translate(boatType.name)} (
                          {addLeadingZero(boatType.total)})
                        </Typography>
                        <BsArrowRightShort className="boat-sec-more-icon" />
                      </CardContent>
                    </Link>
                  </Card>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default BoatType;
