import React, { useEffect, useState } from "react";
import classes from "./Reviews.module.css";
import { AiFillStar } from "react-icons/ai";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import makeHttpRequest from "../../utils/api";
import UserSideNavbar from "../UserSideNavbar/UserSideNavbar";
import { MdClose } from "react-icons/md";
import { useLanguage } from "../../utils/LanguageContext";
import Loader from "../../Components/Loader";

let style = {};
const isMobile = window.innerWidth <= 767;
if (isMobile) {
  style = {
    width: "90%",
    bgcolor: "background.paper",
    boxShadow: "0px 4px 32px 0px rgba(26, 26, 26, 0.10)",
    borderRadius: "16px",
    border: "none",
    outline: "none",
  };
} else {
  style = {
    width: "40%",
    bgcolor: "background.paper",
    boxShadow: "0px 4px 32px 0px rgba(26, 26, 26, 0.10)",
    borderRadius: "16px",
    border: "none",
    outline: "none",
  };
}

function Reviews() {
  const [activeTab, setActiveTab] = useState("pendingProperties");
  const [selectedValue, setSelectedValue] = useState("");
  const [inputReview, setInputReview] = useState("");
  const [propertyId, setPropertyId] = useState(0);
  const [boatId, setBoatId] = useState(0);
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const { translate } = useLanguage();

  const handleOpen = (id, type, rating = "", des = "") => {
    setInputReview(des);
    setSelectedValue(rating);
    if (type === "boat") {
      setBoatId(id);
    } else {
      setPropertyId(id);
    }
    setOpen(true);
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      setLoading(true);
      const res = await makeHttpRequest("GET", "user/reviews");
      setData(res.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleRateChange = (e) => {
    setSelectedValue(e.target.value);
  };

  const handleReviewChange = (e) => {
    setInputReview(e.target.value);
  };

  const storeFeedback = async () => {
    try {
      setLoading(true);
      let obj = {
        rating: selectedValue,
        description: inputReview,
      };
      if (propertyId !== 0) {
        obj.property_id = propertyId;
      } else if (boatId !== 0) {
        obj.boat_id = boatId;
      }
      await makeHttpRequest("POST", "user/reviews/store", obj);
      handleClose();
    } catch (error) {
      setLoading(false);
    }
  };

  const handleClose = () => setOpen(false);

  return (
    <>
      <div className=" container mt-4">
        <div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div className={classes["reviewmodal-wrapper"]}>
              <Box sx={style}>
                <div className={classes["reviewmodal-heading"]}>
                  <h1>{translate("Feedback")}</h1>
                  <MdClose
                    onClick={handleClose}
                    style={{
                      color: "#FFF",
                      cursor: "pointer",
                      fontSize: "38px",
                      paddingRight: "10px",
                    }}
                  />
                </div>
                <div className={classes["reviewsubmit-inputs"]}>
                  <label>{translate("Stars")}</label>
                  <select
                    className="form-control mb-3"
                    onChange={handleRateChange}
                    value={selectedValue}
                  >
                    {[...Array(5)].map((_, i) => (
                      <option key={i + 1} value={i + 1}>
                        {i + 1}{" "}
                        {i === 0 ? translate("Star") : translate("Stars")}
                      </option>
                    ))}
                  </select>
                  <label>{translate("Write feedback")}</label>
                  <textarea
                    className="form-control"
                    onChange={handleReviewChange}
                    value={inputReview}
                  />
                  <div className={classes["reviewsubmit-wrapper"]}>
                    <button
                      onClick={storeFeedback}
                      className={classes["reviewsubmit-btn"]}
                    >
                      {translate("Submit")}
                    </button>
                  </div>
                </div>
              </Box>
            </div>
          </Modal>

          {loading && <Loader />}

          {/* Tab Navigation */}

          {!loading && (
            <>
              <div className="user-dashboard-content-wrapper ">
                <div className="tab-navigation user-dashboard-content-wrapper ">
                  <button
                    //  className={`  ${activeTab === "tickets" ? "active" : ""}  `} // Apply active class if this tab is active
                    className={
                      activeTab === "pendingProperties" ? "active" : ""
                    }
                    onClick={() => setActiveTab("pendingProperties")}
                  >
                    {translate("Pending Properties")}
                  </button>
                  <button
                    className={
                      activeTab === "reviewedProperties" ? "active" : ""
                    }
                    onClick={() => setActiveTab("reviewedProperties")}
                  >
                    {translate("Reviewed Properties")}
                  </button>
                  <button
                    className={activeTab === "pendingBoats" ? "active" : ""}
                    onClick={() => setActiveTab("pendingBoats")}
                  >
                    {translate("Pending Boats")}
                  </button>
                  <button
                    className={activeTab === "reviewedBoats" ? "active" : ""}
                    onClick={() => setActiveTab("reviewedBoats")}
                  >
                    {translate("Reviewed Boats")}
                  </button>
                </div>
                {/* Tab Content */}
                {activeTab === "pendingProperties" &&
                  data?.pendingReviewProperties?.length > 0 && (
                    <div className={`${classes["reviews-bg"]} text-center `}>
                      <h2>{translate("Property Waiting for Review")}</h2>
                      <div
                        className="table-responsive"
                        style={{ borderRadius: "10px" }}
                      >
                        <table className="table table-bordred table-striped">
                          <thead className={classes["reviews-table-th"]}>
                            <tr>
                              <th>{translate("Property Name")}</th>
                              <th>{translate("Location")}</th>
                              <th>{translate("Phone")}</th>
                              <th>{translate("Rate")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.pendingReviewProperties.map((p, i) => (
                              <tr key={i}>
                                <td>{p.name}</td>
                                <td>{p?.location?.name}</td>
                                <td>{p.phone ? p.phone : "Not Available"}</td>
                                <td>
                                  <AiFillStar
                                    style={{
                                      color: "#f2c821",
                                      cursor: "pointer",
                                      fontSize: "25px",
                                    }}
                                    onClick={() => handleOpen(p.id, "property")}
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}

                {activeTab === "reviewedProperties" &&
                  data?.reviewedProperties?.length > 0 && (
                    <div className={`${classes["reviews-bg"]} text-center`}>
                      <h2>{translate("Reviewed Properties")}</h2>
                      <div
                        className="table-responsive"
                        style={{ borderRadius: "10px" }}
                      >
                        <table className="table table-bordred table-striped">
                          <thead className={classes["reviews-table-th"]}>
                            <tr>
                              <th>{translate("Property Name")}</th>
                              <th>{translate("Location")}</th>
                              <th>{translate("Phone")}</th>
                              <th>{translate("Rated")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.reviewedProperties.map((p, i) => (
                              <tr key={i}>
                                <td>{p.name}</td>
                                <td>{p?.location?.name}</td>
                                <td>{p.phone ? p.phone : "Not Available"}</td>
                                <td>
                                  <AiFillStar
                                    style={{
                                      color: "#f2c821",
                                      cursor: "pointer",
                                      fontSize: "25px",
                                    }}
                                    onClick={() =>
                                      handleOpen(
                                        p.id,
                                        "property",
                                        p?.review?.rating,
                                        p?.review?.description
                                      )
                                    }
                                  />
                                  ({p?.review?.rating})
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}

                {activeTab === "pendingBoats" &&
                  data?.pendingReviewBoats?.length > 0 && (
                    <div className={`${classes["reviews-bg"]} text-center `}>
                      <h2>{translate("Boat Waiting for Review")}</h2>
                      <div
                        className="table-responsive"
                        style={{ borderRadius: "10px" }}
                      >
                        <table className="table table-bordred table-striped">
                          <thead className={classes["reviews-table-th"]}>
                            <tr>
                              <th>{translate("Boat Name")}</th>
                              <th>{translate("Location")}</th>
                              <th>{translate("Phone")}</th>
                              <th>{translate("Rate")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.pendingReviewBoats.map((p, i) => (
                              <tr key={i}>
                                <td>{p.name}</td>
                                <td>{p?.location?.name}</td>
                                <td>{p.phone ? p.phone : "Not Available"}</td>
                                <td>
                                  <AiFillStar
                                    style={{
                                      color: "#f2c821",
                                      cursor: "pointer",
                                      fontSize: "25px",
                                    }}
                                    onClick={() => handleOpen(p.id, "boat")}
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}

                {activeTab === "reviewedBoats" &&
                  data?.reviewedBoats?.length > 0 && (
                    <div className={` ${classes["reviews-bg"]} text-center `}>
                      <h2>{translate("Reviewed Boats")}</h2>
                      <div
                        className="table-responsive"
                        style={{ borderRadius: "10px" }}
                      >
                        <table className="table table-bordred table-striped">
                          <thead className={classes["reviews-table-th"]}>
                            <tr>
                              <th>{translate("Boat Name")}</th>
                              <th>{translate("Location")}</th>
                              <th>{translate("Phone")}</th>
                              <th>{translate("Rated")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.reviewedBoats.map((p, i) => (
                              <tr key={i}>
                                <td>{p.name}</td>
                                <td>{p?.location?.name}</td>
                                <td>{p.phone ? p.phone : "Not Available"}</td>
                                <td>
                                  <AiFillStar
                                    style={{
                                      color: "#f2c821",
                                      cursor: "pointer",
                                      fontSize: "25px",
                                    }}
                                    onClick={() =>
                                      handleOpen(
                                        p.id,
                                        "boat",
                                        p?.review?.rating,
                                        p?.review?.description
                                      )
                                    }
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}

                {/* Handle case when no data is available */}
                {activeTab === "pendingProperties" &&
                  data?.pendingReviewProperties?.length === 0 && (
                    <div className="successProperty-Img">
                      <img
                        src="/assets/icons/pendingProperty-img.png"
                        alt="..."
                      />
                    </div>
                  )}
                {activeTab === "reviewedProperties" &&
                  data?.reviewedProperties?.length === 0 && (
                    <div className="successProperty-Img">
                      <img
                        src="/assets/icons/pendingProperty-img.png"
                        alt="..."
                      />
                    </div>
                  )}
                {activeTab === "pendingBoats" &&
                  data?.pendingReviewBoats?.length === 0 && (
                    <div className="successProperty-Img">
                      <img
                        src="/assets/icons/pendingProperty-img.png"
                        alt="..."
                      />
                    </div>
                  )}
                {activeTab === "reviewedBoats" &&
                  data?.reviewedBoats?.length === 0 && (
                    <div className="successProperty-Img">
                      <img
                        src="/assets/icons/pendingProperty-img.png"
                        alt="..."
                      />
                    </div>
                  )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default Reviews;
