import React, { useEffect, useState } from "react";
import "./OfferModal.css";
import { Modal, Form } from "react-bootstrap";
import { BsQuestionCircleFill, BsStarFill, BsTrash } from "react-icons/bs";
import { useLanguage } from "../../../utils/LanguageContext";
import makeHttpRequest from "../../../utils/api";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";

const OfferModal = (props) => {
  const [offer, setOffer] = useState("");
  const { langData, fetchLangData, translate } = useLanguage();
  const [services, setServices] = useState([]);

  useEffect(() => {
    handleAppendServices();
  }, []);

  const handleAppendServices = () => {
    const newItem = {
      id: services.length + 1,
      text:
        services.length === 0
          ? `Item ${services.length + 1}`
          : `Item ${services.length + 1}`,
    };
    setServices([...services, newItem]);
  };

  const handleRemove = (idToRemove) => {
    const updatedItems = services.filter((item) => item.id !== idToRemove);
    setServices(updatedItems);
  };

  const handleChange = (e) => {
    setOffer(e.target.value);
  };
  const handleServiceChange = (e, id) => {
    const { name, value } = e.target;
    const updatedServices = services.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          [name]: value,
        };
      }
      return item;
    });
    setServices(updatedServices);
  };

  // form submit
  const [base_price, setBasePrice] = useState(0);
  const handleBasePrice = (e) => {
    setBasePrice(e.target.value);
  };

  const [date_from, setDateFrom] = useState("");
  const [date_to, setDateTo] = useState("");
  const handleDateTo = (newValue) => {
    const dateValue = newValue?.toISOString() || ""; // Convert to ISO string
    setDateTo(dateValue);
  };

  // const [date_from, setDateFrom] = useState("");
  const handleDateFrom = (newValue) => {
    const dateValue = newValue?.toISOString() || ""; // Convert to ISO string
    setDateFrom(dateValue);
  };

  const [offer_setting, setOfferSetting] = useState({});
  const hanldeOfferSetting = (e) => {
    const { name, value } = e.target;
    setOfferSetting((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const [total_price, setTotalPrice] = useState(0);
  useEffect(() => {
    calculatePrice();
  }, [base_price, services]);

  const calculatePrice = () => {
    let price = 0;
    setTotalPrice(0);
    services?.forEach((item, index) => {
      if (item?.service_price == "") price += parseInt(0, 10);
      else price += parseInt(item?.service_price ?? 0, 10);
    });
    setTotalPrice(parseInt(base_price, 10) + price ?? 0);
  };

  const handleSubmit = async () => {
    try {
      let data = {
        offer,
        services,
        base_price,
        date_to,
        date_from,
        offer_setting,
        total_price,
        user: props.selectedUser,
        item_id: selected_boat?.id,
        type: "boat",
      };

      const res = await makeHttpRequest("POST", `owner/chat/offer/store`, data);

      if (res.success) {
        props.hide();
      } else if (!res.success) {
      } else {
      }
    } catch (error) {
      console.error("Error is:", error);
    }
  };

  // Change Boat
  const [open, setOpen] = useState(false);
  const [selected_boat, setSelectedBoat] = useState(false);
  const [boat_change, setBoatChange] = useState();
  const handleOpen = async () => {
    const res = await makeHttpRequest("POST", `owner/boats`);

    if (res.success) {
      setBoatChange(res?.data);
      props.hide();
      setOpen(true);
    } else if (!res.success) {
    } else {
    }
  };

  const handleHide = () => {
    props.handleShow();
    setOpen(false);
  };

  const selectedBoat = (boat) => {
    setSelectedBoat(boat);
    props.handleShow();
    setOpen(false);
  };
  return (
    <>
      <Dialog
        open={props.show}
        onClose={() => props.hide()}
        maxWidth="lg"
        fullWidth
        className="offer-modal" // Main class for styling
      >
        <DialogTitle className="offer-modal-title fw-bold">
          {translate("Offer Details")}
        </DialogTitle>
        <DialogContent className="offer-modal-content">
          <div className="offer-section">
            <div className="offer-header">
              <h6>{translate("Select a Boat")}</h6>
              {selected_boat ? (
                <Button className="offer-button" onClick={handleOpen}>
                  {translate("Change")}
                </Button>
              ) : (
                <Button className="offer-button" onClick={handleOpen}>
                  {translate("Select")}
                </Button>
              )}
            </div>
            {selected_boat && (
              <div className="gig2">
                <div className="image-container">
                  <img
                    src={selected_boat?.image}
                    alt={selected_boat?.name}
                    className="w-100 h-100"
                  />
                </div>
                <div className="text-container">
                  <p>
                    <span>
                      <BsStarFill style={{ color: "yellow" }} />
                    </span>
                    <span>
                      {selected_boat?.total_rating &&
                        selected_boat?.total_rating}
                    </span>
                    <span>
                      ({selected_boat?.review ? selected_boat?.review : 0})
                    </span>
                  </p>
                  <p>
                    {selected_boat?.name} ({selected_boat?.boat_type?.name})
                  </p>
                  <p className="text-end">
                    <span>
                      {props?.currency} {selected_boat?.price}
                    </span>
                  </p>
                </div>
              </div>
            )}
          </div>

          <div className="offer-section offer-description">
            <div className="offer-header">
              <h6>{translate("Describe your offer")}</h6>
            </div>
            <textarea
              className="offer-textarea"
              value={offer}
              onChange={handleChange}
              placeholder={translate("Enter Description")}
            />
          </div>

          <div className="offer-section" id="services">
            <div className="offer-header">
              <h6>{translate("Services/Charges Include")}</h6>
              <Button className="offer-button" onClick={handleAppendServices}>
                {translate("Add Service")}
              </Button>
            </div>
            {services.map((item, index) => (
              <div className="offer-service">
                <div className="offer-service-header">
                  <p className="service-name">
                    <span>{item?.id}.</span> &nbsp;
                    <input
                      type="text"
                      onChange={(e) => handleServiceChange(e, item?.id)}
                      placeholder={translate("service " + item?.id)}
                      name="service_title"
                      className="offer-input"
                    />
                  </p>
                  <div className="service-price">
                    <input
                      type="number"
                      onChange={(e) => handleServiceChange(e, item?.id)}
                      placeholder="0"
                      name="service_price"
                      className="offer-input service-input"
                    />
                    <span className="currency-symbol">{props?.currency}</span>
                  </div>
                  <span
                    onClick={() => handleRemove(item.id)}
                    className="service-remove"
                  >
                    <BsTrash style={{ color: "#ff0000", cursor: "pointer" }} />
                  </span>
                </div>
              </div>
            ))}
          </div>

          <div className="offer-section">
            <div className="offer-header">
              <h6>
                {translate("Booking Detail")}{" "}
                <span className="question-icon">
                  <BsQuestionCircleFill style={{ color: "#ffff" }} />
                </span>
              </h6>
            </div>
            <div className="offer-detail">
              <div className="offer-price">
                <p>
                  {translate("Basic Price")} ({props?.currency})
                </p>
                <input
                  type="text"
                  id="fname"
                  name="price"
                  onChange={handleBasePrice}
                  placeholder={translate("Basic Price")}
                  className="offer-input offer-price-input"
                />
              </div>
              <div className="offer-total-price">
                <p>{translate("Total Offer Price")}</p>
                <p className="offer-total">
                  <span id="total_offer_price">{total_price ?? 0}</span>&nbsp;
                  <span>{props?.currency}</span>
                </p>
              </div>
              <div className="row">
                <div className="col-lg-2 col-md-4 col-sm-12">
                  <p className="text-black mb-0 mt-2">
                    {translate("Booking Date/Time")}
                  </p>
                </div>
                <div className="col-lg-5 col-md-4 col-sm-12">
                  <input
                    type="datetime-local"
                    onChange={handleDateFrom}
                    id="from"
                    name="from"
                    className="offer-input offer-date-input"
                  />
                </div>
                <div className="col-lg-5 col-md-4 col-sm-12">
                  <input
                    type="datetime-local"
                    onChange={handleDateTo}
                    id="to"
                    name="to"
                    className="offer-input offer-date-input"
                  />
                </div>
              </div>
              <div className="offer-datetime">{translate("To")}</div>
            </div>
          </div>

          <div className="offer-section">
            <div className="offer-header">
              <h6>{translate("Define offer settings")}</h6>
            </div>
            <div className="offer-detail">
              <div className="offer-expiration">
                <p>
                  {translate("Expiration")}{" "}
                  <span>({translate("optional")})</span>
                </p>
                <input
                  type="number"
                  id="expire"
                  placeholder={translate("Days")}
                  onChange={hanldeOfferSetting}
                  name="expire"
                  value={offer_setting.expire}
                  className="offer-input offer-expiration-input"
                />
              </div>
            </div>
          </div>
        </DialogContent>

        <DialogActions className="offer-footer">
          <Button onClick={() => props.hide()}>{translate("Close")}</Button>
          <button className="offer-submit-btn" onClick={handleSubmit}>
            {translate("Send Offer")}
          </button>
        </DialogActions>
      </Dialog>
      {/* Select item */}
      <Modal show={open} onHide={handleHide} backdrop="static" size="md">
        <Modal.Body className="p-1">
          <div className="gig-section">
            <div className="d-flex justify-content-between align-items-center gig1 model-br">
              <h6>{translate("Select a Boat")}</h6>
            </div>
            {boat_change?.boats?.map((boat, index) => (
              <div className="gig2 m-1" onClick={() => selectedBoat(boat)}>
                <div className="image-container">
                  <img
                    src={boat?.image}
                    alt={boat?.name}
                    className="w-100 h-100"
                  />
                </div>
                <div className="text-container">
                  <p>
                    <span>
                      <BsStarFill style={{ color: "yellow" }} />
                    </span>
                    <span>{boat?.total_rating && boat?.total_rating}</span>
                    <span>({boat?.review ? boat?.review : 0})</span>
                  </p>
                  <p>
                    {boat?.name} ({boat?.boat_type?.name})
                  </p>
                  <p className="text-end">
                    <span>
                      {props?.currency} {boat?.price}
                    </span>
                  </p>
                </div>
              </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer className="model-footer">
          <Button variant="secondary" onClick={handleHide}>
            {translate("Close")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default OfferModal;
