import React, { useState } from "react";
import "./WithdrawLog.css";
import { Link } from "react-router-dom";
import { useLanguage } from "../../utils/LanguageContext";
import DashboardSideNav from "../DashboardSideNav/DashboardSideNav";
import Loader from "../../Components/Loader";

function WithdrawLog() {
  const { langData, fetchLangData, translate } = useLanguage();
  const [loading, setLoading] = useState(false);

  const data = [
    {
      txn: "NJWOXW TNX2AS",
      gateway: "Method 1",
      amount: "50.00 EUR",
      charge: "0.00 EUR",
      afterCharge: "50.00 EUR",
      rate: "1.00 EUR",
      receivable: "50.00 EUR",
      status: "Completed",
      time: "Time",
    },
    {
      txn: "NJWOXW TNX2AS",
      gateway: "Method 1",
      amount: "50.00 EUR",
      charge: "0.00 EUR",
      afterCharge: "50.00 EUR",
      rate: "1.00 EUR",
      receivable: "50.00 EUR",
      status: "Completed",
      time: "Time",
    },
    {
      txn: "NJWOXW TNX2AS",
      gateway: "Method 1",
      amount: "50.00 EUR",
      charge: "0.00 EUR",
      afterCharge: "50.00 EUR",
      rate: "1.00 EUR",
      receivable: "50.00 EUR",
      status: "Completed",
      time: "Time",
    },
    {
      txn: "NJWOXW TNX2AS",
      gateway: "Method 1",
      amount: "50.00 EUR",
      charge: "0.00 EUR",
      afterCharge: "50.00 EUR",
      rate: "1.00 EUR",
      receivable: "50.00 EUR",
      status: "Completed",
      time: "Time",
    },
    {
      txn: "NJWOXW TNX2AS",
      gateway: "Method 1",
      amount: "50.00 EUR",
      charge: "0.00 EUR",
      afterCharge: "50.00 EUR",
      rate: "1.00 EUR",
      receivable: "50.00 EUR",
      status: "Completed",
      time: "Time",
    },
    {
      txn: "NJWOXW TNX2AS",
      gateway: "Method 1",
      amount: "50.00 EUR",
      charge: "0.00 EUR",
      afterCharge: "50.00 EUR",
      rate: "1.00 EUR",
      receivable: "50.00 EUR",
      status: "Completed",
      time: "Time",
    },
    {
      txn: "NJWOXW TNX2AS",
      gateway: "Method 1",
      amount: "50.00 EUR",
      charge: "0.00 EUR",
      afterCharge: "50.00 EUR",
      rate: "1.00 EUR",
      receivable: "50.00 EUR",
      status: "Completed",
      time: "Time",
    },
    {
      txn: "NJWOXW TNX2AS",
      gateway: "Method 1",
      amount: "50.00 EUR",
      charge: "0.00 EUR",
      afterCharge: "50.00 EUR",
      rate: "1.00 EUR",
      receivable: "50.00 EUR",
      status: "Completed",
      time: "Time",
    },
  ];
  return (
    <>
     {loading && <Loader />}
      {!loading && (
        <div className="mainContainer">
          <div className="withdrawLog-headingWrapper">
            <div className="withdrawLog-heading">
              <h1>{translate("Withdraw Log")}</h1>
              <p>
                {translate("Total Withdraw Logs")}{" "}
                <span>{translate("20")}</span>
              </p>
            </div>
          </div>

          <div className="table-responsive">
            <div className="table-wrapper">
              <table id="mytable" className="table table-bordred table-striped">
                <thead className="withdrawLog-table-th">
                  <th>{translate("TXN")}</th>
                  <th>{translate("Gateway")}</th>
                  <th>{translate("Amount")}</th>
                  <th>{translate("Charge")}</th>
                  <th>{translate("After Charge")}</th>
                  <th>{translate("Rate")}</th>
                  <th>{translate("Receivable")}</th>
                  <th>{translate("Status")}</th>
                  <th>{translate("Action")}</th>
                </thead>
                <tbody>
                  {data.map((row, index) => (
                    <tr key={index} className="withdrawLog-td-wrapper">
                      <td>{row.txn}</td>
                      <td>{row.gateway}</td>
                      <td>{row.amount}</td>
                      <td
                        style={{ paddingTop: "20px" }}
                        className="withdrawLog-charge"
                      >
                        {row.charge}
                      </td>
                      <td>{row.afterCharge}</td>
                      <td>{row.rate}</td>
                      <td>{row.receivable}</td>
                      <td
                        style={{ paddingTop: "20px" }}
                        className="withdrawLog-status"
                      >
                        {row.status}
                      </td>
                      <td>{row.time}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default WithdrawLog;
