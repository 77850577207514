import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: "",
  blogs: "",
  selectedBlog: "",
};

export const blogSlice = createSlice({
  name: "landing",
  initialState,
  reducers: {
    addBlogs: (state, action) => {
      state.blogs = action.payload;
    },
    selectBlog: (state, action) => {
      state.selectedBlog = action.payload;
    },
  },
});

export const { selectBlog, addBlogs } = blogSlice.actions;

export default blogSlice.reducer;
