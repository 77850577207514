  import { toast } from "react-toastify";

  function Toaster(type, text) {
    if (!toast.isActive(text)) {
      return toast[type](text, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }
  }

  export default Toaster;
