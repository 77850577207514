import React from "react";
import classes from "./PrivacyPolicy.module.css";
import Footer from "../../Components/Footer/Footer";
import Navbar from "../../Components/Navbar/Navbar";
import UserSideNavbar from "../UserSideNavbar/UserSideNavbar";
import { useEffect } from "react";
import makeHttpRequest from "../../utils/api";
import { useState } from "react";
import { useParams } from "react-router-dom";
import he from "he";
import { useLanguage } from "../../utils/LanguageContext";



function PrivacyPolicy() {

  const [content, setContent] = useState('');
  const {id, slug} = useParams();
  const { langData, fetchLangData, translate } = useLanguage();

  useEffect(() => {
    fetchData();
  },[id]);
  const fetchData = async () => {
    try {
      const response = await makeHttpRequest("GET", `p/${id}/${slug}`);
      setContent(response.data);
    } catch (error) {
      console.error("Error is:", error);
    }
  };
  return (
    <>
      <Navbar />
      <div className="row" style={{ background: "#F3F3F5"}}>
        {/* <div className="col-lg-3 col-md-3 d-none d-lg-block">
          <UserSideNavbar />
        </div> */}
        <div className="col-lg-12 col-md-12 col-12">
          <div className={classes["privacyPolicy-Bg"]}>
            <div className={classes["privacyPolicy-heading"]}>
              <h1>{ content?.page_title }</h1>
            </div>
            <div className={classes["privacyPolicy-text"]} dangerouslySetInnerHTML={{
                    __html: content?.description
                      ? he.decode(content?.description)
                      : "",
                  }}>
            </div>
          </div>
          {/* <div className="privacyPolicy-Bg">
            <div className="privacyPolicy-heading">
              <h1>Privacy Policy</h1>
            </div>

            <div className="privacyPolicy-text">
              <h2>What information do we collect?</h2>
              <p>
                We gather data from you when you register on our site, submit a
                request, buy any services, react to an overview, or round out a
                structure. At the point when requesting any assistance or
                enrolling on our site, as suitable, you might be approached to
                enter your: name, email address, or telephone number. You may,
                nonetheless, visit our site anonymously.
              </p>
            </div>
            <div className="privacyPolicy-text">
              <h2>How do we protect your information?</h2>
              <p>All provided delicate/credit data is sent through Stripe.</p>
              <p>
                After an exchange, your private data (credit cards, social
                security numbers, financials, and so on) won't be put away on
                our workers.
              </p>
            </div>
            <div className="privacyPolicy-text">
              <h2>Do we disclose any information to outside parties?</h2>
              <p>
                We don't sell, exchange, or in any case move to outside
                gatherings by and by recognizable data. This does exclude
                confided in outsiders who help us in working our site, leading
                our business, or adjusting you, since those gatherings consent
                to keep this data private. We may likewise deliver your data
                when we accept discharge is suitable to follow the law,
                implement our site strategies, or ensure our own or others'
                rights, property, or wellbeing.
              </p>
            </div>
            <div className="privacyPolicy-text">
              <h2>Children's Online Privacy Protection Act Compliance</h2>
              <p>
                We are consistent with the prerequisites of COPPA (Children's
                Online Privacy Protection Act), we don't gather any data from
                anybody under 13 years old. Our site, items, and administrations
                are completely coordinated to individuals who are in any event
                13 years of age or more established.
              </p>
            </div>
            <div className="privacyPolicy-text">
              <h2>Changes to our Privacy Policy</h2>
              <p>
                If we decide to change our privacy policy, we will post those
                changes on this page.
              </p>
            </div>
            <div className="privacyPolicy-text">
              <h2>How long we retain your information?</h2>
              <p>
                At the point when you register for our site, we cycle and keep
                your information we have about you however long you don't erase
                the record or withdraw yourself (subject to laws and
                guidelines).
              </p>
            </div>
            <div className="privacyPolicy-text">
              <h2>What we don’t do with your data</h2>
              <p>
                We don't and will never share, unveil, sell, or in any case give
                your information to different organizations for the promoting of
                their items or administrations.
              </p>
            </div>
          </div> */}
        </div>
      </div>
      <Footer />

    </>
  );
}

export default PrivacyPolicy;
