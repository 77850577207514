import React, { useRef } from "react";
import "./ImageUploader.css";
import { useLanguage } from "../../utils/LanguageContext";

function ImageUploader({
  selectedImage,
  onImageChange,
  onChangeEvent,
  onFileChange,
}) {
  const fileInputRef = useRef(null);
  const { langData, fetchLangData, translate } = useLanguage();
  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const handleFileUpload = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      const imageUrl = URL.createObjectURL(selectedFile);
      onChangeEvent(selectedFile);
      onImageChange(imageUrl); // Update the selected image in the parent component
      // onFileChange(selectedFile);
    } else {
      onChangeEvent(null);
      onImageChange(null); // Clear the selected image in the parent component
      // onFileChange(null);
    }
  };

  const removeImage = () => {
    // Dont change this url becasue it is use as condition on backend
    onChangeEvent("/assets/icons/uploadIcon.png");
    onImageChange(null); // Clear the selected image in the parent component
    // onFileChange(null);
    // Clear the file input if needed
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <div>
      <div
        className={`uploadimage-attachment ${
          selectedImage ? "no-padding" : ""
        }`}
      >
        <div className="uploadimage-content">
          {selectedImage && (
            <div className="position-relative" style={{ height: "200px" }}>
              <img
                src={selectedImage}
                alt="Selected img"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
              <button
                className="btn-close uploadimage-closebtn"
                onClick={removeImage}
              ></button>
            </div>
          )}
          {!selectedImage && (
            <img
              src="/assets/icons/uploadIcon.png"
              alt="Upload Icon"
              onClick={triggerFileInput}
              style={{ cursor: "pointer", maxWidth: "100%", maxHeight: "100%" }}
            />
          )}
          {!selectedImage && <p>{translate("JEPG, JPG, PNG")}</p>}
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            accept="image/png, image/jpg, image/jpeg, image/gif, image/webp, video/mp4"
            onChange={handleFileUpload}
          />
        </div>
      </div>
    </div>
  );
}

export default ImageUploader;
