import React from "react";
import "./FASecurity.css";
import { AiFillCopy } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useLanguage } from "../../utils/LanguageContext";


function FASecurity() {
  const { langData, fetchLangData, translate } = useLanguage();

  return (
    <div className="faSecurity-container">
      <div className="row">
        <div className="col-lg-8 col-md-12 col-12">
          <div className="faSecurity-heading">
            <h1>{translate('Two Factor Authenticator')}</h1>
          </div>
          <div className="faSecurity-qrBg">
            <div className="input-group faSecurity-input">
              <input
                type="text"
                className="form-control"
                placeholder="YGJOYLO6UVXRLDW5"
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
              />
              <div className="input-group-append">
                <span
                  className="input-group-text"
                  id="basic-addon2"
                  style={{ background: "#45AAF0" }}
                >
                  <AiFillCopy style={{ color: "#FFF" }} />
                </span>
              </div>
            </div>

            <div className="faSecurity-QrImg">
              <img src="/assets/icons/faSecurityImg.png" alt="..." />
            </div>

            <div className="faSecurity-button">
              <button>
                <Link>{translate('Enable Two Factor Authentication')}</Link>
              </button>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-12 col-12">
          <div className="faSecurity-heading">
            <h1>{translate('Google Authenticator')}</h1>
          </div>

          <div className="faSecurity-qrBg">
            <div className="google-authenticator">
            <h2>
              {translate('Google Authenticator is a multifactor app for mobile devices. It generates timed codes used during the 2-step verification process. To use Google Authenticator, install the Google Authenticator application on your mobile device.')}
            </h2>
            </div>
            <div className="faSecurity-appBtn">
              <button>
                <Link>{translate('Download App')}</Link>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FASecurity;
