import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import classes from "./UserChat.module.css";
import { MdClose } from "react-icons/md";
import makeHttpRequest from "../../../utils/api";
import Toaster from "../../../Components/Toaster";
import { useLanguage } from "../../../utils/LanguageContext";
import { showAmount } from "../../../utils/showAmount";
import "../ChatStyle.css";
import "react-chat-elements/dist/main.css";
import "bootstrap/dist/css/bootstrap.min.css";

function PreviewModal() {
  const [preview_open, setPreviewOpen] = useState(false);
  const { translate } = useLanguage();
  const handlePreviewClose = () => setPreviewOpen(false);
  const [loading, setLoading] = useState(false);
  const [preview_data, setPreviewData] = useState();
  const [confirm_data, setConfirmData] = useState("");
  const [confirm_open, setConfirmOpen] = useState(false);

  let style = {};
  let style2 = {};
  const isMobile = window.innerWidth <= 767;
  if (isMobile) {
    style = {
      width: "90%",
      bgcolor: "background.paper",
      boxShadow: "0px 4px 32px 0px rgba(26, 26, 26, 0.10)",
      borderRadius: "16px",
      border: "none",
      outline: "none",
    };
    style2 = {
      width: "90%",
      bgcolor: "background.paper",
      boxShadow: "0px 4px 32px 0px rgba(26, 26, 26, 0.10)",
      borderRadius: "16px",
      border: "none",
      outline: "none",
    };
  } else {
    style = {
      width: "40%",
      bgcolor: "background.paper",
      boxShadow: "0px 4px 32px 0px rgba(26, 26, 26, 0.10)",
      borderRadius: "16px",
      border: "none",
      outline: "none",
    };
    style2 = {
      width: "60%",
      bgcolor: "background.paper",
      boxShadow: "0px 4px 32px 0px rgba(26, 26, 26, 0.10)",
      borderRadius: "16px",
      border: "none",
      outline: "none",
    };
  }

  const handleConfirmPayment = async () => {
    try {
      setLoading(true);
      const res = await makeHttpRequest("POST", "user/custom-payment/confirm", {
        track: preview_data?.track,
      });

      setLoading(false);
      if (res.success) {
        setConfirmData(res.data);
        setConfirmOpen(true);
      } else {
        Toaster("error", "Error");
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div>
      <Modal
        open={preview_open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className={classes["user-chat-modal-wrapper"]}>
          <Box sx={style2}>
            <div className={classes["user-chat-modal-heading"]}>
              <h1>{translate("Preview Payment")}</h1>
              <MdClose
                onClick={handlePreviewClose}
                style={{
                  color: "#FFF",
                  cursor: "pointer",
                  fontSize: "38px",
                  paddingRight: "10px",
                }}
              />
            </div>
            <div className={classes["user-chat-modal-inputs"]}>
              <div className={classes["user-chat-modal-content"]}>
                <div className="row justify-content-center">
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="custom--card card-deposit text-center">
                      <div className="card-body card-body-deposit">
                        <div className="row">
                          <div className="col-6">
                            <ul className="list-group text-center">
                              <p
                                key={0}
                                className="list-group-item img_list pb-4"
                              >
                                <img
                                  src={preview_data?.data?.image}
                                  alt="Payment Logo"
                                  width={200}
                                  height={200}
                                  className="w-50"
                                />
                              </p>
                            </ul>
                          </div>
                          <div className="col-6">
                            <ul className="list-group text-center">
                              <p
                                key={0}
                                className="list-group-item d-flex justify-content-between"
                              >
                                {translate("Amount")}
                                <strong>
                                  {showAmount(preview_data?.data?.amount)}{" "}
                                  {preview_data?.curr_name}
                                </strong>
                              </p>
                              <p
                                key={1}
                                className="list-group-item d-flex justify-content-between"
                              >
                                {translate("Charge")}
                                <strong>
                                  {showAmount(preview_data?.data?.charge)}{" "}
                                  {preview_data?.curr_name}
                                </strong>
                              </p>
                              <p
                                key={2}
                                className="list-group-item d-flex justify-content-between"
                              >
                                {translate("Payable")}{" "}
                                <strong>
                                  {" "}
                                  {showAmount(
                                    preview_data?.data?.amount +
                                      preview_data?.data?.charge
                                  )}
                                  {preview_data?.curr_name}
                                </strong>
                              </p>
                              <p
                                key={3}
                                className="list-group-item d-flex justify-content-between"
                              >
                                {translate("Conversion Rate")}{" "}
                                <strong>
                                  1 {preview_data?.curr_name} ={" "}
                                  {showAmount(preview_data?.data?.rate)}{" "}
                                  {preview_data?.data?.base_currency}
                                </strong>
                              </p>
                              <p
                                key={4}
                                className="list-group-item d-flex justify-content-between"
                              >
                                {translate("In")}{" "}
                                {preview_data?.data?.base_currency}:
                                <strong>
                                  {showAmount(preview_data?.data?.final_amo)}
                                </strong>
                              </p>
                              {preview_data?.data?.crypto == 1 && (
                                <p key={5} className="list-group-item">
                                  {translate("Conversion with")}
                                  <b>
                                    {" "}
                                    {preview_data?.data?.method_currency}
                                  </b>{" "}
                                  {translate(
                                    "and final value will Show on next step"
                                  )}
                                </p>
                              )}
                            </ul>
                          </div>
                        </div>

                        {1000 > preview_data?.data?.method_code ? (
                          <button
                            // {{route('user.deposit.confirm')}}"
                            className="btn btn-outline-primary d-block py-3 font-weight-bold mt-3 w-100"
                            onClick={handleConfirmPayment}
                          >
                            {translate("Proceed")}
                          </button>
                        ) : (
                          <a
                            href="{{route('user.deposit.manual.confirm')}}"
                            className="btn btn-outline-primary d-block py-3 font-weight-bold mt-3"
                            onClick={handleConfirmPayment}
                          >
                            {translate("Proceed")}
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </div>
      </Modal>
    </div>
  );
}

export default PreviewModal;
