import React from "react";
import "./Style.css";

function CenterHeading({ h1, p, justify }) {
  return (
    <div className="property-heading-wrapper">
      <div className="property-section-heading">
        <div className="property-headings">
          <img className="" src="/assets/icons/aa1.png" alt="Image" />
          <h1>{h1}</h1>
          <img className="img2" src="/assets/icons/aa2.jpeg" alt="Image" />
        </div>
      </div>
      <p className="text-center fs-7">{p}</p>
    </div>
  );
}

export default CenterHeading;
