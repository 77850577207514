export function setEqualHeight(selector) {
  const elementsToAdjust = document.querySelectorAll(selector);

  if (elementsToAdjust.length > 0) {
    let maxHeight = 0;

    elementsToAdjust.forEach((element) => {
      const elementHeight = element.clientHeight;
      if (elementHeight > maxHeight) {
        maxHeight = elementHeight;
      }
    });

    elementsToAdjust.forEach((element) => {
      element.style.height = `${maxHeight}px`;
    });
  }
}
