import React from "react";
import classes from "./LoginSecurity.module.css";
import { AiFillCopy } from "react-icons/ai";
import { Link } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import UserSideNavbar from "../UserSideNavbar/UserSideNavbar";
import Footer from "../../Components/Footer/Footer";
import { useLanguage } from "../../utils/LanguageContext";


function LoginSecurity() {
  const { langData, fetchLangData, translate } = useLanguage();

  return (
    <div>
      <Navbar />
      <div className="row" style={{ background: "#F3F3F5", padding: "120px" }}>
        <div className="col-lg-3 col-md-3 d-none d-lg-block">
          <UserSideNavbar />
        </div>
        <div className="col-lg-9 col-md-9 col-12">
          <div className={classes["loginSecurity-heading"]}>
            <h1>{translate('Login & Security')}</h1>
          </div>
          <div className={classes["loginSecurity-bg"]}>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12">
                <div className={classes["loginSecurity-title"]}>
                  <h1>{translate('Two Factor Authenticator')}</h1>
                </div>
                <div className={classes["loginSecurity-qrBg"]}>
                  <div className={`input-group ${classes['loginSecurity-input']}`}>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="YGJOYLO6UVXRLDW5"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                    />
                    <div className="input-group-append">
                      <span
                        className="input-group-text"
                        id="basic-addon2"
                        style={{ background: "#45AAF0" }}
                      >
                        <AiFillCopy style={{ color: "#FFF" }} />
                      </span>
                    </div>
                  </div>

                  <div className={classes["loginSecurity-QrImg"]}>
                    <img src="assets/icons/faSecurityImg.png" alt="..." />
                  </div>

                  <div className={classes["loginSecurity-button"]}>
                    <button>
                      <Link>{translate('Enable Two Factor Authentication')}</Link>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`${classes['loginSecurity-bg']} mt-5`}>
            <div className="row ">
              <div className="col-lg-12 col-md-12 col-12">
                <div className={classes["loginSecurity-heading"]}>
                  <h1>{translate('Google Authenticator')} </h1>
                </div>

                <div className={classes["loginSecurity-qrBg"]}>
                  <div className={classes["loginGoogle-authenticator"]}>
                    <h2>
                      {translate("Google Authenticator is a multifactor app for mobile devices. It generates timed codes used during the 2-step verification process. To use Google Authenticator, install the Google Authenticator application on your mobile device.")}
                    </h2>
                  </div>
                  <div className={classes["loginSecurity-appBtn"]}>
                    <button>
                      <Link>{translate('Download App')}</Link>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default LoginSecurity;
