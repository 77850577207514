import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { IoAdd, IoCloseOutline, IoRemove } from "react-icons/io5";
import { BiEnvelope } from "react-icons/bi";
import DashboardLogout from "../DashboardLogout/DashboardLogout";
import { useLanguage } from "../../utils/LanguageContext";
import "../Dashboard/Dashboard.css";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function OwnerSideBar({
  isSidebarOpen,
  handleCloseSidebar,
  activeTab,
  setActiveTab,
}) {
  const { translate } = useLanguage();

  const [openAccordion, setOpenAccordion] = useState(null);

  useEffect(() => {
    // Jab component load ho, default tab ko "dashboard-home" set karen
    if (!activeTab) {
      setActiveTab("dashboard-home");
    }
  }, [activeTab, setActiveTab]);

  const toggleAccordion = (section) => {
    setOpenAccordion(openAccordion === section ? null : section);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    handleCloseSidebar();
  };

  return (
    <>
      <div className={`sidebar  ${isSidebarOpen ? "open" : ""}`}>
        <div className="d-flex justify-content-between align-items-center mb-4">
          <div className="owner-dashboardLogo-img mb-0 mb-lg-4">
            <Link to="/dashboard">
              <img alt="" src="/assets/icons/dashboardLogo.png" />
            </Link>
          </div>
          <IoCloseOutline
            className="close-btn-ownerDashboard d-block d-lg-none"
            onClick={handleCloseSidebar}
          />
        </div>

        <div className="logout-button-owner-login d-none d-lg-block mt-3">
          <DashboardLogout />
        </div>

        <div
          onClick={() => {
            handleTabClick("dashboard-home");
          }}
        >
          <h1
            className={`dashboard-subTitle mb-4 mt-3 ${
              activeTab === "dashboard-home" ? "active" : ""
            }`}
          >
            {translate("DASHBOARD")}
          </h1>
        </div>

        <div className="chat-sidebar mb-3">
          <h1
            className="dashboard-subTitle mb-2"
            onClick={() => {
              setActiveTab("Chats");
              handleCloseSidebar();
            }}
          >
            {translate("Chat")}
          </h1>
          <BiEnvelope style={{ fontSize: "20px", color: "#6c9b2e" }} />
        </div>

        <hr />

        {/* Accommodation Accordion */}
        <Accordion
          expanded={openAccordion === "accommodation"}
          onChange={() => toggleAccordion("accommodation")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ color: "#8bc53e" }} />}
            aria-controls="accommodation-content"
            id="accommodation-header"
          >
            <h2 className="dashboard-subTitle">{translate("Accommodation")}</h2>
          </AccordionSummary>
          <AccordionDetails>
            <ul>
              <li
                onClick={() => handleTabClick("all-property")}
                className={activeTab === "all-property" ? "active" : ""}
              >
                {translate("All Properties")}
              </li>
              <li
                onClick={() => handleTabClick("roomCategory")}
                className={activeTab === "roomCategory" ? "active" : ""}
              >
                {translate("Room Category")}
              </li>
              <li
                onClick={() => handleTabClick("pending-booking-property")}
                className={
                  activeTab === "pending-booking-property" ? "active" : ""
                }
              >
                {translate("Pending Booking List")}
              </li>
              <li
                onClick={() => handleTabClick("success-booked-property")}
                className={
                  activeTab === "success-booked-property" ? "active" : ""
                }
              >
                {translate("List of Confirmed Reservations")}
              </li>
              <li
                onClick={() => handleTabClick("cancle-booked-property")}
                className={
                  activeTab === "cancle-booked-property" ? "active" : ""
                }
              >
                {translate("List of Cancelled Bookings")}
              </li>
            </ul>
          </AccordionDetails>
        </Accordion>

        {/* Boats Accordion */}
        <Accordion
          expanded={openAccordion === "boats"}
          onChange={() => toggleAccordion("boats")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ color: "#8bc53e" }} />}
            aria-controls="boats-content"
            id="boats-header"
          >
            <h2 className="dashboard-subTitle">{translate("Boats")}</h2>
          </AccordionSummary>
          <AccordionDetails>
            <ul>
              <li
                onClick={() => handleTabClick("all-Boat")}
                className={activeTab === "all-Boat" ? "active" : ""}
              >
                {translate("All Boats")}
              </li>
              <li
                onClick={() => handleTabClick("pending-booked-Boat")}
                className={activeTab === "pending-booked-Boat" ? "active" : ""}
              >
                {translate("Pending Booking List")}
              </li>
              <li
                onClick={() => handleTabClick("success-Booked-Boat")}
                className={activeTab === "success-Booked-Boat" ? "active" : ""}
              >
                {translate("List of Confirmed Reservations")}
              </li>
              <li
                onClick={() => handleTabClick("cancle-booked-boat")}
                className={activeTab === "cancle-booked-boat" ? "active" : ""}
              >
                {translate("List of Cancelled Bookings")}
              </li>
            </ul>
          </AccordionDetails>
        </Accordion>

        {/* Withdraw Accordion */}
        <Accordion
          expanded={openAccordion === "withdraw"}
          onChange={() => toggleAccordion("withdraw")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ color: "#8bc53e" }} />}
            aria-controls="withdraw-content"
            id="withdraw-header"
          >
            <h2 className="dashboard-subTitle">{translate("WITHDRAW")}</h2>
          </AccordionSummary>
          <AccordionDetails>
            <ul>
              <li
                onClick={() => handleTabClick("Withdraw-money")}
                className={activeTab === "Withdraw-money" ? "active" : ""}
              >
                {translate("Withdraw Money")}
              </li>
              <li
                onClick={() => handleTabClick("withdraw-log")}
                className={activeTab === "withdraw-log" ? "active" : ""}
              >
                {translate("Withdraw Log")}
              </li>
            </ul>
          </AccordionDetails>
        </Accordion>

        {/* Support Ticket Accordion */}
        <Accordion
          expanded={openAccordion === "ticket"}
          onChange={() => toggleAccordion("ticket")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ color: "#8bc53e" }} />}
            aria-controls="ticket-content"
            id="ticket-header"
          >
            <h2 className="dashboard-subTitle">
              {translate("SUPPORT TICKET")}
            </h2>
          </AccordionSummary>
          <AccordionDetails>
            <ul>
              <li
                onClick={() => handleTabClick("open-ticket")}
                className={activeTab === "open-ticket" ? "active" : ""}
              >
                {translate("Open Ticket")}
              </li>
              <li
                onClick={() => handleTabClick("ticket-history")}
                className={activeTab === "ticket-history" ? "active" : ""}
              >
                {translate("Ticket History")}
              </li>
            </ul>
          </AccordionDetails>
        </Accordion>

        <div className="logout-button-owner-login d-block d-lg-none mt-3">
          <DashboardLogout />
        </div>
      </div>
    </>
  );
}
export default OwnerSideBar;
