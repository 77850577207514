import React, { useState, useEffect, useRef } from "react";
import { MessageList, Avatar } from "react-chat-elements";
import "react-chat-elements/dist/main.css";
import "bootstrap/dist/css/bootstrap.min.css";
import classes from "./OwnerChat.module.css";
import "../ChatStyle.css";
import InputMessage from "./component/InputMessage";
import { useParams, Link } from "react-router-dom";
import makeHttpRequest from "../../../utils/api";
import { useLanguage } from "../../../utils/LanguageContext";
import {
  CircularProgress,
  Stack,
  TableFooter,
  Typography,
} from "@mui/material";
import ChatDisplayDate from "./component/ChatDisplayDate";
import { isDateExpired, pusherHelper, ucwords } from "../../../utils/helpers";
import { showAmount } from "../../../utils/showAmount";
import Pusher from "pusher-js";
import Echo from "laravel-echo";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { Dropdown } from "react-bootstrap";
import NickName from "./component/NickName";
import Note from "./component/Note";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Divider,
} from "@mui/material";

const Chat = () => {
  const { type, id, slug } = useParams();
  const [inputText, setInputText] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [userSentMessage, setUserSentMessage] = useState(false);
  const [sidebar, setSidebar] = useState(false);
  const { langData, fetchLangData, translate } = useLanguage();
  const [attachedFile, setAttachedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loading_chat, setLoadingChat] = useState(false);
  const [conversations, setConversations] = useState();
  const [chats, setChats] = useState([]);
  const [logged_id, setLoggedIn] = useState();
  const [scroll_below, setScrollBelow] = useState();
  const [messages_limit, setMessagesLimit] = useState(0);

  // pass currency to child
  const [currency, setCurrency] = useState();
  const [edit_chat, setEditChat] = useState();
  const [already_value, setAlreadyValue] = useState();

  // Trigger modal from here
  const [isOfferModalOpen, setIsOfferModalOpen] = useState(false);
  const openOfferModal = () => {
    setIsOfferModalOpen(true);
  };

  // Show NickName Model
  const [showNickNameModal, setShowNickNameModal] = useState(false);
  const handleCloseNickNameModal = () => setShowNickNameModal(false);
  const handleShowNickNameModal = (
    edit_chat_person_id,
    par_already_value = null
  ) => {
    setShowNickNameModal(true);
    setEditChat(edit_chat_person_id);
    setAlreadyValue(par_already_value);
  };

  // Show Note Model
  const [showNoteModal, setShowNoteModal] = useState(false);
  const handleCloseNoteModal = () => setShowNoteModal(false);
  const handleShowNoteModal = (
    edit_chat_person_id,
    par_already_value = null
  ) => {
    setShowNoteModal(true);
    setEditChat(edit_chat_person_id);
    setAlreadyValue(par_already_value);
  };

  // Delete Conversation
  async function handleDeleteConversation(id) {
    const res = await makeHttpRequest("POST", `owner/chat/delete`, { id });
    if (res?.success) {
      await getConversation(id, slug);
      setChats([]);
    } else {
      console.log(res?.message);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      await getConversation(id, slug);
    };

    fetchData();

    window.Pusher = Pusher;
    const echo = new Echo({
      broadcaster: "pusher",
      key: pusherHelper("api_key"),
      cluster: pusherHelper("cluster"),
      forceTLS: true,
    });

    echo.channel("chat-channel").listen(".chat-event", (e) => {
      setChats((prevMessages) => [...prevMessages, e.message]);
    });
  }, []);

  async function getConversation(id, slug) {
    try {
      setLoadingChat(true);
      const res = await makeHttpRequest("POST", `owner/chat/conversations`);
      if (res.success) {
        setConversations(res?.data?.chat);
        setSelectedUser(res?.data?.chat[0]);
        handleMessageClick(res?.data?.chat[0]);
        setCurrency(res?.data?.curr_sym);
        setLoadingChat(false);
      } else if (!res.success) {
      } else {
      }
    } catch (error) {
      console.error("Error is:", error);
      setLoadingChat(false);
    }
  }

  useEffect(() => {
    const fetchData = () => {
      document
        .querySelector("#chat-messages")
        .scrollTo(0, document.body.scrollHeight);
    };

    fetchData();
  }, [scroll_below]);
  const handleMessageClick = async (user, is_increment = true) => {
    setSelectedUser(user);
    if (user && user?.conversation_id !== 0) {
      try {
        setLoading(true);
        const res = await makeHttpRequest("POST", `owner/chat/messages`, {
          slug,
          id,
          user,
          limit: messages_limit,
        });
        if (res.success) {
          setScrollBelow(user);
          if (is_increment) setMessagesLimit(res?.data?.count);
          setChats(res?.data?.messages);
          setLoggedIn(res?.data?.logged_id);
        } else if (!res.success) {
        } else {
        }
      } catch (error) {
        console.error("Error is:", error);
        setLoading(false);
      }
    }
    setSidebar(false);
  };

  const handleHamburgerClick = () => {
    setSidebar(true);
  };

  const backdropHandle = () => {
    setSidebar(false);
  };

  function loadMoreMessages() {
    setMessagesLimit(messages_limit + 20);
    handleMessageClick(selectedUser);
  }

  return loading_chat ? (
    <Stack height="80vh" alignItems="center" justifyContent="center">
      <CircularProgress size={50} sx={{ color: "#8bc53e" }} />
    </Stack>
  ) : (
    <div className="container">
      <div className="rightbar p-2 border">
        <div className="row">
          <div className="col-lg-3 col-md-3 col-sm-3">
            <div className="chat-sideBar p-2">
              <h4>{translate("Messages")}</h4>
              <hr />
              <NickName
                show={showNickNameModal}
                handleClose={handleCloseNickNameModal}
                handleShow={handleShowNickNameModal}
                chat_person_id={edit_chat}
              />
              <Note
                show={showNoteModal}
                handleClose={handleCloseNoteModal}
                handleShow={handleShowNoteModal}
                chat_person_id={edit_chat}
                note_content={already_value}
              />
              <ul className={classes["chat-ul"]}>
                {conversations?.map((conversation) => (
                  <li
                    key={conversation.id}
                    className={`d-flex justify-content-between ${
                      classes["userId"]
                    } ${
                      selectedUser && selectedUser.id === conversation.id
                        ? classes["selected"]
                        : ""
                    }`}
                    onClick={() => handleMessageClick(conversation)}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <Avatar
                        src={conversation.image}
                        alt={`Profile ${conversation.name}`}
                        className={classes["avatar"]}
                        size="small"
                      />{" "}
                      &nbsp;
                      {conversation?.name} <br />
                      {conversation?.nick_name
                        ? "(" + conversation?.nick_name + ")"
                        : ""}
                    </div>

                    <Dropdown>
                      <Dropdown.Toggle
                        variant=""
                        id="dropdown-basic"
                        style={{ border: "none" }}
                      >
                        {/* <BiDotsHorizontalRounded size={35}/> */}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          href="#"
                          onClick={(e) =>
                            handleShowNickNameModal(
                              conversation?.id,
                              conversation?.nick_name
                            )
                          }
                        >
                          {translate("Nick Name")}
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={(e) =>
                            handleShowNoteModal(
                              conversation?.id,
                              conversation?.note
                            )
                          }
                        >
                          {translate("Note")}
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={(e) =>
                            handleDeleteConversation(
                              conversation?.conversation_id
                            )
                          }
                        >
                          {translate("Delete")}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>

                    {/* <div>
                      </div> */}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-lg-9 col-md-9 col-sm-9">
            <div className={classes["rightbar"]}>
              <div className={classes["navBar"]}>
                {selectedUser ? (
                  <h2 className={classes["selectedUser"]}>
                    <Avatar
                      src={selectedUser.image}
                      alt={`Profile ${selectedUser.name}`}
                      size="small"
                    />
                    {selectedUser.name}
                  </h2>
                ) : (
                  <h2 className={classes["selectedUser"]}>
                    {translate("Chat")}
                  </h2>
                )}
                <i
                  className={`fa fa-bars ${classes["hamburger"]}`}
                  onClick={handleHamburgerClick}
                ></i>
                <div
                  className={sidebar ? "backdrop" : "backdropNone"}
                  onClick={backdropHandle}
                ></div>
              </div>
              <div className={classes["chatWrapper"]}>
                <div className={classes["chat-messages"]} id="chat-messages">
                  {chats && chats.length > 0 && (
                    <div className="text-center">
                      <span
                        className={`badge badge-primary ${classes["my-bdg"]}`}
                        onClick={loadMoreMessages}
                      >
                        {translate("Load More Messages")}
                      </span>
                    </div>
                  )}
                  {chats &&
                    chats?.map((c) => (
                      <>
                        <ChatDisplayDate c={c} />
                        {c?.type == "message" && (
                          <MessageList
                            className={classes["message-list"]}
                            lockable={true}
                            toBottomHeight={"100%"}
                            dataSource={
                              [
                                {
                                  position:
                                    c?.sender_id == logged_id
                                      ? "right"
                                      : "left",
                                  type: "text",
                                  text: c?.content,
                                  date: new Date(c?.created_at),
                                  dateString: `${new Date(
                                    c?.created_at
                                  ).getHours()}:${new Date(c?.created_at)
                                    .getMinutes()
                                    .toString()
                                    .padStart(2, "0")}${
                                    new Date(c?.created_at).getHours() >= 12
                                      ? "PM"
                                      : "AM"
                                  }`,
                                },
                              ] || []
                            }
                          />
                        )}
                        {c?.type == "item" && (
                          <div className="card">
                            <div className="card-header">
                              <h3>
                                <div className={classes["card-head"]}>
                                  <span>
                                    {ucwords(JSON.parse(c?.content)?.item)}
                                  </span>
                                  {JSON.parse(c?.content)?.discount > 0.0 && (
                                    <span className="badge badge-success">
                                      {translate("Discount")} :{" "}
                                      {showAmount(
                                        JSON.parse(c?.content)?.discount
                                      )}{" "}
                                      {translate("%")}
                                    </span>
                                  )}
                                </div>
                              </h3>
                              {JSON.parse(c?.content)?.url && (
                                <div>
                                  (
                                  <Link
                                    to={JSON.parse(c?.content)?.url}
                                    target="_blank"
                                  >
                                    {JSON.parse(c?.content)?.url}
                                  </Link>
                                  )
                                </div>
                              )}
                            </div>
                            <div className="card-body">
                              <TableContainer component={Paper}>
                                <Table sx={{ border: "1px solid #ccc" }}>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell
                                        sx={{ border: "1px solid #ccc" }}
                                      >
                                        {translate("Inquiry Boat Name")}
                                      </TableCell>
                                      <TableCell
                                        sx={{ border: "1px solid #ccc" }}
                                      >
                                        {JSON.parse(c?.content)?.name}
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    <TableRow>
                                      <TableCell
                                        sx={{ border: "1px solid #ccc" }}
                                      >
                                        {translate("Boat Check-in & Check-out")}
                                      </TableCell>
                                      <TableCell
                                        sx={{ border: "1px solid #ccc" }}
                                      >
                                        {JSON.parse(c?.content)?.start}{" "}
                                        {translate("To")}{" "}
                                        {JSON.parse(c?.content)?.end} (
                                        {JSON.parse(c?.content)?.days}{" "}
                                        {translate("Days")})
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell
                                        sx={{ border: "1px solid #ccc" }}
                                      >
                                        {translate("Adult/Child")}
                                      </TableCell>
                                      <TableCell
                                        sx={{ border: "1px solid #ccc" }}
                                      >
                                        {JSON.parse(c?.content)?.adult} /{" "}
                                        {JSON.parse(c?.content)?.child}
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </div>
                            <div className="card-footer send-offer-btn">
                              <button
                                type="button"
                                onClick={openOfferModal}
                                ClassName="m-1"
                              >
                                {translate("Send Offer")}
                              </button>
                            </div>
                          </div>
                        )}
                        {c?.type == "offer" && (
                          <div className="card offer-voucher">
                            <div className="card-header bg-dark text-white">
                              <h3>
                                <div className="card-head">
                                  <span>{ucwords("custom " + c?.type)}</span>
                                </div>
                              </h3>
                            </div>
                            <div className="card-body">
                              <div className="offer-description-box border rounder-4">
                                <Typography
                                  sx={{ fontWeight: "bold" }}
                                  variant="h6"
                                >
                                  {translate("Offer Description")}:
                                </Typography>
                                <Divider sx={{ my: 1 }} />
                                <Typography
                                  variant="body1"
                                  className="offer-description-content"
                                >
                                  {JSON.parse(c?.content)?.offer}
                                </Typography>
                              </div>

                              <div
                                className="offer-dates "
                                style={{ marginTop: "16px" }}
                              >
                                <Typography
                                  sx={{ fontWeight: "bold" }}
                                  variant="h6"
                                >
                                  {translate("Check In")} &mdash;{" "}
                                  {translate("Check Out")}
                                </Typography>

                                <Typography variant="body2" className="mb-3">
                                  {JSON.parse(c?.content)?.date_from} &mdash;{" "}
                                  {JSON.parse(c?.content)?.date_to}
                                </Typography>
                              </div>

                              <Typography
                                variant="h6"
                                className="offer-services-title"
                                sx={{ mt: 2 }}
                              >
                                {translate("Offer Services")}:
                              </Typography>

                              <TableContainer component={Paper} sx={{ mt: 1 }}>
                                <Table>
                                  <TableBody>
                                    {JSON.parse(c?.content)?.services?.map(
                                      (item, index) => (
                                        <TableRow key={index}>
                                          <TableCell
                                            sx={{ border: "1px solid #ccc" }}
                                            align="center"
                                          >
                                            {item["service_title"] &&
                                              translate(
                                                ucwords(item["service_title"])
                                              )}
                                          </TableCell>
                                          <TableCell
                                            sx={{ border: "1px solid #ccc" }}
                                            align="center"
                                          >
                                            {item["service_price"] &&
                                              currency +
                                                translate(
                                                  item["service_price"]
                                                )}
                                          </TableCell>
                                        </TableRow>
                                      )
                                    )}
                                  </TableBody>
                                  <TableFooter>
                                    <TableRow>
                                      <TableCell
                                        sx={{ border: "1px solid #ccc" }}
                                        align="center"
                                      >
                                        {translate("Boat Rent")}
                                      </TableCell>
                                      <TableCell align="center">
                                        {currency +
                                          JSON.parse(c?.content)?.base_price}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell
                                        sx={{ border: "1px solid #ccc" }}
                                        align="center"
                                      >
                                        {translate("Total Price")}
                                      </TableCell>
                                      <TableCell
                                        sx={{ border: "1px solid #ccc" }}
                                        align="center"
                                      >
                                        {currency +
                                          JSON.parse(c?.content)?.total_price}
                                      </TableCell>
                                    </TableRow>
                                  </TableFooter>
                                </Table>
                              </TableContainer>
                            </div>
                          </div>
                        )}
                      </>
                    ))}
                </div>
              </div>
              {conversations && conversations.length > 0 ? (
                <InputMessage
                  type={type}
                  id={id}
                  inputState={{ inputText, setInputText }}
                  sidebar={sidebar}
                  selectedUser={selectedUser}
                  setUserSentMessage={setUserSentMessage}
                  setAttachedFile={setAttachedFile}
                  classes={classes}
                  isOfferModalOpen={isOfferModalOpen}
                  setIsOfferModalOpen={setIsOfferModalOpen}
                  currency={currency}
                />
              ) : (
                <h4 className="text-center">{translate("No Chat found")}</h4>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;
