import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: "",
  data: "",
};

export const landingSlice = createSlice({
  name: "landing",
  initialState,
  reducers: {
    addData: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { addData } = landingSlice.actions;

export default landingSlice.reducer;
