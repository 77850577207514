import React, { useEffect, useRef, useState } from "react";
import { useLanguage } from "../../utils/LanguageContext";
import { useDispatch, useSelector } from "react-redux";
import { userLogged } from "../../UserDashboardComponent/UserProfile/userSlice";
import WorkHeading from "../../Components/Heading/WorkHeading";
import { Link, useNavigate } from "react-router-dom";
import ExtensionApi from "../../Components/ExtensionApi";
import makeHttpRequest from "../../utils/api";
import Toaster from "../../Components/Toaster";

function TravelExperience() {
  const navigate = useNavigate();
  const { translate } = useLanguage();
  const { data } = useSelector((state) => state.landing);
  const [state, setState] = useState({ username: "", password: "" });
  const [isCaptchaPassed, setIsCaptchaPassed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const dispatch = useDispatch();
  const usernameRef = useRef(null);
  const passwordRef = useRef(null);

  useEffect(() => {
    // Reset the form state when the component mounts
    setState({ username: "", password: "" });
    setIsCaptchaPassed(false);

    // Check if the user is already logged in
    const token = localStorage.getItem("bearerToken");
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const handleLogin = async () => {
    if (!isCaptchaPassed) {
      Toaster("warning", "Captcha Required");
      return;
    }

    const { username, password } = state;
    if (!username) {
      Toaster("info", "Enter username");
      return;
    }
    if (!password) {
      Toaster("info", "Enter password");
      return;
    }

    try {
      setLoading(true);
      const res = await makeHttpRequest("POST", "login", {
        username,
        password,
        user_type: "user",
      });
      setLoggedUser(res);
    } catch (error) {
      console.error("Login error:", error);
      Toaster("error", "Login failed");
    } finally {
      setLoading(false);
    }
  };

  const setLoggedUser = (res) => {
    if (res?.success) {
      localStorage.setItem("bearerToken", res.data.token);
      localStorage.setItem("userType", res.data.type);
      localStorage.setItem("userEmail", res.data.user?.email);
      dispatch(userLogged(res.data.type === "user" ? "user" : "owner"));
      setIsLoggedIn(true);
      navigate("/"); // Redirect to home page
    } else {
      Toaster("error", "Invalid credentials");
    }
  };
  return (
    <div className="container">
      <div className="row mt-lg-5 pt-lg-5">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <WorkHeading
            h1={translate(data?.work?.title)}
            p={translate(data?.work?.heading)}
          />

          <div>
            {data?.works?.map((value, i) => (
              <>
                <div key={i}>
                  <div className="experience-card mt-5">
                    <h3 className="experience-card-title">
                      {translate(value?.data_values?.step)}
                    </h3>
                    <p className="experience-card-description">
                      {translate(value?.data_values?.description)}
                    </p>
                  </div>
                </div>
              </>
            ))}

            <div className="travel-exp-login-button">
              <Link to="/login">
                <button>Login</button>
              </Link>
            </div>
          </div>

          {/* <div className="row justify-content-center">
            {data?.works?.map((value, i) => (
              <div
                key={i}
                className="col-lg-3 col-md-4 col-sm-6 col-12 mt-4 d-flex justify-content-center"
              >
                <div className="experience-card">
                  <div className="experience-card-content">
                    <h3 className="experience-card-title">
                      {translate(value?.data_values?.step)}
                    </h3>
                    <p className="experience-card-description">
                      {translate(value?.data_values?.description)}
                    </p>
                  </div>
                </div>
                <div className="text-end mt-3">
                  <Link to="/login">
                    <button className="experience-card-button">
                      {translate("Login")}
                    </button>
                  </Link>
                </div>
              </div>
            ))}
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default TravelExperience;
