import React from "react";
import classes from "./PrivacyPolicy.module.css";
import Footer from "../../Components/Footer/Footer";
import Navbar from "../../Components/Navbar/Navbar";
import UserSideNavbar from "../UserSideNavbar/UserSideNavbar";
import { useEffect } from "react";
import makeHttpRequest from "../../utils/api";
import { useState } from "react";
import { useParams } from "react-router-dom";
import he from "he";
import { useLanguage } from "../../utils/LanguageContext";



function TermsPolicyStatic() {

  const [content, setContent] = useState('');
  const {id, slug} = useParams();
  const { langData, fetchLangData, translate } = useLanguage();

  useEffect(() => {
    fetchData();
  },[id]);
  const fetchData = async () => {
    try {
      const response = await makeHttpRequest("GET", 'p/43/terms-of-services');
      setContent(response.data);
    } catch (error) {
      console.error("Error is:", error);
    }
  };
  return (
    <>
      <Navbar />
      <div className="row" style={{ background: "#F3F3F5"}}>
        {/* <div className="col-lg-3 col-md-3 d-none d-lg-block">
          <UserSideNavbar />
        </div> */}
        <div className="col-lg-12 col-md-12 col-12">
          <div className={classes["privacyPolicy-Bg"]}>
            <div className={classes["privacyPolicy-heading"]}>
              <h1>{ content?.page_title }</h1>
            </div>
            <div className={classes["privacyPolicy-text"]} dangerouslySetInnerHTML={{
                    __html: content?.description
                      ? he.decode(content?.description)
                      : "",
                  }}>
            </div>
          </div>
        </div>
      </div>
      <Footer />

    </>
  );
}

export default TermsPolicyStatic;
