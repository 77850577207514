import React, { useState } from "react";
import { Dialog, List, ListItem, ListItemText } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import { clearUser } from "../../UserDashboardComponent/UserProfile/userSlice";
import { useLanguage } from "../../utils/LanguageContext";

function UserSidebar() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector((state) => state.user);
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const { translate } = useLanguage();

  const handleOpenSidebar = () => setSidebarOpen(true);
  const handleCloseSidebar = () => setSidebarOpen(false);

  const handleLogout = () => {
    dispatch(clearUser());
    localStorage.removeItem("bearerToken");
    localStorage.removeItem("userType");
    localStorage.removeItem("userEmail");
    navigate("/");
  };

  const menuItems = [
    {
      text: translate("Availability Request"),
      path: "/dashboard",
    },
    {
      text: translate("Property Booking History"),
      path: "/property-history",
    },
    {
      text: translate("Boat Booking History"),
      path: "/boat-history",
    },
    {
      text: translate("Reviews"),
      path: "/user-reviews",
    },
    {
      text: translate("Support Tickets"),
      path: "/support-ticket",
    },
    {
      text: translate("Change Password"),
      path: "/change-password",
    },
    {
      text: translate("Logout"),
      onClick: handleLogout,
    },
  ];

  return (
    <div>
      {/* {isLoggedIn && (
        <div className="floating-btn-container" onClick={handleOpenSidebar}>
          <button
            className="side-bar-btn"
            style={{ width: "120px", height: "35px", paddingLeft:'20px' }}
          >
            More Options
          </button>
        </div>
      )} */}
      <Dialog
        open={isSidebarOpen}
        onClose={handleCloseSidebar}
        PaperProps={{
          style: {
            width: "80%", // Set the width of the sidebar
            maxWidth: "300px", // Set a maximum width if desired
            backgroundColor: "#f8fbfc",
          },
        }}
      >
        <List>
          {menuItems.map((menuItem, index) => (
            <ListItem
              button
              key={index}
              onClick={() => {
                handleCloseSidebar();
                if (menuItem.onClick) menuItem.onClick();
              }}
              className={pathname === menuItem.path ? "active" : ""}
            >
              {menuItem.path ? (
                <Link className="nav-link" to={menuItem.path}>
                  <ListItemText primary={menuItem.text} />
                </Link>
              ) : (
                <div className="nav-link" onClick={menuItem.onClick}>
                  {menuItem.text}
                </div>
              )}
            </ListItem>
          ))}
        </List>
      </Dialog>
    </div>
  );
}

export default UserSidebar;
