import React, { useState, useEffect, useRef } from "react";
import { MessageList, Avatar } from "react-chat-elements";
import "react-chat-elements/dist/main.css";
import "bootstrap/dist/css/bootstrap.min.css";
import classes from "./UserChat.module.css";
import "../ChatStyle.css";
import InputMessage from "./component/InputMessage";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useLanguage } from "../../../utils/LanguageContext";
import { CircularProgress, Stack } from "@mui/material";
import ChatDisplayDate from "./component/ChatDisplayDate";
import makeHttpRequest from "../../../utils/api";
import { showAmount } from "../../../utils/showAmount";
import { isDateExpired, pusherHelper, ucwords } from "../../../utils/helpers";
import Echo from "laravel-echo";
import Pusher from "pusher-js";
import { MdClose, MdOutlineMenu } from "react-icons/md";

import Toaster from "../../../Components/Toaster";
import PaymentModal from "./PaymentModal";
import PreviewModal from "./PreviewModal";
import ConfirmModal from "./ConfirmModal";
import { FaExternalLinkAlt } from "react-icons/fa"; // Import the redirection icon

let style = {};
let style2 = {};
const isMobile = window.innerWidth <= 767;
if (isMobile) {
  style = {
    width: "90%",
    bgcolor: "background.paper",
    boxShadow: "0px 4px 32px 0px rgba(26, 26, 26, 0.10)",
    borderRadius: "16px",
    border: "none",
    outline: "none",
  };
  style2 = {
    width: "90%",
    bgcolor: "background.paper",
    boxShadow: "0px 4px 32px 0px rgba(26, 26, 26, 0.10)",
    borderRadius: "16px",
    border: "none",
    outline: "none",
  };
} else {
  style = {
    width: "40%",
    bgcolor: "background.paper",
    boxShadow: "0px 4px 32px 0px rgba(26, 26, 26, 0.10)",
    borderRadius: "16px",
    border: "none",
    outline: "none",
  };
  style2 = {
    width: "60%",
    bgcolor: "background.paper",
    boxShadow: "0px 4px 32px 0px rgba(26, 26, 26, 0.10)",
    borderRadius: "16px",
    border: "none",
    outline: "none",
  };
}

const UserChat = () => {
  const { item, type = "message", id, slug } = useParams();
  const navigate = useNavigate();
  const [inputText, setInputText] = useState("");
  const [inputIframe, setInputIframe] = useState(
    `${window.location.origin}/${item}/${id}/${slug}/details`
  );
  const [selectedUser, setSelectedUser] = useState(null);
  const [userSentMessage, setUserSentMessage] = useState(false);
  const [sidebar, setSidebar] = useState(false);
  // console.log("sidebar", sidebar);
  const sidebarRef = useRef(null);
  const { translate } = useLanguage();
  const [attachedFile, setAttachedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loading_chat, setLoadingChat] = useState(false);
  const [conversations, setConversations] = useState();

  const [chats, setChats] = useState([]);

  const [logged_id, setLoggedIn] = useState();
  const [scroll_below, setScrollBelow] = useState();
  const [messages_limit, setMessagesLimit] = useState(0);
  const [dropdownOpen, setDropdownOpen] = useState(null);

  // payment
  const [booked_id, setBookedId] = useState();
  const [booked_type, setBookedType] = useState(null);
  const [booked_payment_gateway, setBookedPaymentGateway] = useState();
  const [preview_data, setPreviewData] = useState();
  const [confirm_data, setConfirmData] = useState("");

  const [payment_open, setPaymentOpen] = useState(false);
  const handlePaymentClose = () => setPaymentOpen(false);

  const userAvatar = "/assets/icons/noImageFound.jpg"; // Replace with actual user avatar path
  const ownerAvatar = "/assets/icons/noImageFound.jpg"; // Replace with actual owner avatar path

  const [activeTab, setActiveTab] = useState("chats");

  const [currency, setCurrency] = useState();

  useEffect(() => {
    const fetchData = async () => {
      await getConversation(id, slug);
    };

    fetchData();

    window.Pusher = Pusher;
    const echo = new Echo({
      broadcaster: "pusher",
      key: pusherHelper("api_key"),
      cluster: pusherHelper("cluster"),
      forceTLS: true,
    });

    echo.channel("chat-channel").listen(".chat-event", (e) => {
      setChats((prevMessages) => [...prevMessages, e.message]);
    });
  }, []);

  async function getConversation(id, slug) {
    try {
      setLoadingChat(true);
      const res = await makeHttpRequest("POST", `user/chat/conversations`, {
        slug,
        id,
      });
      if (res.success) {
        setConversations(res?.data?.chat);
        setSelectedUser(res?.data?.chat[0]);
        handleMessageClick(res?.data?.chat[0]);
        setCurrency(res?.data?.curr_sym);
        setLoadingChat(false);
      } else if (!res.success) {
        navigate(inputIframe);
      } else {
        navigate(inputIframe);
      }
    } catch (error) {
      console.error("Error is:", error);
      setLoadingChat(false);
    }
  }

  useEffect(() => {
    const fetchData = () => {
      document
        .querySelector("#chat-messages")
        ?.scrollTo(0, document.body.scrollHeight);
    };

    fetchData();
  }, [scroll_below]);

  const handleMessageClick = async (user, is_increment = true) => {
    setSelectedUser(user);
    // Get Message with this user
    if (user && user?.conversation_id !== 0) {
      try {
        setLoading(true);
        const res = await makeHttpRequest("POST", `user/chat/messages`, {
          slug,
          id,
          user,
          limit: messages_limit,
        });
        if (res.success) {
          setScrollBelow(user);
          if (is_increment) setMessagesLimit(res?.data?.count);
          setChats(res?.data?.messages);
          setLoggedIn(res?.data?.logged_id);
        } else if (!res.success) {
          navigate(inputIframe);
        } else {
          navigate(inputIframe);
        }
      } catch (error) {
        console.error("Error is:", error);
        setLoading(false);
      }
    }
    setSidebar(false);
  };

  const handleHamburgerClick = () => {
    setSidebar((prev) => !prev);
  };

  const toggleDropdown = (e, id) => {
    e.stopPropagation(); // Prevent click event from propagating
    setDropdownOpen((prev) => (prev === id ? null : id)); // Toggle dropdown visibility
  };

  const handleDelete = (id) => {
    // Delete the boat detail for the selected id
    setChats((prevChats) => prevChats.filter((chat) => chat.id !== id));

    // Optionally, make an API call to persist the deletion
  };

  const backdropHandle = () => {
    setSidebar(false);
  };

  function loadMoreMessages() {
    setMessagesLimit(messages_limit + 20);
    handleMessageClick(selectedUser);
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setSidebar(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [sidebarRef]);

  const handleSetupPayment = async (id, type) => {
    try {
      setLoading(true);
      const res = await makeHttpRequest("POST", "user/custom-payment", {
        id: id,
        type: type,
      });
      setLoading(false);
      if (res.success) {
        setBookedPaymentGateway(res.data);
        setPaymentOpen(true);
        setBookedId(id);
        setBookedType(type); // This should set booked_type correctly
      } else {
        Toaster("error", "Error: " + res.message); // Ensure you provide a type and a message
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    }).format(date);
  };

  return (
    <div>
      {activeTab === "chats" && (
        <div className="container mt-4">
          <div>
            <div className="d-flex justify-content-center">
              {/* payment modal */}
              <PaymentModal />

              {/* Preview Modal */}

              <PreviewModal />

              {/* Confirm Modal */}

              <ConfirmModal />
            </div>
            {loading_chat ? (
              <Stack height="80vh" alignItems="center" justifyContent="center">
                <CircularProgress size={50} sx={{ color: "#8bc53e" }} />
              </Stack>
            ) : (
              <>
                <div className={classes["chat-container"]}>
                  <div
                    ref={sidebarRef}
                    className={
                      sidebar
                        ? `${classes["sidebar"]} ${classes["sidebarMobile"]}`
                        : classes["sidebar"]
                    }
                  >
                    <div className="sidebar-heading">
                      <h4>{translate("All Messages")}</h4>
                    </div>
                    <ul className={classes["chatList"]}>
                      {conversations?.map((conversation) => (
                        <li
                          key={conversation?.id}
                          className={` chat-item-section ${
                            classes["chatItem"]
                          } ${
                            selectedUser && selectedUser.id === conversation?.id
                              ? classes["selected"]
                              : ""
                          }`}
                          onClick={() => handleMessageClick(conversation)}
                        >
                          <Avatar
                            src={conversation?.image}
                            alt={`Profile ${conversation?.name}`}
                            className={classes["avatar"]}
                            size="small"
                          />
                          {conversation?.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className={classes["rightbar"]}>
                    <div className="chat-section-background">
                      <div className="d-flex justify-content-between align-item-center p-3">
                        <div>
                          <h2 className={classes["selectedUser"]}>
                            {selectedUser?.name}
                          </h2>

                          <p className="last_message_detail">
                            {selectedUser?.last_message}
                          </p>
                        </div>
                        {chats && chats?.length > 0 && (
                          <div className="text-center">
                            <span
                              className={`badge badge-primary ${classes["my-bdg"]}`}
                              onClick={loadMoreMessages}
                            >
                              {translate("Load More Messages")}
                            </span>
                          </div>
                        )}

                        <i
                          className={`mr-3 ${classes["hamburger"]} ${
                            sidebar ? "open" : ""
                          }`}
                          onClick={handleHamburgerClick}
                          style={{ cursor: "pointer" }} // Add pointer cursor for better UX
                        >
                          {sidebar ? <MdClose /> : <MdOutlineMenu />}
                        </i>
                      </div>
                    </div>
                    <div className={classes["chatWrapper"]}>
                      <div
                        className={classes["chat-messages"]}
                        id="chat-messages"
                      >
                        {chats?.map((c) => (
                          <>
                            {/* <ChatDisplayDate c={c} /> */}
                            {c?.type == "message" && (
                              <div
                                className={`message-bubble ${
                                  c?.sender_id === logged_id ? "right" : "left"
                                }`}
                              >
                                <div className="message-avatar">
                                  <img
                                    src={
                                      c?.sender_id === logged_id
                                        ? userAvatar
                                        : ownerAvatar
                                    }
                                    alt="avatar"
                                  />
                                </div>
                                <div className="message-content">
                                  <div className="message-header">
                                    <span className="message-time">
                                      {/* Formatting the date and time */}
                                      {formatDate(c?.created_at)}
                                    </span>
                                  </div>
                                  <p>{c?.content}</p>
                                </div>
                              </div>
                            )}

                            {/* {c?.type == "message" && (
                              <div
                                className={`message-bubble ${
                                  c?.sender_id === logged_id ? "right" : "left"
                                }`}
                              >
                                <div className="message-avatar">
                                  <img
                                    src={
                                      c?.sender_id === logged_id
                                        ? userAvatar
                                        : ownerAvatar
                                    }
                                    alt="avatar"
                                  />
                                </div>
                                <div className="message-content">
                                  <p>{c?.content}</p>
                                  <div className="message-footer">
                                    <span className="message-time">
                                      {`${new Date(
                                        c?.created_at
                                      ).getHours()}:${new Date(c?.created_at)
                                        .getMinutes()
                                        .toString()
                                        .padStart(2, "0")}${
                                        new Date(c?.created_at).getHours() >= 12
                                          ? "PM"
                                          : "AM"
                                      }`}
                                    </span>
                                    <span className="message-sender">
                                      {c?.sender_id === logged_id
                                        ? "You"
                                        : "Owner"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            )} */}

                            {c?.type == "item" && (
                              <div className="item-card">
                                <div className="item-header">
                                  <div className="mr-4">
                                    {JSON.parse(c?.content)?.url && (
                                      <FaExternalLinkAlt
                                        className="external-link"
                                        onClick={() =>
                                          window.open(
                                            JSON.parse(c?.content)?.url,
                                            "_blank"
                                          )
                                        }
                                      />
                                    )}
                                    {JSON.parse(c?.content)?.discount > 0 && (
                                      <div className="discount-badge">
                                        {translate("Discount")}:{" "}
                                        {showAmount(
                                          JSON.parse(c?.content)?.discount
                                        )}
                                        %
                                      </div>
                                    )}
                                  </div>

                                  <div className="dropdown">
                                    <i
                                      className="three-dots-icon"
                                      onClick={(e) => toggleDropdown(e, c?.id)}
                                    >
                                      •••
                                    </i>
                                    {dropdownOpen === c?.id && (
                                      <div className="dropdown-menu">
                                        <button
                                          className="dropdown-item"
                                          onClick={() => handleDelete(c?.id)}
                                        >
                                          {translate("Delete")}
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6 col-md-6 col-sm-12"></div>
                                  <div className="col-lg-6 col-md-6 col-sm-12">
                                    {" "}
                                  </div>
                                </div>

                                <div className="item-info">
                                  <div className="info-row">
                                    <span className="info-label">
                                      {translate("Inquiry Boat Name")}
                                    </span>
                                    <span className="info-value">
                                      {JSON.parse(c?.content)?.name}
                                    </span>
                                  </div>
                                  <div className="info-row">
                                    <span className="info-label">
                                      {translate("Boat Check-in")}
                                    </span>
                                    <span className="info-value">
                                      {JSON.parse(c?.content)?.start}
                                    </span>
                                  </div>
                                  <div className="info-row">
                                    <span className="info-label">
                                      {translate("Boat Check-out")}
                                    </span>
                                    <span className="info-value">
                                      {JSON.parse(c?.content)?.end}
                                    </span>
                                  </div>
                                  <div className="info-row">
                                    <span className="info-label">
                                      {translate("Adult/Child")}
                                    </span>
                                    <span className="info-value">
                                      {JSON.parse(c?.content)?.adult}-
                                      {JSON.parse(c?.content)?.child}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            )}

                            {/* {c?.type == "message" && (
                              <MessageList
                                className={classes["message-list"]}
                                lockable={true}
                                toBottomHeight={"100%"}
                                dataSource={
                                  [
                                    {
                                      position:
                                        c?.sender_id === logged_id
                                          ? "right"
                                          : "left",
                                      type: "text",
                                      text: c?.content,
                                      date: new Date(c?.created_at),
                                      dateString: `${new Date(
                                        c?.created_at
                                      ).getHours()}:${new Date(c?.created_at)
                                        .getMinutes()
                                        .toString()
                                        .padStart(2, "0")}${
                                        new Date(c?.created_at).getHours() >= 12
                                          ? "PM"
                                          : "AM"
                                      }`,
                                      title:
                                        c?.sender_id === logged_id
                                          ? "You"
                                          : "Owner", // Shows "You" for your messages, otherwise "User"
                                    },
                                  ] || []
                                }
                              />
                            )}

                            {c?.type == "item" && (
                              <div className="mb-3">
                                <div className="boat-details-card">
                                  <div className="boat-header d-flex justify-content-between align-items-center">
                                    {JSON.parse(c?.content)?.url && (
                                      <FaExternalLinkAlt
                                        style={{
                                          color: "#007bff",
                                          cursor: "pointer",
                                          marginLeft: "5px",
                                        }}
                                        onClick={() =>
                                          window.open(
                                            JSON.parse(c?.content)?.url,
                                            "_blank"
                                          )
                                        }
                                      />
                                    )}

                                    <div className="dropdown">
                                      <i
                                        className="three-dots-icon"
                                        onClick={(e) =>
                                          toggleDropdown(e, c?.id)
                                        }
                                      >
                                        •••
                                      </i>
                                      {dropdownOpen === c?.id && (
                                        <div className="dropdown-menu">
                                          <button
                                            className="dropdown-item"
                                            onClick={() => handleDelete(c?.id)}
                                          >
                                            {translate("Delete")}
                                          </button>
                                        </div>
                                      )}
                                    </div>
                                  </div>

                                  {JSON.parse(c?.content)?.discount > 0 && (
                                    <div className="discount-badge">
                                      {translate("Discount")}:{" "}
                                      {showAmount(
                                        JSON.parse(c?.content)?.discount
                                      )}
                                      %
                                    </div>
                                  )}

                                  <div className="boat-info">
                                    <div className="info-row">
                                      <span className="info-label">
                                        {translate("Inquiry Boat Name")}
                                      </span>
                                      <span className="info-value">
                                        {JSON.parse(c?.content)?.name}
                                      </span>
                                    </div>
                                    <div className="info-row">
                                      <span className="info-label">
                                        {translate("Boat Check-in")}
                                      </span>
                                      <span className="info-value">
                                        {JSON.parse(c?.content)?.start}
                                      </span>
                                    </div>
                                    <div className="info-row">
                                      <span className="info-label">
                                        {translate("Boat Check-out")}
                                      </span>
                                      <span className="info-value">
                                        {JSON.parse(c?.content)?.end}
                                      </span>
                                    </div>
                                    <div className="info-row">
                                      <span className="info-label">
                                        {translate("Adult/Child")}
                                      </span>
                                      <span className="info-value">
                                        {JSON.parse(c?.content)?.adult}-
                                        {JSON.parse(c?.content)?.child}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )} */}

                            {c?.type == "offer" && (
                              <div className="card offer-card-primary">
                                <div className="card-header d-flex justify-content-between align-items-center bg-light p-3">
                                  <h3 className="m-0 ">
                                    {ucwords("custom " + c?.type)}
                                  </h3>
                                  <span className="badge badge-info">
                                    {translate("Offer")}
                                  </span>
                                </div>
                                <div className="card-body">
                                  <div className="offer-description mb-3">
                                    <b>{translate("Offer Description")}:</b>
                                    <p>{JSON.parse(c?.content)?.offer}</p>
                                  </div>

                                  <div className="check-in-out mb-3">
                                    <div className="d-flex justify-content-between">
                                      <b>{translate("Check In")}</b>
                                      <span>
                                        {JSON.parse(c?.content)?.date_from}
                                      </span>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <b>{translate("Check Out")}</b>
                                      <span>
                                        {JSON.parse(c?.content)?.date_to}
                                      </span>
                                    </div>
                                  </div>

                                  <div className="offer-services">
                                    <b>{translate("Offer Services")}:</b>
                                    <div className="services-grid mt-3">
                                      {JSON.parse(c?.content)?.services.map(
                                        (item, index) => (
                                          <div
                                            className="service-item"
                                            key={index}
                                          >
                                            <div className="service-title">
                                              <b>
                                                {item["service_title"] &&
                                                  translate(
                                                    ucwords(
                                                      item["service_title"]
                                                    )
                                                  )}
                                              </b>
                                            </div>
                                            <div className="service-price">
                                              {item["service_price"] &&
                                                currency +
                                                  translate(
                                                    item["service_price"]
                                                  )}
                                            </div>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="card-footer text-right">
                                  <button
                                    type="button"
                                    disabled={
                                      JSON.parse(c?.content)?.offer_setting
                                        ?.expire &&
                                      isDateExpired(
                                        c?.created_at,
                                        JSON.parse(c?.content)?.offer_setting
                                          ?.expire
                                      )
                                    }
                                    onClick={() =>
                                      handleSetupPayment(c?.id, "boat")
                                    }
                                    className="btn btn-success"
                                  >
                                    {translate("Accept Offer")}
                                  </button>
                                </div>
                              </div>
                            )}

                            {/* {c?.type == "offer" && (
                              <div className="card">
                                <div className="card-header">
                                  <h3>
                                    <div className={classes["card-head"]}>
                                      <span>
                                        {ucwords("custom " + c?.type)}
                                      </span>
                                    </div>
                                  </h3>
                                </div>
                                <div className="card-body">
                                  <div
                                    style={{
                                      border: "1px solid grey",
                                      padding: "5px",
                                    }}
                                  >
                                    <div>
                                      <b>{translate("Offer Description")}:</b>
                                    </div>
                                    <div
                                      className={classes["styled-line"]}
                                    ></div>
                                    <div style={{ marginTop: "10px" }}>
                                      {JSON.parse(c?.content)?.offer}
                                    </div>
                                  </div>
                                  <div>
                                    <div
                                      style={{
                                        marginTop: "10px",
                                        display: "flex",
                                        justifyContent: "space-around",
                                      }}
                                    >
                                      <b>{translate("Check In")} </b>{" "}
                                      {JSON.parse(c?.content)?.date_from}
                                    </div>
                                    <div
                                      style={{
                                        marginTop: "10px",
                                        display: "flex",
                                        justifyContent: "space-around",
                                      }}
                                    >
                                      <b>{translate("Check Out")} </b>{" "}
                                      {JSON.parse(c?.content)?.date_to}
                                    </div>
                                  </div>
                                  <div>
                                    <div style={{ marginTop: "10px" }}>
                                      <b>{translate("Offer Services")}:</b>
                                    </div>
                                  </div>
                                  <div className={classes["center-bill"]}>
                                    <table
                                      className={`table table-striped ${classes["bill-width-50"]}`}
                                    >
                                      <tbody>
                                        {JSON.parse(c?.content)?.services.map(
                                          (item, index) => (
                                            <tr
                                              className={`text-center ${classes["offer-row-height"]}`}
                                            >
                                              <th>
                                                {item["service_title"] &&
                                                  translate(
                                                    ucwords(
                                                      item["service_title"]
                                                    )
                                                  )}
                                              </th>
                                              <td>
                                                {item["service_price"] &&
                                                  currency +
                                                    translate(
                                                      item["service_price"]
                                                    )}
                                              </td>
                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                      <tfoot>
                                        <tr
                                          className={`text-center ${classes["offer-row-height"]}`}
                                        >
                                          <th>{translate("Boat Rent")}</th>
                                          <th>
                                            {currency +
                                              JSON.parse(c?.content)
                                                ?.base_price}
                                          </th>
                                        </tr>
                                        <tr
                                          className={`text-center ${classes["offer-row-height"]}`}
                                        >
                                          <th>{translate("Total Price")}</th>
                                          <th>
                                            {currency +
                                              JSON.parse(c?.content)
                                                ?.total_price}
                                          </th>
                                        </tr>
                                      </tfoot>
                                    </table>
                                  </div>
                                </div>
                                <div className="card-footer d-flex justify-content-end">
                                  <button
                                    type="button"
                                    disabled={
                                      JSON.parse(c?.content)?.offer_setting
                                        ?.expire &&
                                      isDateExpired(
                                        c?.created_at,
                                        JSON.parse(c?.content)?.offer_setting
                                          ?.expire
                                      )
                                    }
                                    onClick={() =>
                                      handleSetupPayment(c?.id, "boat")
                                    }
                                    className="btn btn-secondary m-1"
                                  >
                                    {translate("Accept Offer")}
                                  </button>
                                </div>
                              </div>
                            )} */}
                          </>
                        ))}
                      </div>
                    </div>
                    {conversations && conversations.length > 0 ? (
                      <InputMessage
                        item={item}
                        type={type}
                        id={id}
                        inputState={{ inputText, setInputText }}
                        inputIframeState={{
                          inputIframe,
                          setInputIframe,
                        }}
                        sidebar={sidebar}
                        selectedUser={selectedUser}
                        setUserSentMessage={setUserSentMessage}
                        setAttachedFile={setAttachedFile}
                        chats={chats}
                        setChats={setChats}
                        classes={classes}
                      />
                    ) : (
                      <h4 className="text-center fw-bold">
                        {translate("No Chat found")}
                      </h4>
                    )}
                  </div>
                  {sidebar && (
                    <div
                      className={` overlay active`}
                      onClick={backdropHandle}
                    ></div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default UserChat;
