import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Use useNavigate instead of useHistory
import "./ForgetPassword.css";
import Toaster from "../../Components/Toaster";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import Footer from "../../Components/Footer/Footer";
import Navbar from "../../Components/Navbar/Navbar";

function ForgetPassword() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate(); // useNavigate hook

  const handleResetPassword = async () => {
    if (!email) {
      setError("Email is required.");
      return;
    }

    setLoading(true);
    setError("");
    setMessage("");

    const payload = {
      type: "email", // Make sure this is set to "email"
      value: email, // Pass the email entered by the user
    };

    console.log("Request Payload: ", payload); // Log the payload to confirm

    try {
      const response = await fetch(
        "https://hotel.caprieasy.net/api/password/email",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      const data = await response.json();
      console.log("Response: ", data); // Log the API response for debugging

      if (response.ok) {
        // Show success toast notification using your custom Toaster function
        Toaster(
          "success",
          data.message || "A verification code has been sent to your email."
        );

        // After showing the toast, navigate to the verification page
        setTimeout(() => {
          navigate("/verify-code", { state: { email } });
        }, 2000); // 2-second delay before navigating to the next page
      } else {
        // Show error toast notification using your custom Toaster function
        Toaster(
          "error",
          data.message || "Failed to send the verification code."
        );
      }
    } catch (err) {
      setError("An error occurred. Please try again later.");
      Toaster("error", "An error occurred. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Navbar />
      <div className="container p-4">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 reset-content-wrapper">
            <div className="restPassword-heading">
              <img src="/assets/icons/companyLogo.png" alt="..." />
              <div className="restPassword-topIcon">
                <img src="/assets/icons/aa1.png" alt="..." />
              </div>
              <div className="restPassword-bottomIcon">
                <img src="/assets/icons/aa2.jpeg" alt="..." />
              </div>
              <h1>Forget Password</h1>

              <div className="restPassword-email">
                <label>Email</label>
                <input
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              {error && <div className="error">{error}</div>}
              {message && <div className="message">{message}</div>}

              <div className="resetPassword-btn">
                <button onClick={handleResetPassword} disabled={loading}>
                  {loading ? "Sending..." : "Reset Password"}
                </button>
              </div>

              <div className="signIn-link">
                <p>
                  Back to <a href="/login">Sign In</a>
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6  d-none d-lg-block d-md-block">
            <div
              id="resetPasswordcarouselExampleIndicator"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-indicators-resetPassword">
                <button
                  type="button"
                  data-bs-target="#resetPasswordcarouselExampleIndicator"
                  data-bs-slide-to="0"
                  className="active"
                  aria-current="true"
                  aria-label="Slide 1"
                ></button>
                <button
                  type="button"
                  data-bs-target="#resetPasswordcarouselExampleIndicator"
                  data-bs-slide-to="1"
                  aria-label="Slide 2"
                ></button>
                <button
                  type="button"
                  data-bs-target="#resetPasswordcarouselExampleIndicator"
                  data-bs-slide-to="2"
                  aria-label="Slide 3"
                ></button>
              </div>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src="/assets/icons/resetPasswordImg.png"
                    className="d-block w-100"
                    alt="..."
                  />

                  <div className="carousel-caption">
                    <h2>
                      “We've been using CAPRIEASY to explore every new location,
                      and I can't imagine having a good time traveling without
                      it.”
                    </h2>
                    <h3>Brooklyn Simmons</h3>
                    <p>Happy Client</p>
                  </div>
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/icons/loginPageImg.png"
                    className="d-block w-100"
                    alt="..."
                  />
                  <div className="carousel-caption">
                    <h2>
                      “We’ve been using CAPRIEASY to explore every new place and
                      can’t imagine enjoying my travel without it.”
                    </h2>
                    <h3>Olivia Rhye</h3>
                    <p>Happy Client</p>
                  </div>
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/icons/signupPageImg.png"
                    className="d-block w-100"
                    alt="..."
                  />
                  <div className="carousel-caption">
                    <h2>
                      “We have been utilizing CAPRIEASY to explore each new
                      location, and I can't fathom traveling without it.”
                    </h2>
                    <h3>Wade Warren</h3>
                    <p>Happy Client</p>
                  </div>
                </div>
              </div>
              <div>
                <button
                  className="resetPassword-carousel-control-prev"
                  type="button"
                  data-bs-target="#resetPasswordcarouselExampleIndicator"
                  data-bs-slide="prev"
                >
                  <span
                    className="resetPassword-carousel-control-prev-icon"
                    aria-hidden="true"
                  >
                    <BsArrowLeft />
                  </span>
                </button>
                <button
                  className="resetPassword-carousel-control-next"
                  type="button"
                  data-bs-target="#resetPasswordcarouselExampleIndicator"
                  data-bs-slide="next"
                >
                  <span
                    className="resetPassword-carousel-control-next-icon"
                    aria-hidden="true"
                  >
                    <BsArrowRight />
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ForgetPassword;
