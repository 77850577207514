import { configureStore } from "@reduxjs/toolkit";
import userSlice from "../UserDashboardComponent/UserProfile/userSlice";
import landingSlice from "../Pages/landing page/landingSlice";
import blogSlice from "../Pages/blogs/blogSlice";
import boatSlice from "../Pages/boat/boatSlice";

export const store = configureStore({
  reducer: {
    user: userSlice,
    landing: landingSlice,
    blogs: blogSlice,
    boat: boatSlice,
  },
});
