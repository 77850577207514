import axios from "axios";
import Toaster from "../Components/Toaster";
import { title } from "./helpers";

// const SERVER_URL = "http://127.0.0.1:8000/api/";
// const SERVER_URL = "http://hotel.azbasolution.pk/api/";
// const SERVER_URL = "https://control-center.caprieasy.net/api/";
// const SERVER_URL = "https://api.caprieasy.net/api/";
const SERVER_URL = "https://hotel.caprieasy.net/api/";


const makeHttpRequest = async (method, end_point, data = null) => {
  try {
    let bearerToken = localStorage.getItem("bearerToken");
    let url = SERVER_URL + end_point;
    const config = {
      method,
      url,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    if (bearerToken) {
      config.headers["Authorization"] = `Bearer ${bearerToken}`;
    }

    // console.log("this is config obj ", config);
    if (method === "POST" && data) {
      config.data = data;
    }

    const response = await axios(config);
    // console.log("this is api response ", response.data);

    // if(response?.data?.success === true){
    //   document.title = title();
    // }

    if (response?.data?.message != null && response?.data?.success === true) {
      Toaster("success", response.data.message);
    }

    if (response?.data?.success === false) {
      document.title = title();
      if (response?.data?.message) {
        Toaster("error", response?.data?.message);
      } else {
        Toaster("error", response?.data?.data[0]);
      }
    }
    // else {
    //   response.data.data.map(error,i){
    //   Toaster("error", response.data.data);
    //   }
    // }

    // if (response.data.success === true) {
    //   localStorage.setItem("bearerToken", response.data.data.token);
    // }
    // If the token is present in the response headers, store it in localStorage
    const newToken = response.headers["authorization"];
    if (newToken && newToken.startsWith("Bearer ")) {
      const extractedToken = newToken.substring("Bearer ".length);
      localStorage.setItem("bearerToken", extractedToken);
    }

    return response.data;
  } catch (error) {
    // console.log(error);
    if (error?.response?.status === 401) {
      localStorage.removeItem("bearerToken");
      window.location.reload(window.location.host);
      Toaster("error", "Session Expired. Please Login Again");
      return 401;
    }
    Toaster("error", error?.response?.data?.message);
    throw error;
  }
};

export default makeHttpRequest;
