import React from 'react';
import "./ErrorPage404.css";
import { useLanguage } from "../../utils/LanguageContext";


function ErrorPage404() {
  const { langData, fetchLangData, translate } = useLanguage();

  return (
    <div className=''>
        <div className='Error404Img-wrapper'>
        <img src="/assets/icons/404ErrorImg.png" alt="..." />
        </div>
      
    </div>
  )
}

export default ErrorPage404;
