import React, { useCallback, useEffect, useState } from "react";
import LocationCarousal from "../../Components/LocationCarousal/LocationCarousal";
import LeftHeading from "../../Components/Heading/LeftHeading";
import { useLanguage } from "../../utils/LanguageContext";
import { useSelector, useDispatch } from "react-redux";
import makeHttpRequest from "../../utils/api";
import { addData } from "../landing page/landingSlice";
import { Button } from "@mui/material";
import { BsArrowRightShort } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";

function HomeCarousel() {
  const { translate } = useLanguage();
  const { data } = useSelector((state) => state.landing);
  const navigate = useNavigate();

  const [selected_type_location, setSelectedTypeLocation] =
    useState("property");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await makeHttpRequest("GET", "home");
      dispatch(addData(response.data));
      document.title = "Home Page";
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    if (!data) {
      fetchData();
    }
  }, [data, fetchData]);

  const handleLocationFn = async (id) => {
    try {
      if (selected_type_location === "property") {
        const res = await makeHttpRequest(
          "GET",
          `search?type=${selected_type_location}&location=${id}`
        );
        navigate("/search", { state: res });
      } else {
        const res = await makeHttpRequest("GET", `boat?location=${id}`);
        navigate("/boat", { state: res });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <>
      <div className="carousel-container">
        <div className="row  mb-5">
          <div className="col-lg-6 col-md-12 col-12 ">
            <LeftHeading
              h1={translate(data?.location?.heading)}
              btn_label={translate(data?.location?.button)}
              img1="not show image"
              img2="not show image"
            />
            <div className="container">
              <p className="ml-3 ml-lg-5"> {translate(data?.property?.sub_heading)}</p>
            </div>

            <div className="home-carousel-button">
              <Link to="">
                <Button variant="contained" onClick={() => handleLocationFn()}>
                  {translate("Discover More")}
                  <BsArrowRightShort
                    style={{ fontSize: "15px", marginLeft: "10px" }}
                  />
                </Button>
              </Link>
            </div>
          </div>

          <div className="col-lg-6 col-md-12 col-12 mt-5">
            <LocationCarousal />
          </div>
        </div>
      </div>
    </>
  );
}

export default HomeCarousel;
