import React, { useState } from "react";
import classes from "./ChangePassword.module.css";
import { Link } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import UserSideNavbar from "../UserSideNavbar/UserSideNavbar";
import Footer from "../../Components/Footer/Footer";
import makeHttpRequest from "../../utils/api";
import { useLanguage } from "../../utils/LanguageContext";

function ChangePassword() {
  const [current_password, setCurrentPassword] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirmation, setPasswordConfirmation] = useState("");
  const [loading, setLoading] = useState(false);
  const { langData, fetchLangData, translate } = useLanguage();

  const storeFeedback = async () => {
    try {
      setLoading(true);
      let obj = {
        current_password: current_password,
        password: password,
        password_confirmation: password_confirmation,
      };
      const res = await makeHttpRequest("POST", "user/change-password", obj);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <div>
      <div className="container mt-4">
        <div>
          {/* <div className="table-heading">
              <h1>{translate('Change Password')}</h1>
            </div> */}
          <div className={classes["changePassword-bg"]}>
            <div className="row">
              <div
                className={`col-lg-12 col-md-12 col-12 mt-3 ${classes["changePassword-name-inputs"]}`}
              >
                <label className={classes["changePassword-inputLabel"]}>
                  {translate("Current Password")}
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={translate("Enter current password")}
                  value={current_password}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  required
                />
              </div>
              <div
                className={`col-lg-12 col-md-12 col-12 mt-5 ${classes["changePassword-name-inputs"]}`}
              >
                <label className={classes["changePassword-inputLabel"]}>
                  {translate("New Password")}
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={translate("Enter new password")}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <div
                className={`col-lg-12 col-md-12 col-12 mt-5 ${classes["changePassword-name-inputs"]}`}
              >
                <label className={classes["changePassword-inputLabel"]}>
                  {translate("Confirm Password")}
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={translate("Confirm your password")}
                  value={password_confirmation}
                  onChange={(e) => setPasswordConfirmation(e.target.value)}
                  required
                />
              </div>

              <div className="col-lg-12 col-md-12 col-12">
                <div className={classes["changePassword-submitBtn"]}>
                  <button
                    onClick={storeFeedback}
                    disabled={loading}
                    style={{ color: "#fff" }}
                  >
                    {loading
                      ? translate("Changing Password...")
                      : translate("Change Password")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangePassword;
