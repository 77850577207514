import React, { useEffect, useState } from "react";
import makeHttpRequest from "../../utils/api";
import Toaster from "../../Components/Toaster";
import { Link, useNavigate } from "react-router-dom";
import "./AllBoat.css";
import { showAmount } from "../../utils/showAmount";
import { useLanguage } from "../../utils/LanguageContext";
import { addLeadingZero } from "../../utils/addLeadingZero";
import Loader from "../../Components/Loader";
import CreateNewBoat from "../CreateNewBoat/CreateNewBoat";
import { TiEdit } from "react-icons/ti";
import { MdDelete } from "react-icons/md";

function AllBoat() {
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);
  const { translate } = useLanguage();
  const [activeTab, setActiveTab] = useState("all-boat");
  const navigate = useNavigate();

  useEffect(() => {
    getData();
  }, []);

  const refreshData = () => {
    getData(); // Re-fetch the boat data
  };

  const getData = async () => {
    try {
      setLoading(true);
      const res = await makeHttpRequest("GET", "owner/boats");
      setData(res.data);
      setLoading(false);
      if (res.success) {
        setData(res.data);
      } else {
        Toaster("error", "Error");
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const deleteThisItem = async (id) => {
    try {
      setLoading(true);
      const res = await makeHttpRequest("GET", "owner/boat/destroy/" + id);
      getData();
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleEditClick = (id) => {
    navigate(`/boat/${id}/edit`);
  };

  // Function to render different content based on the active tab
  const renderTabContent = () => {
    switch (activeTab) {
      case "all-boat":
        return (
          <>
            <div className="table-responsive">
              <div className="table-wrapper">
                <table
                  id="mytable"
                  className="table table-bordred table-striped"
                >
                  <thead className="allBoat-table-th">
                    <th>{translate("S.N.")}</th>
                    <th>{translate("Name")}</th>
                    <th>{translate("Boat Type")}</th>
                    <th>{translate("Location")}</th>
                    <th>{translate("Price")}</th>
                    <th>{translate("Discount")}</th>
                    <th>{translate("Status")}</th>
                    <th>{translate("Action")}</th>
                  </thead>
                  <tbody>
                    {data?.boats?.data?.map((row, index) => (
                      <tr key={index} className="allBoat-td-wrapper">
                        <td style={{ paddingTop: "18px" }}>
                          <div className="sr-no-bg">{index + 1}</div>
                        </td>
                        <td style={{ paddingTop: "20px" }}>{row.name}</td>
                        <td style={{ paddingTop: "20px" }}>
                          {row?.boat_type?.name}
                        </td>
                        <td style={{ paddingTop: "20px" }}>
                          {row?.location?.name}
                        </td>
                        <td style={{ paddingTop: "20px" }}>
                          {row?.price} €
                        </td>
                        <td style={{ paddingTop: "20px" }}>
                          {showAmount(row.discount)}
                          {translate("%")}
                        </td>
                        <td
                          style={{ paddingTop: "20px" }}
                          className="allBoat-status"
                        >
                          {row.status === 1 ? (
                            <span className="badge badge-success">
                              {translate("Active")}
                            </span>
                          ) : (
                            <span className="badge badge-warning">
                              {translate("Inactive")}
                            </span>
                          )}
                        </td>
                        <td className="p-2">
                          <div className="d-flex justify-content-center gap-3 align-items-center">
                            <div>
                              {" "}
                              <div
                                title="edit"
                                className="all-baot-edit-icon"
                                onClick={() => handleEditClick(row?.id)}
                              >
                                <span>
                                  <TiEdit />
                                </span>
                                {/* {translate(" Edit")} */}
                              </div>
                            </div>
                            <div
                              title="delete"
                              className="all-baot-delete-icon"
                              onClick={() => deleteThisItem(row?.id)}
                              style={{ cursor: "pointer" }}
                            >
                              <span>
                                <MdDelete />
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            {data?.booked_properties?.data?.length == 0 && (
              <div className="successProperty-Img">
                <img src="/assets/icons/pendingProperty-img.png" alt="..." />
              </div>
            )}
          </>
        );

      case "add-boat":
        return (
          <div>
            <CreateNewBoat
              setActiveTab={setActiveTab}
              refreshData={refreshData}
            />
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <div className="mainContainer">
          <div className=" mb-4 mt-5">
            <div className="row">
              <div className="col-lg-10 col-md-10 col-sm-12">
                <div className="allProperty-heading pt-0">
                  {activeTab === "add-boat" && (
                    <div className="tab-container d-flex gap-3 mt-5 pt-4">
                      <div
                        className={`tab  text-white fw-bold    ${
                          activeTab === "all-boat" ? "active" : ""
                        }`}
                        onClick={() => setActiveTab("all-boat")}
                      ></div>
                    </div>
                  )}
                  {activeTab === "all-boat" && (
                    <div className="tab-container">
                      <h1>{translate("All Boat")}</h1>
                      <p>
                        {translate("Total Boats")}{" "}
                        <span>{addLeadingZero(data?.boats?.total)}</span>
                      </p>

                      <div
                        className={`tab  text-white fw-bold  ${
                          activeTab === "add-boat" ? "active" : ""
                        }`}
                        onClick={() => setActiveTab("add-boat")}
                      ></div>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-12">
                <div className="">
                  {activeTab === "add-boat" && (
                    <div className="tab-container d-flex gap-3">
                      <div
                        className={`tab addProperty-addBtn text-white fw-bold    ${
                          activeTab === "all-boat" ? "active" : ""
                        }`}
                        onClick={() => setActiveTab("all-boat")}
                      >
                        {translate("All Boats")}
                      </div>
                    </div>
                  )}
                  {activeTab === "all-boat" && (
                    <div className="tab-container d-flex gap-3">
                      <div
                        className={`tab addProperty-addBtn text-white fw-bold  ${
                          activeTab === "add-boat" ? "active" : ""
                        }`}
                        onClick={() => setActiveTab("add-boat")}
                      >
                        {translate("+ Add New Boats")}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {renderTabContent()}
        </div>
      )}
    </>
  );
}

export default AllBoat;
