import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import "./NewPassword.css"; // Import the CSS file for styling
import Toaster from "../../Components/Toaster";

function NewPassword() {
  const location = useLocation();
  const navigate = useNavigate();
  const { email, code, password, password_confirmation } = location.state || {}; // Get the email passed from VerifyCode

  const [newPassword, setNewPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  const handleResetPassword = async () => {
    if (!newPassword) {
      setError("New password is required.");
      return;
    }

    setLoading(true);
    setError("");
    setMessage("");

    try {
      const response = await fetch(
        `https://hotel.caprieasy.net/api/password/reset`,
        {
          method: "POST", // Use POST for resetting the password
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token: code,
            email,
            password: newPassword, // Use the new password
            password_confirmation: passwordConfirmation, // Use the confirmation
          }), // Only send necessary data
        }
      );

      const data = await response.json();

      if (response.ok && data.success) {
        Toaster("success", data.message || "Password reset successfully.");
        setTimeout(() => navigate("/login"), 3000);
      } else {
        Toaster("error", data.message || "Failed to reset the password.");
        console.error("Error details:", data); // Log the error for debugging
      }
    } catch (err) {
      Toaster("error", "An error occurred. Please try again later.");
      console.error("Network or other error:", err); // Log any network or other errors
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Navbar />
      <div className="new-password-container">
        <div className="new-password-form">
          <h1 className="new-password-heading">Set New Password</h1>

          <div className="new-password-input">
            <label className="new-password-label">New Password</label>
            <input
              type="password"
              className="new-password-field"
              placeholder="Enter new password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>
          <div className="new-password-input">
            <label className="new-password-label">New Password</label>
            <input
              type="password"
              className="new-password-field"
              placeholder="Enter new password"
              value={passwordConfirmation}
              onChange={(e) => setPasswordConfirmation(e.target.value)}
            />
          </div>

          {error && <div className="error-message">{error}</div>}
          {message && <div className="success-message">{message}</div>}

          <button
            className="new-password-btn"
            onClick={handleResetPassword}
            disabled={loading}
          >
            {loading ? "Setting..." : "Set Password"}
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default NewPassword;
