import React from 'react'

function About() {
  return (
    <div>
      About page
    </div>
  )
}

export default About
