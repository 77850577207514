import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom"; // useNavigate for navigation, useLocation for accessing passed state
import "./VerifyCode.css"; // Import CSS file for styling
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";

function VerifyCode() {
  const location = useLocation();
  const navigate = useNavigate();
  const { email } = location.state || {}; // Get the email passed from ForgetPassword

  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  const handleVerifyCode = async () => {
    if (!code) {
      setError("Code is required.");
      return;
    }

    setLoading(true);
    setError("");
    setMessage("");

    try {
      const response = await fetch(
        "https://hotel.caprieasy.net/api/password/verify-code",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, code }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        setMessage("Code verified successfully.");
        // Navigate to the NewPassword page
        navigate("/new-password", { state: { email, code } });
      } else {
        setError(data.message || "Failed to verify the code.");
      }
    } catch (err) {
      setError("An error occurred. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Navbar />
      <div className="container">
        <div className="verify-container">
          <div className="verify-form">
            <h1 className="verify-form-heading">Enter Verification Code</h1>

            <div className="verify-email-input">
              <label className="verify-email-label">Email</label>
              <input
                type="email"
                className="verify-email-field"
                value={email}
                readOnly
              />
            </div>

            <div className="verify-code-input">
              <label className="verify-code-label">Code</label>
              <input
                type="text"
                className="verify-code-field"
                placeholder="Enter the code"
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            </div>

            {error && <div className="error-message">{error}</div>}
            {message && <div className="success-message">{message}</div>}

            <button
              className="verify-btn"
              onClick={handleVerifyCode}
              disabled={loading}
            >
              {loading ? "Verifying..." : "Verify Code"}
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default VerifyCode;
