import React, { useEffect, useState } from "react";
import makeHttpRequest from "../../utils/api";
import Toaster from "../../Components/Toaster";
import "./RoomCategory.css";
import { Link, useNavigate } from "react-router-dom";
import { useLanguage } from "../../utils/LanguageContext";
import DashboardSideNav from "../DashboardSideNav/DashboardSideNav";
import Loader from "../../Components/Loader";
import CreateRoomCategory from "./CreateRoomCategory";
import { TiEdit } from "react-icons/ti";
import { MdDelete } from "react-icons/md";

function RoomCategory() {
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);
  const { langData, fetchLangData, translate } = useLanguage();
  const [activeTab, setActiveTab] = useState("room-category");
  const navigate = useNavigate();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      setLoading(true);
      const res = await makeHttpRequest("GET", "owner/room-categories");
      setData(res.data);
      setLoading(false);
      if (res.success) {
        setData(res.data);
      } else {
        Toaster("error", "Error");
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const deleteThisItem = async (id) => {
    try {
      setLoading(true);
      const res = await makeHttpRequest(
        "GET",
        "owner/room-categories/destroy/" + id
      );
      getData();
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleEditClick = (id) => {
    navigate(`/edit-room-category/${id}`);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "room-category":
        return (
          <div className="mainContainer">
            <div className="table-responsive">
              <div className="table-wrapper">
                <table
                  id="mytable"
                  className="table table-bordred table-striped"
                >
                  <thead className="roomCategory-table-th">
                    <th>{translate("S.N.")}</th>
                    <th>{translate("Name")}</th>
                    <th>{translate("Accomodation")}</th>
                    <th>{translate("Rooms")}</th>
                    <th>{translate("Action")}</th>
                  </thead>
                  <tbody>
                    {data?.room_categories?.data?.map((row, index) => (
                      <tr key={index} className="roomCategory-td-wrapper">
                        <td>
                          <div className="sr-no-bg">0{index + 1}</div>
                        </td>
                        <td>{row.name}</td>
                        <td>{row.property.name}</td>
                        <td>{row.rooms.length}</td>
                        <td className="roomCategory-viewWrapper">
                          <div className="d-flex justify-content-center gap-3 align-items-center">
                            <div>
                              {" "}
                              <div
                                title="edit"
                                className="all-baot-edit-icon"
                                onClick={() => handleEditClick(row?.id)}
                              >
                                <span>
                                  <TiEdit />
                                </span>
                                {/* {translate(" Edit")} */}
                              </div>
                            </div>
                            {/* <div
                              title="delete"
                              className="all-baot-delete-icon"
                              onClick={() => deleteThisItem(row?.id)}
                              style={{ cursor: "pointer" }}
                            >
                              <span>
                                <MdDelete />
                              </span>
                            </div> */}
                          </div>
                          {/* <Link
                            to={`/edit-room-category/${row.id}`}
                            className="roomCategory-view-bg"
                          >
                            {translate("Edit")}
                          </Link> */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            {data?.room_categories?.data?.length == 0 && (
              <div className="successProperty-Img">
                <img src="/assets/icons/pendingProperty-img.png" alt="..." />
              </div>
            )}
          </div>
        );

      case "create-room-category":
        return (
          <div>
            <CreateRoomCategory setActiveTab={setActiveTab} />
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <div className="mainContainer">
          <div className="allProperty-headingWrappers">
            <div className="allProperty-heading">
              {activeTab === "room-category" && (
                <div className="tab-container d-flex gap-3 ">
                  <div className="">
                    <h1>{translate("Room Categories")}</h1>
                    <p>
                      {translate("Total category")}{" "}
                      <span>{data?.room_categories?.total}</span>
                    </p>
                  </div>
                  <div
                    className={`tab  text-white fw-bold    ${
                      activeTab === "create-room-category" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("create-room-category")}
                  ></div>
                </div>
              )}
              {activeTab === "create-room-category" && (
                <div className="tab-container d-flex gap-3">
                  <div
                    className={`tab  text-white fw-bold mt-0  ${
                      activeTab === "room-category" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("room-category")}
                  >
                    <div className="roomCategory-heading pt-0">
                      <h1>{translate("Room Categories")}</h1>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="">
              {activeTab === "room-category" && (
                <div className="tab-container d-flex gap-3">
                  <div
                    className={`tab addProperty-addBtn text-white fw-bold    ${
                      activeTab === "create-room-category" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("create-room-category")}
                  >
                    {translate(" + Add New Category")}
                  </div>
                </div>
              )}
              {activeTab === "create-room-category" && (
                <div className="tab-container d-flex gap-3">
                  <div
                    className={`tab addProperty-addBtn text-white fw-bold mt-4  ${
                      activeTab === "room-category" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("room-category")}
                  >
                    {translate("Room Categories")}
                  </div>
                </div>
              )}
            </div>
          </div>
          {renderTabContent()}
        </div>
        // <div className="mainContainer">
        //   <div className="roomCategory-headingWrapper">
        //     <div className="roomCategory-heading">
        //       <h1>{translate("Room Categories")}</h1>
        //       <p>
        //         {translate("Total category")}{" "}
        //         <span>{data?.room_categories?.total}</span>
        //       </p>
        //     </div>
        //     <div className="">
        //       <button className="roomCategory-addBtn">
        //         <Link to="/create-room-category">
        //           + {translate("Add New Category")}
        //         </Link>
        //       </button>
        //     </div>
        //   </div>
        //   <div className="table-responsive">
        //     <div className="table-wrapper">
        //       <table id="mytable" className="table table-bordred table-striped">
        //         <thead className="roomCategory-table-th">
        //           <th>{translate("S.N.")}</th>
        //           <th>{translate("Name")}</th>
        //           <th>{translate("Accomodation")}</th>
        //           <th>{translate("Rooms")}</th>
        //           <th>{translate("Action")}</th>
        //         </thead>
        //         <tbody>
        //           {data?.room_categories?.data?.map((row, index) => (
        //             <tr key={index} className="roomCategory-td-wrapper">
        //               <td style={{ paddingTop: "18px" }}>
        //                 <div className="sr-no-bg">0{index + 1}</div>
        //               </td>
        //               <td>{row.name}</td>
        //               <td>{row.property.name}</td>
        //               <td>{row.rooms.length}</td>
        //               <td className="roomCategory-viewWrapper">
        //                 <Link
        //                   to={`/edit-room-category/${row.id}`}
        //                   className="roomCategory-view-bg"
        //                 >
        //                   {translate("Edit")}
        //                 </Link>
        //               </td>
        //             </tr>
        //           ))}
        //         </tbody>
        //       </table>
        //     </div>
        //   </div>
        // </div>
      )}
    </>
  );
}

export default RoomCategory;
