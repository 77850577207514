import React, { useEffect, useState } from "react";
import Loader from "../../Components/Loader";
import makeHttpRequest from "../../utils/api";
import Switch from "@mui/material/Switch";
import Toaster from "../../Components/Toaster";

function CustomInquires() {
  const [loading, setLoading] = useState(false);
  const [boatInquiry, setBoatInquiry] = useState(true);
  const [boatCustomInquiry, setBoatCustomInquiry] = useState(false);
  const [propertyInquiry, setPropertyInquiry] = useState(false);
  const [propertyCustomInquiry, setPropertyCustomInquiry] = useState(false);
  const [toasterMessage, setToasterMessage] = useState("");
  const [showToaster, setShowToaster] = useState(false);

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const handleSwitchChange = async (name, value) => {
    try {
      setLoading(true);
      const response = await makeHttpRequest("POST", "owner/booking-method", {
        name,
        value,
      });
      console.log(` ${name} response:  , response`); // Log response from API
      setLoading(false); // Hide loader after request is done
    } catch (error) {
      console.error(`${name} error:  , error`);
      setLoading(false); // Hide loader in case of error
    }
  };
  // Effect to send the default inquiry state to the server
  useEffect(() => {
    handleSwitchChange("boat_inquiry", boatInquiry ? 1 : 0);
  }, []); // Empty dependency array ensures this runs only on mount

  return (
    <>
      {loading && <Loader />}
      {showToaster && <Toaster message={toasterMessage} />}
      {!loading && (
        <div className="container custom-inquiry-wrapper mt-5">
          <div className="inquiry-item">
            <h3>Boat Inquiry</h3>
            <Switch
              {...label}
              checked={boatInquiry}
              onChange={(e) => {
                setBoatInquiry(e.target.checked);
                handleSwitchChange("boat_inquiry", e.target.checked ? 1 : 0);
              }}
            />
          </div>
          <div className="inquiry-item">
            <h3>Boat Custom Inquiry</h3>
            <Switch
              {...label}
              checked={boatCustomInquiry}
              onChange={(e) => {
                setBoatCustomInquiry(e.target.checked);
                handleSwitchChange(
                  "boat_custom_inquiry",
                  e.target.checked ? 1 : 0
                );
              }}
            />
          </div>
          <div className="inquiry-item">
            <h3>Property Inquiry</h3>
            <Switch
              {...label}
              checked={propertyInquiry}
              onChange={(e) => {
                setPropertyInquiry(e.target.checked);
                handleSwitchChange(
                  "property_inquiry",
                  e.target.checked ? 1 : 0
                );
              }}
            />
          </div>
          <div className="inquiry-item">
            <h3>Property Custom Inquiry</h3>
            <Switch
              {...label}
              checked={propertyCustomInquiry}
              onChange={(e) => {
                setPropertyCustomInquiry(e.target.checked);
                handleSwitchChange(
                  "property_custom_inquiry",
                  e.target.checked ? 1 : 0
                );
              }}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default CustomInquires;
