import React, { useState, useEffect } from "react";
import CenterHeading from "../../Components/Heading/CenterHeading";
import { useLanguage } from "../../utils/LanguageContext";
import { useSelector } from "react-redux";
import CardMedia from "@mui/material/CardMedia";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { limitCharacters } from "../../utils/helpers";
import { addLeadingZero } from "../../utils/addLeadingZero";
import { BsArrowRightShort } from "react-icons/bs";
import Skeleton from "@mui/material/Skeleton"; // Import Skeleton from Material-UI

function PropertyType() {
  const { translate } = useLanguage();
  const { data } = useSelector((state) => state.landing);

  let firstEle = data?.propertyTypes ? data?.propertyTypes[0] : "";
  let secondEle = data?.propertyTypes ? data?.propertyTypes[1] : "";
  let thirdEle = data?.propertyTypes ? data?.propertyTypes[2] : "";
  let fourthEle = data?.propertyTypes ? data?.propertyTypes[3] : "";
  let fifthEle = data?.propertyTypes ? data?.propertyTypes[4] : "";

  // State to track loading status of images
  const [loading, setLoading] = useState({
    first: true,
    second: true,
    third: true,
    fourth: true,
    fifth: true,
  });

  useEffect(() => {
    // Set a timeout for each image to simulate loading
    const timeouts = {
      first: setTimeout(
        () => setLoading((prev) => ({ ...prev, first: false })),
        3000
      ),
      second: setTimeout(
        () => setLoading((prev) => ({ ...prev, second: false })),
        3000
      ),
      third: setTimeout(
        () => setLoading((prev) => ({ ...prev, third: false })),
        3000
      ),
      fourth: setTimeout(
        () => setLoading((prev) => ({ ...prev, fourth: false })),
        3000
      ),
      fifth: setTimeout(
        () => setLoading((prev) => ({ ...prev, fifth: false })),
        3000
      ),
    };

    // Cleanup function to clear timeouts
    return () => {
      Object.values(timeouts).forEach(clearTimeout);
    };
  }, []);

  const handleImageLoad = (key) => {
    setLoading((prev) => ({ ...prev, [key]: false }));
  };

  return (
    <div className="container">
      <CenterHeading
        h1={translate(data?.property?.heading)}
        p={translate(data?.property?.sub_heading)}
      />

      <div>
        {data?.propertyTypes?.length > 0 && (
          <div className="row mb-3 class1">
            <div className="col-lg-6 col-md-12 col-12 mt-2">
              {firstEle && (
                <Card
                  className="m-1"
                  sx={{ boxShadow: "none", borderRadius: "16px" }}
                >
                  <Link
                    to={`/accomodation/search-location/${firstEle.id}/${firstEle.slug}`}
                    className="link-wrapper"
                  >
                    {loading.first ? (
                      <Skeleton variant="rectangular" height={432} />
                    ) : (
                      <CardMedia
                        component="img"
                        alt="Property"
                        height="432px"
                        image={firstEle.image}
                        onLoad={() => handleImageLoad("first")}
                      />
                    )}

                    <CardContent className="card-content-wrapper">
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        className="proprty-card-footer"
                        title={firstEle.name}
                      >
                        {translate(limitCharacters(firstEle.name, 15))} (
                        {addLeadingZero(firstEle.total)})
                      </Typography>
                      <BsArrowRightShort className="boat-sec-more-icon" />
                    </CardContent>
                  </Link>
                </Card>
              )}
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-12 mt-2">
                  {secondEle && (
                    <Card
                      className="m-1"
                      sx={{ boxShadow: "none", borderRadius: "16px" }}
                    >
                      <Link
                        to={`/accomodation/search-location/${secondEle.id}/${secondEle.slug}`}
                        className="link-wrapper"
                      >
                        {loading.second ? (
                          <Skeleton variant="rectangular" height={180} />
                        ) : (
                          <CardMedia
                            component="img"
                            alt="Property"
                            height="180px"
                            image={
                              secondEle.image !== ""
                                ? secondEle.image
                                : "/assets/icons/houseImg.png"
                            }
                            onLoad={() => handleImageLoad("second")}
                          />
                        )}
                        <CardContent className="card-content-wrapper">
                          <Typography
                            gutterBottom
                            variant="h5"
                            component="div"
                            className="proprty-card-footer"
                            title={secondEle.name}
                          >
                            {translate(limitCharacters(secondEle.name, 15))} (
                            {addLeadingZero(secondEle.total)})
                          </Typography>
                          <BsArrowRightShort className="boat-sec-more-icon" />
                        </CardContent>
                      </Link>
                    </Card>
                  )}
                </div>
                <div className="col-lg-6 col-md-6 col-12 mt-2">
                  {fourthEle && (
                    <Card
                      className="m-1"
                      sx={{ boxShadow: "none", borderRadius: "16px" }}
                    >
                      <Link
                        to={`/accomodation/search-location/${fourthEle.id}/${fourthEle.slug} `}
                        className="link-wrapper"
                      >
                        {loading.fourth ? (
                          <Skeleton variant="rectangular" height={180} />
                        ) : (
                          <CardMedia
                            component="img"
                            alt="Property"
                            height="180px"
                            image={
                              fourthEle.image !== ""
                                ? fourthEle.image
                                : "/assets/icons/motelImg.png"
                            }
                            onLoad={() => handleImageLoad("fourth")}
                          />
                        )}

                        <CardContent className="card-content-wrapper">
                          <Typography
                            gutterBottom
                            variant="h5"
                            component="div"
                            className="proprty-card-footer"
                            title={fourthEle.name}
                          >
                            {translate(limitCharacters(fourthEle.name))} (
                            {addLeadingZero(fourthEle.total)})
                          </Typography>
                          <BsArrowRightShort className="boat-sec-more-icon" />
                        </CardContent>
                      </Link>
                    </Card>
                  )}
                </div>
                <div className="col-lg-6 col-md-6 col-12 mt-2">
                  {thirdEle && (
                    <Card
                      className="m-1"
                      sx={{ boxShadow: "none", borderRadius: "16px" }}
                    >
                      <Link
                        to={`/accomodation/search-location/${thirdEle.id}/${thirdEle.slug}`}
                        className="link-wrapper"
                      >
                        {loading.third ? (
                          <Skeleton variant="rectangular" height={180} />
                        ) : (
                          <CardMedia
                            component="img"
                            alt="Property"
                            height="180px"
                            image={
                              thirdEle.image !== ""
                                ? thirdEle.image
                                : "/assets/icons/villaImg.png"
                            }
                            onLoad={() => handleImageLoad("third")}
                          />
                        )}
                        <CardContent className="card-content-wrapper">
                          <Typography
                            gutterBottom
                            variant="h5"
                            component="div"
                            className="proprty-card-footer"
                            title={thirdEle.name}
                          >
                            {translate(limitCharacters(thirdEle.name, 15))} (
                            {addLeadingZero(thirdEle.total)})
                          </Typography>
                          <BsArrowRightShort className="boat-sec-more-icon" />
                        </CardContent>
                      </Link>
                    </Card>
                  )}
                </div>
                <div className="col-lg-6 col-md-6 col-12 mt-2">
                  {fifthEle && (
                    <Card
                      className="m-1"
                      sx={{ boxShadow: "none", borderRadius: "16px" }}
                    >
                      <Link
                        to={`/accomodation/search-location/${fifthEle.id}/${fifthEle.slug}`}
                        className="link-wrapper"
                      >
                        {loading.fifth ? (
                          <Skeleton variant="rectangular" height={180} />
                        ) : (
                          <CardMedia
                            component="img"
                            alt="Property"
                            height="180px"
                            image={
                              fifthEle.image !== ""
                                ? fifthEle.image
                                : "/assets/icons/motelImg.png"
                            }
                            onLoad={() => handleImageLoad("fifth")}
                          />
                        )}

                        <CardContent className="card-content-wrapper">
                          <Typography
                            gutterBottom
                            variant="h5"
                            component="div"
                            className="proprty-card-footer"
                            title={fifthEle.name}
                          >
                            {translate(limitCharacters(fifthEle.name))} (
                            {addLeadingZero(fifthEle.total)})
                          </Typography>
                          <BsArrowRightShort className="boat-sec-more-icon" />
                        </CardContent>
                      </Link>
                    </Card>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default PropertyType;
