import React, { useEffect, useState } from "react";
import "./UpdateProperty.css";
import { Link, useParams, useNavigate } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";
import makeHttpRequest from "../../utils/api";
import { CircularProgress, Stack } from "@mui/material";
import ImageUploader from "../../Components/ImageUploader/ImageUploader";
import Toaster from "../../Components/Toaster";
import { BiCheck } from "react-icons/bi";
import { useLanguage } from "../../utils/LanguageContext";

function UpdateProperty() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [image, setImage] = useState(null);
  const [image1, setImage1] = useState(null);
  const [image2, setImage2] = useState(null);
  const [image3, setImage3] = useState(null);
  const [image4, setImage4] = useState(null);
  const [ori_image, setOriImage] = useState(null);
  const [ori_image1, setOriImage1] = useState(null);
  const [ori_image2, setOriImage2] = useState(null);
  const [ori_image3, setOriImage3] = useState(null);
  const [ori_image4, setOriImage4] = useState(null);
  const [feature, setFeature] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState("");
  const { langData, fetchLangData, translate } = useLanguage();

  const [state, setState] = useState({});
  useEffect(() => {
    if (id) {
      getData();
    }
  }, []);

  const getData = async () => {
    try {
      setLoading(true);
      const res = await makeHttpRequest("GET", `owner/property/edit/${id}`);
      setLoading(false);
      if (res.success) {
        setData(res?.data);
        let initialState = {
          name: res?.data?.property?.name,
          property_type: res?.data?.property?.property_type,
          location: res?.data?.property?.location,
          phone: res?.data?.property?.phone,
          phone_call_time: res?.data?.property?.phone_call_time,
          price: res?.data?.property?.price,
          description: res?.data?.property?.description,
          map_url: res?.data?.property?.map_url,
          discount: res?.data?.property?.discount,
          star: res?.data?.property?.star,
          amenities: res?.data?.property?.amenities,
          extra_features: res?.data?.property?.extra_features,
          is_complete: res?.data?.property?.is_complete + "",
          status: res?.data?.property?.status,
          images: res?.data?.property?.images,
          image: res?.data?.property?.image,
        };
        if (res?.data?.property?.image) setImage(res?.data?.property?.image);
        if (res?.data?.property?.images[0])
          setImage1(res?.data?.property?.images[0]);
        if (res?.data?.property?.images[1])
          setImage2(res?.data?.property?.images[1]);
        if (res?.data?.property?.images[2])
          setImage3(res?.data?.property?.images[2]);
        if (res?.data?.property?.images[3])
          setImage4(res?.data?.property?.images[3]);
        setState(initialState);
      }
    } catch (error) {
      console.error("Error is:", error);
      setLoading(false);
    }
  };

  const amenities = state?.amenities?.reduce((acc, item) => {
    acc[item] = item;
    return acc;
  }, {});

  const handleAmenities = (arg) => {
    let ameni = state?.amenities ?? [];
    if (amenities[arg]) {
      let foundIdx = ameni.findIndex((item) => item == arg);
      let copy = JSON.parse(JSON.stringify(ameni));
      copy.splice(foundIdx, 1);
      setState((prev) => ({
        ...prev,
        amenities: copy,
      }));
    } else {
      setState((prev) => ({
        ...prev,
        amenities: [...prev.amenities, arg],
      }));
    }
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    if (name == "feature") {
      setFeature(value);
    }
  };

  const handleInputKeyDown = (e) => {
    if (e.key === "Enter" && feature.trim() !== "") {
      setState((prev) => {
        const extraFeaturesArray = Array.isArray(prev.extra_features)
          ? prev.extra_features
          : [];

        return {
          ...prev,
          extra_features: [...extraFeaturesArray, feature.trim()],
        };
      });
      setFeature("");
    }
  };
  const handleTagRemove = (arg) => {
    console.log("Removing Tag:", arg);
    console.log("Current State:", state);

    const updatedTags =
      state?.extra_features?.filter((tag) => tag !== arg) || [];
    console.log("Updated Tags:", updatedTags);

    setState((prev) => ({
      ...prev,
      extra_features: updatedTags,
    }));
    console.log("After setState:", state);
  };

  const handleSubmit = async () => {
    if (!data?.property?.name) {
      Toaster("info", translate("Enter Property Name"));
      return;
    }
    if (!data?.property?.property_type) {
      Toaster("info", translate("Enter Property Type"));
      return;
    }
    if (!data?.property?.location) {
      Toaster("info", translate("Select Location"));
      return;
    }

    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("name", state.name);
      if (ori_image) {
        formData.append("image", ori_image);
      }
      formData.append("property_type", state.property_type);
      formData.append("location", state.location);
      formData.append("phone", state.phone);
      formData.append("phone_call_time", state.phone_call_time);
      formData.append("price", state.price);
      // if (state.is_complete == "1") {
      //   formData.append("price", state.price);
      // }
      formData.append("discount", state.discount);
      formData.append("description", state.description);
      formData.append("map_url", state.map_url);
      if (state.star != null) {
        formData.append("star", state.star);
      }
      formData.append("status", state.status);
      formData.append("is_complete", state.is_complete);

      if (state.extra_features) {
        state.extra_features.forEach((feature, index) => {
          formData.append(`extra_features[${index}]`, feature);
        });
      }

      state.amenities.forEach((amenity, index) => {
        formData.append(`amenities[${index}]`, amenity);
      });
      // Append other form fields as needed
      const images = [ori_image1, ori_image2, ori_image3, ori_image4];
      images.forEach((image, index) => {
        if (image) {
          formData.append(`images[${index}]`, image);
        }
      });
      const res = await makeHttpRequest(
        "POST",
        `owner/property/update/${id}`,
        formData
      );
      setLoading(false);

      if (res.success) {
        navigate("/dashboard", { state: "all-property" });
      } else {
        // Handle error (e.g., display an error message)
      }
    } catch (error) {
      console.error("Error is:", error);
      setLoading(false);
    }
  };

  const handleBackButtonClick = () => {
    navigate("/dashboard", { state: "all-property" });
  };

  return (
    <div className="container">
      <div className="updateProperty-container">
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="fw-bold mb-3 mt-3 pt-3">
            {translate("Update Property")}
          </h2>

          <button
            className="addProperty-addBtn text-white fw-bold"
            onClick={handleBackButtonClick}
          >
            <BsArrowLeft className="mr-2" />
            <p className="d-inline">All Property</p>
          </button>
        </div>

        {loading && (
          <Stack alignItems="center" justifyContent="center">
            <CircularProgress size={50} sx={{ color: "#8bc53e" }} />
          </Stack>
        )}
        {!loading && (
          <div>
            <div className="updateProperty-contentBg">
              <div className="updateProperty-detailHeading">
                <h2>{translate("Property details")}</h2>
              </div>

              <div className="row">
                <div className="col-lg-8 col-md-12 col-12">
                  <div className="row">
                    <div className="col-lg-6 col-md-12 col-12 mt-3 updateProperty-name-inputs">
                      <label className="updateProperty-inputLabel">
                        {translate("Property Name")}
                        <span>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={translate("Enter Property Name")}
                        required
                        name="name"
                        value={state?.name}
                        onChange={(e) =>
                          setState({ ...state, name: e.target.value })
                        }
                      />
                    </div>
                    <div className="col-lg-6 col-md-12 col-12 mt-3 updateProperty-name-inputs">
                      <label className="updateProperty-inputLabel">
                        {translate("Property Type")}
                        <span>*</span>
                      </label>
                      <div
                        className="updateProperty-dropdown"
                        style={{ width: "100%" }}
                      >
                        <select
                          name="property_type"
                          value={state?.property_type}
                          onChange={(e) => {
                            const selectedType = e.target.value;
                            const selectedDataType =
                              e.target.options[e.target.selectedIndex].dataset
                                .type;
                            setState({
                              ...state,
                              property_type: selectedType,
                              is_complete: selectedDataType,
                            });
                          }}
                        >
                          {!state?.property_type && (
                            <option key={0} value="0">
                              {translate("Select Type")}
                            </option>
                          )}
                          {data?.property_types?.map((item, i) => (
                            <option
                              key={item?.id}
                              value={item?.id}
                              data-type={item.type}
                            >
                              {item?.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-12 col-12 mt-4 updateProperty-inputs">
                      <label className="updateProperty-inputLabel">
                        {translate("Location")}
                        <span>*</span>
                      </label>
                      <div
                        className="updateProperty-dropdown"
                        style={{ width: "100%" }}
                      >
                        <select
                          name="location"
                          value={state.location}
                          onChange={(e) =>
                            setState({ ...state, location: e.target.value })
                          }
                        >
                          {!state?.location && (
                            <option value="">
                              {translate("Select Location")}
                            </option>
                          )}

                          {data?.locations?.map((item, i) => (
                            <option key={i} value={item.id}>
                              {item?.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-12 col-12 mt-4 updateProperty-name-inputs">
                      <label className="updateProperty-inputLabel">
                        {translate("Google Map Embed URL")}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={translate("Google Map Embed URL")}
                        required
                        name="map_url"
                        value={state.map_url}
                        onChange={(e) =>
                          setState({ ...state, map_url: e.target.value })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-12 mt-3">
                  <label className="updateProperty-inputLabel">
                    {translate("Upload Image")}
                  </label>
                  <ImageUploader
                    selectedImage={image}
                    onImageChange={setImage}
                    onChangeEvent={setOriImage}
                    // onFileChange={setImage}
                  />
                </div>
              </div>

              <div className="row">
                {/* Star */}
                {state.is_complete === "0" && data?.star > 0 && (
                  <div className="col-lg-4 col-md-12 col-12 mt-4 addProperty-name-inputs">
                    <label className="addProperty-inputLabel">
                      {translate("Select Stars")}
                      <span>*</span>
                    </label>
                    <div
                      className="addProperty-dropdown"
                      style={{ width: "100%" }}
                    >
                      <select
                        name="star"
                        value={state.star}
                        onChange={(e) =>
                          setState({ ...state, star: e.target.value })
                        }
                      >
                        {Array.from(
                          { length: data?.star },
                          (_, i) =>
                            i !== 0 && (
                              <option key={i} value={i}>
                                {i}
                              </option>
                            )
                        )}
                      </select>
                    </div>
                  </div>
                )}
                {/* Price */}
                {/* {state.is_complete === "1" && (
                  <div className="col-lg-4 col-md-12 col-12 mt-4 addProperty-name-inputs">
                    <label className="addProperty-inputLabel">
                      {translate("Property Price")}
                      <span>*</span>
                    </label>
                    <div className="input-group mb-3">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Price"
                        aria-describedby="basic-addon2"
                        value={state.price}
                        onChange={(e) =>
                          setState({ ...state, price: e.target.value })
                        }
                      />
                      <div className="input-group-append">
                        <span
                          className="input-group-text"
                          id="basic-addon2"
                          style={{ background: "#45AAF0", color: "#FFF" }}
                        >
                          {data?.curr_name}
                        </span>
                      </div>
                    </div>
                  </div>
                )} */}
                <div className="col-lg-4 col-md-12 col-12 mt-4 addProperty-name-inputs">
                    <label className="addProperty-inputLabel">
                      {translate("Property Price")}
                      <span>*</span>
                    </label>
                    <div className="input-group mb-3">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Price"
                      name="price"

                        aria-describedby="basic-addon2"
                        value={state.price}
                        onChange={(e) =>
                          setState({ ...state, price: e.target.value })
                        }
                      />
                      {/* <div className="input-group-append">
                        <span
                          className="input-group-text"
                          id="basic-addon2"
                          style={{ background: "#45AAF0", color: "#FFF" }}
                        >
                          {data?.curr_name}
                        </span>
                      </div> */}
                    </div>
                  </div>
                <div className="col-lg-4 col-md-12 col-12 mt-4 addProperty-name-inputs">
                  <label className="addProperty-inputLabel">
                    {translate("Discount")}
                  </label>
                  <div className="input-group mb-3">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Discount"
                      aria-describedby="basic-addon2"
                      name="discount"
                      value={state.discount}
                      onChange={(e) =>
                        setState({ ...state, discount: e.target.value })
                      }
                    />
                    <div className="input-group-append">
                      <span
                        className="input-group-text"
                        id="basic-addon2"
                        style={{ background: "#45AAF0", color: "#FFF" }}
                      >
                        {translate("%")}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-12 mt-4 addProperty-name-inputs">
                  <label className="addProperty-inputLabel">
                    {translate("Extra Features")}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    name="feature"
                    value={feature}
                    onChange={handleInput}
                    onKeyDown={handleInputKeyDown}
                  />
                  <p className="addNewBoat-xxtraFeature">
                    {translate("Write features then press enter")}
                  </p>

                  <div
                    className="tag-container"
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      marginTop: "10px",
                    }}
                  >
                    {state?.extra_features?.map((tag, i) => (
                      <div
                        className="tag m-1"
                        style={{
                          background: "#8cc5ed",
                          marginRight: "5px",
                          width: "fit-content",
                          borderRadius: "4px",
                          padding: "3px",
                        }}
                        key={i}
                      >
                        <span style={{ color: "white" }}>{tag}</span>
                        <span
                          style={{
                            color: "red",
                            marginLeft: "5px",
                            cursor: "pointer",
                          }}
                          className="tag-remove"
                          onClick={() => handleTagRemove(tag)}
                        >
                          &#10006;
                        </span>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="col-lg-4 col-md-12 col-12 mt-4 updateProperty-status-inputs">
                  <label className="updateProperty-inputLabel">
                    {translate("Status")}
                  </label>
                  <div
                    className="addProperty-dropdown"
                    style={{ width: "100%" }}
                  >
                    <select
                      name="status"
                      value={state.status}
                      onChange={(e) =>
                        setState({ ...state, status: e.target.value })
                      }
                    >
                      <option key={1} value={1}>
                        {translate("Active")}
                      </option>
                      <option key={0} value={0}>
                        {translate("InActive")}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="addProperty-amenitiesBg">
              <div className="addProperty-amenities">
                <h2>{translate("Amenities")}</h2>
              </div>
              <div className="row">
                {data?.amenities?.map((item) => {
                  return (
                    <div className="col-lg-3 col-md-6 col-12 p-1">
                      <button
                        className={
                          amenities[item.id]
                            ? "addProperty-tabs addProperty-selected-tab"
                            : "addProperty-tabs"
                        }
                        onClick={() => handleAmenities(item.id)}
                      >
                        {item?.name}
                        {amenities[item.id] && (
                          <BiCheck
                            style={{
                              color: "#fff",
                              fontSize: "18px",
                              fontWeight: "500",
                            }}
                          />
                        )}
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="updateProperty-amenitiesBg">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-12 updateProperty-message-input">
                  <label className="updateProperty-inputLabel">
                    {translate("Description")}
                  </label>
                  <textarea
                    className="form-control"
                    placeholder=""
                    required
                    rows={10}
                    value={state.description}
                    onChange={(e) =>
                      setState({ ...state, description: e.target.value })
                    }
                  ></textarea>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-12 addProperty-inputs">
                  <label className="addProperty-inputLabel">
                    {translate("Image Gallery")}
                  </label>
                  <div className="row">
                    <div className="col-lg-3 col-md-12 col-12 ">
                      <ImageUploader
                        selectedImage={image1}
                        onImageChange={setImage1}
                        onChangeEvent={setOriImage1}
                      />
                    </div>
                    <div className="col-lg-3 col-md-12 col-12 ">
                      <ImageUploader
                        selectedImage={image2}
                        onImageChange={setImage2}
                        onChangeEvent={setOriImage2}
                      />
                    </div>
                    <div className="col-lg-3 col-md-12 col-12">
                      <ImageUploader
                        selectedImage={image3}
                        onImageChange={setImage3}
                        onChangeEvent={setOriImage3}
                      />
                    </div>
                    <div className="col-lg-3 col-md-12 col-12">
                      <ImageUploader
                        selectedImage={image4}
                        onImageChange={setImage4}
                        onChangeEvent={setOriImage4}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="update-property-wrapper ">
              <div></div>
              <div className="">
                <button className="text-white" onClick={handleSubmit}>
                  {translate("+ Update Property")}
                </button>
              </div>
            </div>
            <div className="row">
              {/* <div className="col-lg-12 col-md-12 col-12">
                <div className="updateProperty-submitBtn">
                  {loading && (
                    <Stack alignItems="center" justifyContent="center">
                      <CircularProgress size={50} sx={{ color: "#8bc53e" }} />
                    </Stack>
                  )}
                  {!loading && (
                    <button className="text-white" onClick={handleSubmit}>
                      {translate("Update Property")}
                    </button>
                  )}
                </div>
              </div> */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default UpdateProperty;
