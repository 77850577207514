import React, { useEffect, useState } from "react";
import "./DashboardProfile.css";
import { CircularProgress, Stack } from "@mui/material";
import makeHttpRequest from "../../utils/api";
import { useLanguage } from "../../utils/LanguageContext";
import Toaster from "../../Components/Toaster";
import AddressAutocomplete from "../../Components/AddressAutocomplete";
import { googleMapApiKey } from "../../utils/helpers";

function DashboardProfile() {
  const [fullname, setfullname] = useState("");
  const [email, setemail] = useState("");
  const [phonenumber, setphonenumber] = useState("");
  const [address, setAddress] = useState("");
  const [current_password, setCurrentPassword] = useState("");
  const [new_password, setNewPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const [bio, setBio] = useState("");

  const { langData, fetchLangData, translate } = useLanguage();
  const [loading, setLoading] = useState(false);
  const [place, setPlace] = useState(null);
  const [country_code, setCountryCode] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await makeHttpRequest("GET", "owner/profile");
      setfullname(response?.data?.user?.name);
      setemail(response?.data?.user?.email);
      setphonenumber(response?.data?.user?.phone);
      setAddress(response?.data?.user?.address?.address);
      setBio(response?.data?.user?.bio);
      setLoading(false);
    } catch (error) {
      console.error("Error is:", error);
      setLoading(false);
    }
  };

  const handlePlaceChanged = (place) => {
    setPlace(place);
    setAddress(place.formattedAddress);
    const countryAddressComponent = place.address_components?.find(
      (addressComponent) => addressComponent.types[0] === "country"
    );
    if (countryAddressComponent) {
      setCountryCode(countryAddressComponent.short_name);
    }
  };

  const changePassword = () => {
    let data = {
      current_password: current_password,
      new_password: new_password,
      confirm_password: confirm_password,
    };
    callApiForSave(data);
  };

  const changeBio = () => {
    let data = {
      bio: bio,
    };
    callApiForSave(data);
  };

  const changeAddress = () => {
    let data = {
      address: place?.formatted_address,
    };
    callApiForSave(data);
  };

  const callApiForSave = async (data) => {
    try {
      setLoading(true);
      const response = await makeHttpRequest("POST", "owner/profile", data);
      setLoading(false);
      Toaster("success", response?.data?.message);
    } catch (error) {
      console.error("Error is:", error);
      setLoading(false);
    }
  };

  return (
    <>
      {loading && (
        <Stack height="100vh" alignItems="center" justifyContent="center">
          <CircularProgress size={100} sx={{ color: "#8bc53e" }} />
        </Stack>
      )}
      <div className="addProperty-container">
        <div className="row mt-3">
          {!loading && (
            <div className="col-lg-12 col-md-12 col-12">
              <div className="supportTicket-bg">
                <div
                  className="table-responsive"
                  style={{ borderRadius: "10px" }}
                >
                  <div className="supportTicket-wrapper">
                    <form>
                      <p
                        className="h1 ml-4 my-3"
                        style={{ fontFamily: "Montserrat" }}
                      >
                        {translate("Owner Info")}
                      </p>
                      <div className="form-group col">
                        <b>
                          <label
                            htmlFor="fullName"
                            className="col-sm-12 col-form-label"
                            style={{ fontFamily: "Montserrat" }}
                          >
                            {translate("Full name")}
                          </label>
                        </b>
                        <br />
                        <div className="col-sm-12 d-flex justify-content-between m-0">
                          <input
                            disabled
                            type="text"
                            className="form-control-plaintext pl-4"
                            id="fullName"
                            onChange={(e) => {
                              setfullname(e.target.value);
                            }}
                            style={{ fontFamily: "Montserrat" }}
                            value={fullname}
                          />
                          &nbsp;{" "}
                          <a
                            href="#"
                            className="anchorTagcolor ml-2 mt-2"
                            style={{ fontFamily: "Montserrat" }}
                          >
                            {/* {translate('Save')} */}
                          </a>
                        </div>
                      </div>
                      <hr className="hr" />
                      <div className="form-group col">
                        <b>
                          <label
                            htmlFor="email"
                            className="col-sm-12 col-form-label"
                            style={{ fontFamily: "Montserrat" }}
                          >
                            {translate("Email address")}
                          </label>
                        </b>
                        <br />
                        <div className="col-sm-12 d-flex justify-content-between m-0">
                          <input
                            disabled
                            type="email"
                            className="form-control-plaintext pl-4"
                            id="email"
                            onChange={(e) => {
                              setemail(e.target.value);
                            }}
                            value={email}
                            style={{ fontFamily: "Montserrat" }}
                          />
                          &nbsp;{" "}
                          <a
                            className="anchorTagcolor ml-2 mt-2"
                            style={{ fontFamily: "Montserrat" }}
                          >
                            {/* {translate('Save')} */}
                          </a>
                        </div>
                      </div>
                      <hr className="hr" />
                      <div className="form-group col">
                        <b>
                          <label
                            htmlFor="phoneNumber"
                            className="col-sm-12 col-form-label"
                            style={{ fontFamily: "Montserrat" }}
                          >
                            {translate("Phone number")}
                          </label>
                        </b>
                        <br />
                        <div className="col-sm-12 d-flex justify-content-between m-0">
                          <input
                            disabled
                            type="text"
                            className="form-control-plaintext pl-4"
                            id="phoneNumber"
                            onChange={(e) => {
                              setphonenumber(e.target.value);
                            }}
                            value={phonenumber}
                            style={{ fontFamily: "Montserrat" }}
                          />
                          &nbsp;{" "}
                          <a
                            href="#"
                            className="anchorTagcolor ml-2 mt-2"
                            style={{ fontFamily: "Montserrat" }}
                          >
                            {/* {translate('Save')} */}
                          </a>
                        </div>
                      </div>
                      <hr className="hr" />
                      <div className="form-group col">
                        <b>
                          <label
                            htmlFor="address"
                            className="col-sm-12 col-form-label"
                            style={{ fontFamily: "Montserrat" }}
                          >
                            {translate("Address")}
                          </label>
                        </b>
                        <br />
                        <div className="col-sm-12">
                          <div className="row pb-2">
                            <div className="col-sm-2 d-flex justify-content-center align-items-center m-0">
                              <b>{translate("Current Address")}</b>
                            </div>
                            <div className="col-sm-10">
                              <input
                                disabled
                                type="text"
                                className="form-control bg-white border-0"
                                id="address"
                                value={address}
                                style={{ fontFamily: "Montserrat" }}
                              />
                            </div>
                            <div className="col-sm-12 d-flex justify-content-between m-0">
                              <AddressAutocomplete
                                apiKey={googleMapApiKey()}
                                onPlaceChanged={handlePlaceChanged}
                                AddressSet={setAddress}
                                placeHolder={translate("Enter a location")}
                                className={"form-control-plaintext pl-4"}
                                currentValue={address}
                              />{" "}
                              <span
                                href="#"
                                className="anchorTagcolor ml-2 mt-2"
                                style={{
                                  fontFamily: "Montserrat",
                                  cursor: "pointer",
                                }}
                                onClick={changeAddress}
                              >
                                {translate("Save")}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr className="hr" />

                      {/* Password Change */}
                      <div className="form-group col">
                        <b>
                          <label
                            htmlFor="current_password"
                            className="col-sm-12 col-form-label"
                            style={{ fontFamily: "Montserrat" }}
                          >
                            {translate("Current Password")}
                          </label>
                        </b>
                        <br />
                        <div className="col-sm-12 d-flex justify-content-between m-0">
                          <input
                            type="text"
                            className="form-control-plaintext pl-4"
                            id="current_password"
                            placeholder={translate("Type Current Password")}
                            onChange={(e) => {
                              setCurrentPassword(e.target.value);
                            }}
                            value={current_password}
                            style={{ fontFamily: "Montserrat" }}
                          />
                          &nbsp;{" "}
                          <span
                            href="#"
                            className="anchorTagcolor ml-2 mt-2"
                            style={{
                              fontFamily: "Montserrat",
                              cursor: "pointer",
                            }}
                          >
                            {/* {translate("Save")} */}
                          </span>
                        </div>
                      </div>
                      <hr className="hr" />
                      <div className="form-group col">
                        <b>
                          <label
                            htmlFor="new_password"
                            className="col-sm-12 col-form-label"
                            style={{ fontFamily: "Montserrat" }}
                          >
                            {translate("New Password")}
                          </label>
                        </b>
                        <br />
                        <div className="col-sm-12 d-flex justify-content-between m-0">
                          <input
                            type="text"
                            className="form-control-plaintext pl-4"
                            id="new_password"
                            placeholder={translate("Type New Password")}
                            onChange={(e) => {
                              setNewPassword(e.target.value);
                            }}
                            value={new_password}
                            style={{ fontFamily: "Montserrat" }}
                          />
                          &nbsp;{" "}
                          <span
                            href="#"
                            className="anchorTagcolor ml-2 mt-2"
                            style={{
                              fontFamily: "Montserrat",
                              cursor: "pointer",
                            }}
                          >
                            {/* {translate("Save")} */}
                          </span>
                        </div>
                      </div>
                      <hr className="hr" />
                      <div className="form-group col">
                        <b>
                          <label
                            htmlFor="confirm_password"
                            className="col-sm-12 col-form-label"
                            style={{ fontFamily: "Montserrat" }}
                          >
                            {translate("Confirm Password")}
                          </label>
                        </b>
                        <br />
                        <div className="col-sm-12 d-flex justify-content-between m-0">
                          <input
                            type="text"
                            className="form-control-plaintext pl-4"
                            id="confirm_password"
                            placeholder={translate("Type Confirm Password")}
                            onChange={(e) => {
                              setConfirmPassword(e.target.value);
                            }}
                            value={confirm_password}
                            style={{ fontFamily: "Montserrat" }}
                          />
                          &nbsp;{" "}
                          <span
                            href="#"
                            className="anchorTagcolor ml-2 mt-2"
                            style={{
                              fontFamily: "Montserrat",
                              cursor: "pointer",
                            }}
                            onClick={changePassword}
                          >
                            {translate("Save")}
                          </span>
                        </div>
                      </div>
                      <hr className="hr" />
                      {/* End Password Change */}

                      <div className="form-group col">
                        <b>
                          <label
                            htmlFor="bio"
                            className="col-sm-12 col-form-label"
                            style={{ fontFamily: "Montserrat" }}
                          >
                            {translate("Bio")}
                          </label>
                        </b>
                        <br />
                        <div className="col-sm-12 d-flex justify-content-between m-0">
                          <textarea
                            placeholder={translate("Enter your bio")}
                            className="form-control-plaintext pl-4"
                            onChange={(e) => {
                              setBio(e.target.value);
                            }}
                            value={bio}
                          >
                            {bio}
                          </textarea>
                          &nbsp;{" "}
                          <span
                            href="#"
                            className="anchorTagcolor ml-2 mt-2"
                            style={{
                              fontFamily: "Montserrat",
                              cursor: "pointer",
                            }}
                            onClick={changeBio}
                          >
                            {translate("Save")}
                          </span>
                        </div>
                      </div>
                      <hr className="hr" />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default DashboardProfile;
