import React, { useState, useEffect } from "react";
import "./CreateNewBoat.css";
import { Link, useNavigate } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";
import ImageUploader from "../../Components/ImageUploader/NewImageUploader";
import makeHttpRequest from "../../utils/api";
import { BiCheck, BiInfoCircle, BiSolidTrash } from "react-icons/bi";
import { CircularProgress, Stack } from "@mui/material";
import Toaster from "../../Components/Toaster";
import { useLanguage } from "../../utils/LanguageContext";
import { Button, Modal } from "react-bootstrap";
import ReactImagePickerEditor from "react-image-picker-editor";
import "react-image-picker-editor/dist/index.css";
import { showAmount } from "../../utils/showAmount";
import { TiEdit } from "react-icons/ti";
import { MdDelete } from "react-icons/md";
import Loader from "../../Components/Loader";

function CreateNewBoat({ activeTab, setActiveTab, refreshData }) {
  console.log("activeTab", activeTab);
  const navigate = useNavigate();

  const [image, setImage] = useState(null);
  const [image1a, setImage1a] = useState(null);
  const [image1b, setImage1b] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const [imageCount, setImageCount] = useState(0); // Initial count of images

  // State to store image data
  const [imageData, setImageData] = useState([{ selected: null, ori: null }]);

  // Function to handle image change
  const handleImageChange = (index, newImage) => {
    setImageData((prevImageData) => {
      const updatedImageData = [...prevImageData];
      updatedImageData[index] = {
        ...updatedImageData[index],
        selected: newImage,
      };
      console.log(updatedImageData);
      return updatedImageData;
    });
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  // Function to add a new ImageUploader
  const addImageUploader = () => {
    setImageCount(imageCount + 1);
    setImageData((prevImageData) => [
      ...prevImageData,
      { selected: null, ori: null },
    ]);
  };

  const { translate } = useLanguage();

  let initialState = {
    name: "",
    boat_type: "",
    location: "",
    phone: "",
    adult: "",
    child: "",
    phone_call_time: "",
    price: "", // Ensure this is an empty string or null
    description: "",
    url: "",
    discount: "",
    amenities: [],
    extra_features: [],
    // image:null,
    images: [],
  };
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);
  const [feature, setFeature] = useState("");
  const [state, setState] = useState(initialState);
  const [slots, setSlots] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      setLoading(true);
      const res = await makeHttpRequest("GET", "owner/boat/create");
      setLoading(false);
      if (res.success) {
        setData(res.data);
      }
    } catch (error) {
      console.error("Error is:", error);
      setLoading(false);
    }
  };

  const amenities = state?.amenities.reduce((acc, item) => {
    acc[item] = item;
    return acc;
  }, {});

  const handleAmenities = (arg) => {
    if (amenities[arg]) {
      let foundIdx = state.amenities.findIndex((item) => item == arg);
      let copy = JSON.parse(JSON.stringify(state.amenities));
      copy.splice(foundIdx, 1);
      setState((prev) => ({ ...prev, amenities: copy }));
    } else {
      setState((prev) => ({
        ...prev,
        amenities: [...prev.amenities, arg],
      }));
    }
  };
  const handleInput = (e) => {
    const { name, value } = e.target;
    if (name == "feature") {
      setFeature(value);
    }
  };
  const handleInputKeyDown = (e) => {
    if (e.key === "Enter" && feature.trim() !== "") {
      setState((prev) => ({
        ...prev,
        extra_features: [...prev.extra_features, feature.trim()],
      }));
      setFeature("");
    }
  };

  const deleteThisItem = async (id) => {
    try {
      setLoading(true);
      const res = await makeHttpRequest("GET", "owner/boat/destroy/" + id);
      getData();
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleEditClick = (id) => {
    navigate(`/boat/${id}/edit`);
  };

  const handleTagRemove = (arg) => {
    const updatedTags = state.extra_features.filter((tag) => tag !== arg);
    setState((prev) => ({ ...prev, extra_features: updatedTags }));
  };

  const handleSubmit = async () => {
    if (!state.name) {
      Toaster("info", "Enter Boat Name");
      return;
    }
    if (!state.boat_type) {
      Toaster("info", "Enter Boat Type");
      return;
    }
    if (!state.location) {
      Toaster("info", "Select Location");
      return;
    }
    if (!state.price) {
      Toaster("info", "Enter Price");
      return;
    }
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("name", state.name);
      if (image) {
        formData.append("image", image);
      }
      if (image1a) {
        formData.append("image1a", image1a);
      }
      if (image1b) {
        formData.append("image1b", image1b);
      }
      formData.append("boat_type", state.boat_type);
      formData.append("location", state.location);
      formData.append("phone", state.phone);
      formData.append("price", state.price);
      formData.append("adult", state.adult);
      formData.append("child", state.child);
      formData.append("phone_call_time", state.phone_call_time);
      formData.append("discount", state.discount);
      formData.append("description", state.description);
      formData.append("url", state.url);

      state.extra_features.forEach((feature, index) => {
        formData.append(`extra_features[${index}]`, feature);
      });

      state.amenities.forEach((amenity, index) => {
        formData.append(`amenities[${index}]`, amenity);
      });
      // Append other form fields as needed
      imageData.forEach((new_data, index) => {
        console.log(new_data);
        const { ori, selected } = new_data;
        // if (ori) {
        // Append each image to the FormData with a dynamic key
        formData.append(`images[${index}]`, selected);
        // }
      });

      // Send a POST request to the server to submit the data
      const res = await makeHttpRequest("POST", "owner/boat/store", formData);
      setLoading(false);
      if (res.success) {
        Toaster("success", "Property added successfully");
        setActiveTab("all-boat");
        refreshData();
        navigate("/dashboard", { state: "all-Boat" });
      } else {
        Toaster("error", res.message || "Error adding property");
      }
    } catch (error) {
      console.error("Error is:", error);
      setLoading(false);
    }
  };

  const config2 = {
    borderRadius: "8px",
    language: "en",
    width: "330px",
    height: "250px",
    objectFit: "contain",
    compressInitial: null,
    hideDownloadBtn: true,
    hideDeleteBtn: true,
    hideEditBtn: false,
    hideAddBtn: false,
  };

  const config3 = {
    borderRadius: "8px",
    language: "en",
    width: "330px",
    height: "200px",
    objectFit: "contain",
    compressInitial: null,
    hideDownloadBtn: true,
    hideDeleteBtn: true,
    hideEditBtn: false,
    hideAddBtn: false,
  };

  const addSlot = () => {
    setSlots([
      ...slots,
      <div className="row mb-3" key={slots.length}>
        <div className="col-md-4">
          <input type="time" className="form-control" name="to[]" />
        </div>
        <div className="col-md-4">
          <input type="time" className="form-control" name="from[]" />
        </div>
        <div className="col-md-3">
          <input
            type="number"
            className="form-control"
            placeholder="Price"
            aria-describedby="basic-addon2"
          />
        </div>
        <div className="col-md-1">
          <span onClick={() => deleteSlot(slots.length)}>
            <BiSolidTrash style={{ color: "#ff0000" }} />
          </span>
        </div>
      </div>,
    ]);
  };

  const deleteSlot = (index) => {
    const updatedSlots = [...slots];
    updatedSlots.splice(index, 1);
    setSlots(updatedSlots);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "all-boat-tab":
        return (
          <>
            <div className="table-responsive">
              <div className="table-wrapper">
                <table
                  id="mytable"
                  className="table table-bordred table-striped"
                >
                  <thead className="allBoat-table-th">
                    <th>{translate("S.N.")}</th>
                    <th>{translate("Name")}</th>
                    <th>{translate("Boat Type")}</th>
                    <th>{translate("Location")}</th>
                    <th>{translate("Discount")}</th>
                    <th>{translate("Status")}</th>
                    <th>{translate("Action")}</th>
                  </thead>
                  <tbody>
                    {data?.boats?.data?.map((row, index) => (
                      <tr key={index} className="allBoat-td-wrapper">
                        <td style={{ paddingTop: "18px" }}>
                          <div className="sr-no-bg">{index + 1}</div>
                        </td>
                        <td style={{ paddingTop: "20px" }}>{row.name}</td>
                        <td style={{ paddingTop: "20px" }}>
                          {row.boat_type.name}
                        </td>
                        <td style={{ paddingTop: "20px" }}>
                          {row.location.name}
                        </td>
                        <td style={{ paddingTop: "20px" }}>
                          {showAmount(row.discount)}
                          {translate("%")}
                        </td>
                        <td
                          style={{ paddingTop: "20px" }}
                          className="allBoat-status"
                        >
                          {row.status === 1 ? (
                            <span className="badge badge-success">
                              {translate("Active")}
                            </span>
                          ) : (
                            <span className="badge badge-warning">
                              {translate("Inactive")}
                            </span>
                          )}
                        </td>
                        <td className="p-2">
                          <div className="d-flex justify-content-center gap-3 align-items-center">
                            <div>
                              {" "}
                              <div
                                title="edit"
                                className="all-baot-edit-icon"
                                onClick={() => handleEditClick(row?.id)}
                              >
                                <span>
                                  <TiEdit />
                                </span>
                                {/* {translate(" Edit")} */}
                              </div>
                            </div>
                            <div
                              title="delete"
                              className="all-baot-delete-icon"
                              onClick={() => deleteThisItem(row?.id)}
                              style={{ cursor: "pointer" }}
                            >
                              <span>
                                <MdDelete />
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            {data?.booked_properties?.data?.length == 0 && (
              <div className="successProperty-Img">
                <img src="/assets/icons/pendingProperty-img.png" alt="..." />
              </div>
            )}
          </>
        );

      case "add-boat":
        return (
          <div>
            <CreateNewBoat />
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <div className="addNewBoat-container p-0">
          {/* Start Hourly Price */}
          <Modal show={showModal} style={{ paddingTop: "100px" }}>
            <div className="modal-confirm modal-bg">
              <div className="modal-header">
                <h4 className="modal-title w-100 text-center mt-3">
                  {translate("Hourly Prices")}
                </h4>
                <div className="d-flex justify-content-center text-center">
                  <BiInfoCircle
                    style={{
                      backgroundColor: "#000",
                      borderRadius: "10%",
                      fontSize: "50px",
                      color: "#ffff00",
                    }}
                    title={translate(
                      "If the price is not set, then the main price will be displayed by default."
                    )}
                  />
                  {translate(
                    "If the price is not set, then the main price will be displayed by default."
                  )}
                </div>
              </div>

              <div className="modal-body">
                <div
                  className="d-flex flex-column justify-content-center"
                  id="addSlot"
                >
                  <div className="row mb-3">
                    <div className="col-md-4">
                      <label>{translate("To")}</label>
                      <input type="time" className="form-control" name="to[]" />
                    </div>
                    <div className="col-md-4">
                      <label>{translate("From")}</label>
                      <input
                        type="time"
                        className="form-control"
                        name="form[]"
                      />
                    </div>
                    <div className="col-md-4">
                      <label>
                        {translate("Price")}({data?.curr_name ?? "--"})
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Price"
                        // value={state.price}
                        // onChange={(e) =>
                        //   setState({ ...state, price: e.target.value })
                        // }
                      />
                    </div>
                  </div>

                  {slots.map((slot, index) => (
                    <React.Fragment key={index}>{slot}</React.Fragment>
                  ))}
                </div>
                <div className="d-flex justify-content-center"></div>
                <div className="d-flex justify-content-end">
                  <Button
                    variant="contained"
                    className="btn btn-outline-primary"
                    onClick={addSlot}
                    size="small"
                  >
                    {translate("Add Slot")}
                  </Button>
                </div>
              </div>
              <div className="modal-footer modalOk-btn">
                <button
                  style={{
                    background: "#70B25C",
                    color: "#FFF",
                    border: "none",
                    fontWeight: "bold",
                    borderRadius: "5px",
                  }}
                  className=" w-100 p-2"
                  onClick={handleCloseModal}
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  {translate("Remind Me Later")}
                </button>
              </div>
            </div>
          </Modal>

          <div className="addNewBoat-contentBg">
            <div className="addNewBoat-detailHeading">
              <h2>{translate(data?.page_title)}</h2>
            </div>

            <div className="row">
              <div className="col-lg-8 col-md-12 col-12">
                <div className="row">
                  <div className="col-lg-6 col-md-12 col-12 mt-3 addNewBoat-name-inputs">
                    <label className="addNewBoat-inputLabel">
                      {translate("Boat Name")}
                      <span>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Boat Name"
                      required
                      value={state.name}
                      onChange={(e) =>
                        setState({ ...state, name: e.target.value })
                      }
                    />
                  </div>
                  <div className="col-lg-6 col-md-12 col-12 mt-3 addNewBoat-name-inputs">
                    <label className="addNewBoat-inputLabel">
                      {translate("Boat Type")}
                      <span>*</span>
                    </label>
                    <div className="addNewBoat-dropdown">
                      <select
                        value={state.boat_type}
                        onChange={(e) =>
                          setState({ ...state, boat_type: e.target.value })
                        }
                      >
                        <option key={0} value="">
                          {translate("Select One")}
                        </option>
                        {data?.boat_types?.map((type, i) => (
                          <option key={type?.id} value={type?.id}>
                            {type.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-12">
                    <div className="row">
                      <div className="col-lg-6 col-md-12 col-12 mt-4 addNewBoat-name-inputs">
                        <label className="addNewBoat-inputLabel">
                          {translate("Boat Price")}
                          <span>*</span>
                        </label>
                        <div className="input-group mb-3">
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Enter Boat Price" // Keep your desired placeholder here
                            aria-describedby="basic-addon2"
                            value={state.price}
                            onChange={(e) =>
                              setState({ ...state, price: e.target.value })
                            }
                          />
                          <div className="input-group-append">
                            <span
                              className="input-group-text"
                              id="basic-addon2"
                              style={{ background: "#45AAF0", color: "#FFF" }}
                            >
                              {data?.curr_name ?? "--"}
                            </span>
                          </div>
                          {/* <div className="input-group-append">
                            <span
                              onClick={(e) => setShowModal(true)}
                              className="input-group-text"
                              id="basic-addon2"
                              style={{
                                background: "#40E0D0",
                                color: "#FFF",
                                cursor: "pointer",
                              }}
                            >
                              <i className="fa fa-plus"></i>{" "}
                              {translate("Hours")}
                            </span>
                          </div> */}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-12 mt-4 addNewBoat-name-inputs">
                        <label className="addNewBoat-inputLabel">
                          {translate("Discount")}
                        </label>
                        <div className="input-group mb-3">
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Discount"
                            aria-describedby="basic-addon2"
                            value={state.discount}
                            onChange={(e) =>
                              setState({ ...state, discount: e.target.value })
                            }
                          />
                          <div className="input-group-append">
                            <span
                              className="input-group-text"
                              id="basic-addon2"
                              style={{ background: "#45AAF0", color: "#FFF" }}
                            >
                              %
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-12 mt-3 addNewBoat-name-inputs">
                        <label className="addNewBoat-inputLabel">
                          {translate("Adults")}
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder={translate("Adults")}
                          required
                          value={state.adult}
                          onChange={(e) =>
                            setState({ ...state, adult: e.target.value })
                          }
                          min="1"
                        />
                      </div>
                      <div className="col-lg-6 col-md-12 col-12 mt-3 addNewBoat-name-inputs">
                        <label className="addNewBoat-inputLabel">
                          {translate("Childs")}
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder={translate("Childs")}
                          required
                          value={state.child}
                          onChange={(e) =>
                            setState({ ...state, child: e.target.value })
                          }
                          min="0"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4 col-md-12 col-12 mt-4 addNewBoat-name-inputs">
                    <label className="addNewBoat-inputLabel">
                      {translate("Location")}
                      <span>*</span>
                    </label>
                    <div className="addNewBoat-dropdown">
                      <select
                        value={state.location}
                        onChange={(e) =>
                          setState({ ...state, location: e.target.value })
                        }
                      >
                        <option key={0} value="">
                          {translate("Select One")}
                        </option>
                        {data?.locations?.map((type, index) => (
                          <option key={index} value={type.id}>
                            {type.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-12 col-12 mt-4 addNewBoat-name-inputs">
                    <label className="addNewBoat-inputLabel">
                      {translate("Google Map Embbed URL")}
                    </label>
                    <input
                      type="url"
                      className="form-control"
                      placeholder="Google URL"
                      required
                      value={state.url}
                      onChange={(e) =>
                        setState({ ...state, url: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-12 mt-4 addNewBoat-name-inputs">
                    <label className="addNewBoat-inputLabel">
                      {translate("Extra Features")}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      name="feature"
                      value={feature}
                      onChange={handleInput}
                      onKeyDown={handleInputKeyDown}
                    />
                    <p className="addNewBoat-xxtraFeature">
                      {translate("Write features then press enter")}
                    </p>
                    <div
                      className="tag-container"
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        marginTop: "10px",
                      }}
                    >
                      {state.extra_features.map((tag, i) => (
                        <div
                          className="tag"
                          style={{
                            background: "#8cc5ed",
                            marginRight: "5px",
                            width: "fit-content",
                            borderRadius: "4px",
                            padding: "3px",
                          }}
                          key={i}
                        >
                          <span style={{ color: "white" }}>{tag}</span>
                          <span
                            style={{
                              color: "red",
                              marginLeft: "5px",
                              cursor: "pointer",
                              fontSize: "11px",
                            }}
                            className="tag-remove"
                            onClick={() => handleTagRemove(tag)}
                          >
                            &#10006;
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-12 mt-3">
                <label className="addNewBoat-inputLabel">
                  {translate("Thumbnail 1")}
                </label>
                <ReactImagePickerEditor
                  imageChanged={(newImage) => newImage && setImage(newImage)}
                  config={config3}
                />
                <label className="addNewBoat-inputLabel">
                  {translate("Thumbnail 2")}
                </label>
                <ReactImagePickerEditor
                  imageChanged={(newImage) => newImage && setImage1a(newImage)}
                  config={config3}
                />
                <label className="addNewBoat-inputLabel">
                  {translate("Thumbnail 3")}
                </label>
                <ReactImagePickerEditor
                  imageChanged={(newImage) => newImage && setImage1b(newImage)}
                  config={config3}
                />
              </div>
            </div>
          </div>

          <div className="addNewBoat-amenitiesBg">
            <div className="addNewBoat-amenities">
              <h2>{translate("Amenities")}</h2>
            </div>
            <div className="row">
              {data?.amenities?.map((item, index) => {
                return (
                  <div key={index} className="col-lg-3 col-md-6 col-12 p-1">
                    <button
                      className={
                        amenities[item.id]
                          ? "addProperty-tabs addProperty-selected-tab"
                          : "addProperty-tabs"
                      }
                      onClick={() => handleAmenities(item.id)}
                    >
                      {item?.name}
                      {amenities[item.id] && (
                        <BiCheck
                          style={{
                            color: "#fff",
                            fontSize: "18px",
                            fontWeight: "500",
                          }}
                        />
                      )}
                    </button>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="addNewBoat-amenitiesBg">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12 addNewBoat-message-input">
                <label className="addNewBoat-inputLabel">
                  {translate("Description")}
                </label>
                <textarea
                  className="form-control"
                  placeholder=""
                  required
                  rows={10}
                  value={state.description}
                  onChange={(e) =>
                    setState({ ...state, description: e.target.value })
                  }
                ></textarea>
              </div>
            </div>
          </div>

          <div className="addNewBoat-amenitiesBg add-more-Images">
            {/* <div class="d-flex justify-content-between align-items-center flex-wrap">
              <h1 className="addNewBoat-inputLabel">
                {translate("Image Gallery")}
              </h1>
              <div>
                <button>{translate("Add More Image")}</button>
              </div>
            </div> */}
            {/* <div className="row">
          <div className="col-lg-9 col-md-9 col-9 addNewBoat-inputs">
            <label className="addNewBoat-inputLabel">
              {translate("Image Gallery")}
            </label>
          </div>
          <div className="col-lg-3 col-md-3 col-3 addNewBoat-submitBtn">
            <button className="text-white" onClick={addImageUploader}>
              {translate("Add More Image")}
            </button>
          </div>
          <div className="col-lg-12 col-md-12 col-12 addNewBoat-inputs">
            <div className="row">
              {imageData.map((image, index) => (
                <div
                  key={index}
                  className="col-lg-6 col-md-12 col-12 mt-5 d-flex justify-content-center"
                >
                  <ReactImagePickerEditor
                    imageChanged={(newImage) =>
                      newImage && handleImageChange(index, newImage)
                    }
                    config={config2}
                  />
                </div>
              ))}
            </div>
          </div>
        </div> */}

            <div className="addNewBoat-amenitiesBg add-more-Images">
              <div className="d-flex justify-content-between align-items-center flex-wrap">
                <h1 className="addNewBoat-inputLabel">
                  {translate("Image Gallery")}
                </h1>
                <div>
                  <button onClick={addImageUploader}>
                    {translate("Add More Image")}
                  </button>
                </div>
              </div>

              {/* Render the image uploaders based on imageCount */}
              <div className="row">
                {imageData.map((image, index) => (
                  <div
                    key={index}
                    className="col-lg-6 col-md-12 col-12 mt-5 d-flex justify-content-center"
                  >
                    <ReactImagePickerEditor
                      imageChanged={(newImage) =>
                        newImage && handleImageChange(index, newImage)
                      }
                      config={config2}
                    />
                  </div>
                ))}
              </div>

              <div className="row">
                <div className="col-lg-12 col-md-12 col-12">
                  <div className="addNewBoat-submitBtn">
                    <button onClick={handleSubmit}>
                      {translate("Add New Boat")}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="row">
              <div className="col-lg-12 col-md-12 col-12">
                <div className="addNewBoat-submitBtn">
                 

                  <button onClick={handleSubmit}>
                    {translate("Add New Boat")}
                  </button>
                </div>
              </div>
            </div> */}
            {renderTabContent()}
          </div>
        </div>
      )}
    </>
  );
}

export default CreateNewBoat;
