import React, { useState } from "react";
import { showAmount } from "../../../utils/showAmount";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import classes from "./UserChat.module.css";
import { useLanguage } from "../../../utils/LanguageContext";
import { MdClose } from "react-icons/md";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckOutForm";
function ConfirmModal() {
    
  const [confirm_open, setConfirmOpen] = useState(false);
  const { translate } = useLanguage();
  const [confirm_data, setConfirmData] = useState("");
  const stripePromise = loadStripe(`${confirm_data?.data?.val?.key}`);

  const options = {
    mode: "payment",
    name: confirm_data?.data?.val?.name,
    amount: confirm_data?.data?.val?.amount,
    currency: confirm_data?.data?.val?.currency.toLowerCase(),
    description: confirm_data?.data?.val?.description,
    // Fully customizable with appearance API.
    appearance: {
      /*...*/
    },
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  let style = {};
  let style2 = {};
  const isMobile = window.innerWidth <= 767;
  if (isMobile) {
    style = {
      width: "90%",
      bgcolor: "background.paper",
      boxShadow: "0px 4px 32px 0px rgba(26, 26, 26, 0.10)",
      borderRadius: "16px",
      border: "none",
      outline: "none",
    };
    style2 = {
      width: "90%",
      bgcolor: "background.paper",
      boxShadow: "0px 4px 32px 0px rgba(26, 26, 26, 0.10)",
      borderRadius: "16px",
      border: "none",
      outline: "none",
    };
  } else {
    style = {
      width: "40%",
      bgcolor: "background.paper",
      boxShadow: "0px 4px 32px 0px rgba(26, 26, 26, 0.10)",
      borderRadius: "16px",
      border: "none",
      outline: "none",
    };
    style2 = {
      width: "60%",
      bgcolor: "background.paper",
      boxShadow: "0px 4px 32px 0px rgba(26, 26, 26, 0.10)",
      borderRadius: "16px",
      border: "none",
      outline: "none",
    };
  }
  return (
    <div>
      <Modal
        open={confirm_open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className={classes["user-chat-modal-wrapper"]}>
          <Box sx={style2}>
            <div className={classes["user-chat-modal-heading"]}>
              <h1>{translate("Preview Payment")}</h1>
              <MdClose
                onClick={handleConfirmClose}
                style={{
                  color: "#FFF",
                  cursor: "pointer",
                  fontSize: "38px",
                  paddingRight: "10px",
                }}
              />
            </div>
            <div className={classes["user-chat-modal-inputs"]}>
              <div className={classes["user-chat-modal-content"]}>
                <div className="row justify-content-center">
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="custom--card card-deposit text-center">
                      <div className="card-body card-body-deposit">
                        <div className="row">
                          <div className="col-12">
                            <div className="custom--card">
                              <div className="card-header bg--base">
                                <h5 className="text-white text-center">
                                  {translate("Payment Confirm")}
                                </h5>
                              </div>
                              <div className="card-body text-center">
                                <h3 className="text-center mb-2">
                                  {translate("Please Pay")}
                                  {showAmount(
                                    confirm_data?.desposit?.final_amo
                                  )}
                                  {confirm_data?.desposit?.method_currency}
                                </h3>
                                <h3>{translate("To book the property")}</h3>
                                <Elements
                                  stripe={stripePromise}
                                  options={options}
                                >
                                  <CheckoutForm
                                    intent={confirm_data?.data?.intent}
                                    trx={confirm_data?.data?.val?.trx}
                                    redirect_url={
                                      confirm_data?.data?.redirect_url
                                    }
                                  />
                                </Elements>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </div>
      </Modal>
    </div>
  );
}

export default ConfirmModal;
