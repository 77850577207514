import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import makeHttpRequest from "../utils/api";

const Seo = () => {
  const [seoData, setSeoData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = window.location.pathname;
        let slug;

        // check that URL included /blog or NOT
        // if (url.startsWith("/blog/")) {
        //   slug = url.substring("/blog/".length); // Remove '/blog/' prefix
        // } else {
        slug = url; // Keep the entire endpoint
        // }
        if (url.includes("detail")) {
        } else {
          const response = await makeHttpRequest("POST", "seo", {
            slug: slug,
          });
          console.log("response", response?.data?.general);
          setSeoData(response?.data);
        }
      } catch (error) {
        console.error("Error fetching SEO data:", error);
      }
    };

    fetchData();
  }, []); // Empty dependency array to run the effect only once

  const defaultTitle =
    "Capri Easy Booking Online - Boat and Accomodations in Italy.";

  return (
    <>
      <Helmet>
        {/* General Metadata */}
        <title>{seoData?.general?.title}</title>
        <meta name="description" content={seoData?.general?.description} />
        <meta name="keywords" content={seoData?.general?.keywords} />
        <link rel="icon" type="image/x-icon" href={seoData?.general?.favicon} />

        {/* Open Graph (OG) Metadata */}
        <meta property="og:title" content={seoData?.seo?.og_title} />
        <meta property="og:description" content={seoData?.seo?.description} />
        <meta property="og:image" content={seoData?.seo?.og_image} />
        <meta property="og:type" content={seoData?.seo?.og_type} />
        {seoData?.seo?.og_image_width && (
          <meta
            property="og:image:width"
            content={seoData?.seo?.og_image_width}
          />
        )}
        {seoData?.seo?.og_image_heigh && (
          <meta
            property="og:image:height"
            content={seoData?.seo?.og_image_heigh}
          />
        )}

        {/* Twitter Card Metadata */}
        <meta name="twitter:card" content={seoData?.seo?.twitter_card} />

        {/* Apple Touch Icon */}
        <link
          rel="apple-touch-icon"
          href={seoData?.general?.apple_touch_icon}
        />
        <meta
          name="robots"
          content={`${seoData?.general?.is_index == 0 ? "noindex" : "index"}, ${
            seoData?.general?.is_follow == 0 ? "nofollow" : "follow"
          }`}
        />
      </Helmet>
    </>

    // <>
    //   <Helmet>
    //     {seoData &&
    //     seoData?.seoData?.general??.title &&
    //     seoData?.general?.title.trim() !== "" ? (
    //       <title>{seoData?.general?.title}</title>
    //     ) : (
    //       <title>{defaultTitle}</title>
    //     )}
    //     {seoData && seoData?.general?.description && (
    //       <meta name="description" content={seoData?.general?.description} />
    //     )}

    //     {seoData && seoData?.canonical_url && (
    //       <link
    //         rel="canonical"
    //         href={seoData.canonical_url}
    //         data-react-helmet="true"
    //       />
    //     )}

    //     {seoData && seoData.title && seoData.title !== "" && (
    //       <meta
    //         property={`og:${seoData.title}`}
    //         content={seoData?.description}
    //       />
    //     )}
    //     {seoData && seoData.keywords && (
    //       <meta name="keywords" content={seoData.keywords}></meta>
    //     )}
    //     {seoData && seoData.description && (
    //       <meta property={`og:description`} content={seoData.description} />
    //     )}
    //     <meta property="og:url" content={window.location.host} />
    //     <meta property="og:type" content="website" />
    //     <meta property="og:site_name" content={window.location.host} />

    //     {seoData && seoData.meta_robot && (
    //       <meta
    //         name="robots"
    //         content={
    //           seoData.is_index == 1
    //             ? "index"
    //             : "noindex" + "," + seoData.is_follow == 1
    //             ? "follow"
    //             : "nofollow"
    //         }
    //       />
    //     )}
    //   </Helmet>
    // </>
  );
};

export default Seo;
