import React, { useEffect, useState } from "react";
import "./AccomodationPage.css";
import MainSearchEngine from "../../Components/MainSearchEngine/MainSearchEngine";
import { BsStarFill } from "react-icons/bs";
import ContentLoader from "react-content-loader";

import {
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import { BiCheck } from "react-icons/bi";
import { BsFillStarFill } from "react-icons/bs";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { FaMapMarkerAlt } from "react-icons/fa";
import Footer from "../../Components/Footer/Footer";
import Navbar from "../../Components/Navbar/Navbar";
import { styled } from "@mui/material/styles";
import { Checkbox, FormControlLabel } from "@mui/material";
import { MenuItem, Slider, TextField } from "@mui/material";
import makeHttpRequest from "../../utils/api";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { showAmount } from "../../utils/showAmount";

import Wishlist from "../../Components/Wishlist";
import { useLanguage } from "../../utils/LanguageContext";
import CustomCarousel from "./CustomCarousel";
import ReactPaginate from "react-paginate";
import { StarBorder } from "@mui/icons-material";

const Title = styled(Typography)({
  fontFamily: "Montserrat",
  fontSize: "60px",
  fontWeight: 700,
  textAlign: "center",
  marginTop: "50px",
});

function AccomodationPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { id, slug } = useParams();
  const [accomodation, setAccomodation] = useState({
    properties: { data: [] },
  });
  const [loading, setLoading] = useState(false);
  const [section_loading, setSectionLoading] = useState(false);
  const [price, setPrice] = useState([0, 500]); // Start price range at 0
  const [lower_range, setLowerRange] = useState(0); // Start lower range at 0
  const [higher_range, setHigherRange] = useState(500); // Keep higher range at 500
  const [selected_star, setSelectedStar] = useState("");
  const [selected_type, setSelectedType] = useState("");
  const [filter_location, setFilterLocation] = useState("");
  const [filter_amenities, setFilterAmenities] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [adult, setAdult] = useState(0);
  const [child, setChild] = useState(0);
  const [multi_image_slider, setMultiImageSlider] = useState([]);
  const { translate } = useLanguage();
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedStars, setSelectedStars] = useState([]);
  const [selectedStarRatings, setSelectedStarRatings] = useState([]);

  const itemsPerPage = 5;

  useEffect(() => {
    if (location.state) {
      const res = location.state;
      setFilterLocation(res?.data?.request?.location);
      setAccomodation(res.data);
    } else if (id && slug) {
      getData();
    } else {
      //DIRECT URL
      getAccomodations();
    }
  }, [id]);

  const getData = async () => {
    try {
      setLoading(true);
      const res = await makeHttpRequest("GET",`search-location/${id}/${slug}`);
      setAccomodation(res.data);

      const objectsArray = Object.values(res?.data?.property_types);
      const isMobile = window.matchMedia("(max-width: 767px)").matches;
      let subarraySize = 0;
      if (isMobile) {
        subarraySize = 1;
      } else {
        subarraySize = 3;
      }

      const newMultiImageSlider = [];
      for (let i = 0; i < objectsArray.length; i += subarraySize) {
        const subarray = objectsArray.slice(i, i + subarraySize);
        newMultiImageSlider.push(subarray);
      }
      setMultiImageSlider(newMultiImageSlider);
      setLoading(false);
    } catch (error) {
      console.error("Error is:", error);
      setLoading(false);
    }
  };

  const getAccomodations = async () => {
    try {
      setLoading(true);
      const res = await makeHttpRequest("GET", "property");
      setAccomodation(res.data);
      const isMobile = window.matchMedia("(max-width: 767px)").matches;
      let subarraySize = 0;
      if (isMobile) {
        subarraySize = 1;
      } else {
        subarraySize = 3;
      }
      const objectsArray = Object.values(res?.data?.property_types);
      const newMultiImageSlider = [];
      for (let i = 0; i < objectsArray.length; i += subarraySize) {
        const subarray = objectsArray.slice(i, i + subarraySize);
        newMultiImageSlider.push(subarray);
      }
      setMultiImageSlider(newMultiImageSlider);
      setLoading(false);
    } catch (error) {
      console.error("Error is:", error);
      setLoading(false);
    }
  };

  const offset = currentPage * itemsPerPage;

  const filteredItems = accomodation?.properties?.data?.filter((item) => {
    if (selectedStarRatings.length === 0) return true; // Show all if no stars selected
    return selectedStarRatings.includes(item.star); // Show items that match selected stars
  });

  const currentItems =
    filteredItems?.slice(offset, offset + itemsPerPage) || [];

  const totalPages = Math.ceil(filteredItems.length / itemsPerPage);
  const renderStars = (rating) => {
    if (rating === 0) {
      return <p>No reviews found</p>; // Display message when no stars are available
    }

    const stars = [];
    for (let i = 1; i <= rating; i++) {
      stars.push(
        <BsStarFill
          key={i}
          style={{
            color: "#fec223 ", // Color filled stars yellow
            fontSize: "20px",
            marginRight: "3px",
          }}
        />
      );
    }
    return stars;
  };

  const handleStarRatingChange = (value) => {
    setSelectedStarRatings((prevSelected) => {
      if (prevSelected.includes(value)) {
        return prevSelected.filter((star) => star !== value); // Remove star if already selected
      } else {
        return [...prevSelected, value]; // Add star if not selected
      }
    });
  };

  function generateSlug(inputString) {
    const slug = inputString.toLowerCase().replace(/[^a-z0-9]+/g, "-");
    return slug.replace(/^-+|-+$/g, "");
  }

  const handleDetails = (item) => {
    if (slug) {
      navigate(`/accomodation/${item?.id}/${slug}/details`);
    } else {
      const resultSlug = generateSlug(item.name);
      navigate(`/accomodation/${item?.id}/${resultSlug}/details`);
    }
  };

  const minDistance = 10;
  const handlePriceChange = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    let pr = "";
    if (activeThumb === 0) {
      pr = [Math.min(newValue[0], price[1] - minDistance), price[1]];
    } else {
      pr = [price[0], Math.max(newValue[1], price[0] + minDistance)];
    }
    setPrice(pr);
    setLowerRange(showAmount(pr[0]));
    setHigherRange(showAmount(pr[1]));
    if (price[0] != 0 || price[1] != 500) {
      getSearchData(selected_type, filter_location, pr, filter_amenities);
    }
  };

  const handleLowerChange = (event) => {
    const value = event.target.value;
    setLowerRange(value);

    // Convert the input value back to a number and update the Slider
    const numericValue = parseInt(value, 10);
    if (!isNaN(numericValue)) {
      const newPrice = [numericValue, price[1]];
      setPrice(newPrice);
      // Trigger search with the updated price range
      getSearchData(selected_type, filter_location, newPrice, filter_amenities);
    }
  };

  const handleHigherChange = (event) => {
    const value = event.target.value;
    setHigherRange(value);

    // Convert the input value back to a number and update the Slider
    const numericValue = parseInt(value, 10);
    if (!isNaN(numericValue)) {
      const newPrice = [price[0], numericValue];
      setPrice(newPrice);
      // Trigger search with the updated price range
      getSearchData(selected_type, filter_location, newPrice, filter_amenities);
    }
  };

  const handleLocationChange = async (e) => {
    const { value } = e.target;
    setFilterLocation(value);
    // if (value) {
    getSearchData(selected_type, value, price, filter_amenities);
    // }
  };

  const handleTypeFilter = (event) => {
    const { type, value } = event.currentTarget.dataset;
    setSelectedType(value);
    getSearchData(value, filter_location, price, filter_amenities);
  };

  const handleAmenitiesChange = (event) => {
    const amenityId = parseInt(event.target.value); // Parse the ID to an integer
    const isChecked = event.target.checked;

    if (isChecked) {
      setFilterAmenities((prevSelectedAmenities) => [
        ...prevSelectedAmenities,
        amenityId,
      ]);
    } else {
      setFilterAmenities((prevSelectedAmenities) =>
        prevSelectedAmenities.filter((id) => id !== amenityId)
      );
    }
    getSearchData(selected_type, filter_location, price, filter_amenities);
  };

  const getSearchData = async (type, loc, pr, amen, str) => {
    // alert("e");
    let url_data = {
      type: "property",
      property_type: type,
      location: loc,
      start: startDate,
      end: endDate,
      adult: adult,
      child: child,
      price: pr,
      amenities: amen,
      star: str,
    };

    try {
      setSectionLoading(true);
      const res = await makeHttpRequest(
        "GET",
        `search?propertyType=${url_data.property_type}&type=${url_data.type}&location=${url_data.location}&start=${url_data.start}&end=${url_data.end}&adult=${url_data.adult}&child=${url_data.child}&amenities=${url_data.amenities}&price=${url_data.price}&star=${url_data.star}`
      );
      setAccomodation(res.data);
      setSectionLoading(false);
    } catch (error) {
      console.error("Error is:", error);
      setLoading(false);
    }
  };

  const handlePageClick = (data) => {
    setCurrentPage(data?.selected);
  };

  const handleStarRating = (value) => {
    setSelectedStar(value);
    if (value) {
      getSearchData(
        selected_type,
        filter_location,
        price,
        filter_amenities,
        value
      );
    }
  };

  return (
    <div>
      <Navbar />
      <div className="container">
        <div style={{ marginBottom: "70px", marginTop: "40px" }}>
          <MainSearchEngine
            locationId={filter_location}
            accom={setAccomodation}
          />
        </div>
      </div>

      {!loading && (
        <>
          <CustomCarousel />
          <div className="container">
            {!loading && (
              <div
                className="row"
                style={{ marginTop: "30px", marginBottom: "80px" }}
              >
                <div className="col-lg-3 col-md-4 col-12 d-none d-lg-block">
                  <div className="accomodationList-right-bg ">
                    <Card sx={{ background: "none", boxShadow: "none" }}>
                      {/* Property Type */}
                      <div>
                        <h6>{translate("Property Type")}</h6>
                        <TextField
                          select
                          fullWidth
                          className="mainSearch-dropdown"
                          labelId="demo-simple-select-label"
                          variant="outlined"
                          id="demo-simple-select"
                          style={{
                            marginTop: "1rem",
                            backgroundColor: "#F3F3F5",
                            border: "none",
                          }}
                          // name="type_filter"
                          value={selected_type}
                          label={translate("Search by type")}
                        >
                          <MenuItem
                            onClick={handleTypeFilter}
                            key="0"
                            value=""
                            selected={selected_type === ""}
                          >
                            {translate("Any")}
                          </MenuItem>
                          {accomodation?.property_types?.length > 0 &&
                            accomodation?.property_types?.map((type, index) => (
                              <MenuItem
                                // key={type.id}
                                onClick={handleTypeFilter}
                                value={type.id}
                                data-type={type.type}
                                selected={selected_type === type.id}
                              >
                                {translate(type.name)}
                              </MenuItem>
                            ))}
                        </TextField>
                        <hr />
                      </div>
                      <div>
                        <h6>{translate("Location")}</h6>
                        <TextField
                          select
                          fullWidth
                          className="mainSearch-dropdown"
                          labelId="demo-simple-select-label"
                          variant="outlined"
                          id="demo-simple-select"
                          style={{
                            marginTop: "1rem",
                            backgroundColor: "#F3F3F5",
                            border: "none",
                          }}
                          value={filter_location}
                          label={translate("Location of Accomodation")}
                          onChange={handleLocationChange}
                        >
                          <MenuItem
                            key="0"
                            value=""
                            selected={filter_location === "" ? "selected" : ""}
                          >
                            {translate("Any")}
                          </MenuItem>
                          {accomodation?.locations?.length > 0 &&
                            accomodation?.locations?.map((location, index) => (
                              <MenuItem
                                value={location.id}
                                selected={
                                  filter_location === location.id
                                    ? "selected"
                                    : ""
                                }
                              >
                                {translate(location.name)}
                              </MenuItem>
                            ))}
                        </TextField>
                        <hr />
                      </div>
                      <Typography id="range-slider" gutterBottom>
                        <h6 className="fw-bold">Select Price Range:</h6>
                      </Typography>
                      <div className="slider-wrapping">
                        <Slider
                          getAriaLabel={() => translate("Price Range")}
                          value={price}
                          min={0} // Set minimum value to 0
                          max={800} // Adjust maximum as needed
                          step={50}
                          onChange={handlePriceChange}
                          valueLabelDisplay="auto"
                          disableSwap
                        />
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginTop: "1rem",
                          }}
                        >
                          <Typography
                            style={{
                              backgroundColor: "#F3F3F5",
                              padding: "0px",
                              borderRadius: "5px",
                              display: "flex",
                            }}
                          >
                            {/* {accomodation?.curr_sym} {showAmount(price[0])} */}
                            <span>{accomodation?.curr_sym} </span>
                            <input
                              type="number"
                              value={lower_range}
                              onChange={handleLowerChange}
                              style={{
                                width: `${6}ch`,
                                border: "1px solid gray",
                                borderRadius: "5px",
                                marginLeft: "5px",
                              }}
                            />
                          </Typography>
                          <Typography>{translate("-")}</Typography>
                          <Typography
                            style={{
                              backgroundColor: "#F3F3F5",
                              padding: "0px",
                              borderRadius: "5px",
                              display: "flex",
                            }}
                          >
                            {/* {accomodation?.curr_sym} {showAmount(price[1])} */}
                            <span>{accomodation?.curr_sym} </span>
                            <input
                              type="number"
                              value={higher_range}
                              onChange={handleHigherChange}
                              style={{
                                width: `${6}ch`,
                                border: "1px solid gray",
                                borderRadius: "5px",
                                marginLeft: "5px",
                              }}
                            />
                          </Typography>
                        </div>
                      </div>
                      <hr />
                      {/* Amenities */}
                      <div>
                        <h6 className="fw-bold">
                          {translate("Amenities")}
                          {/* Property facilities & services */}
                        </h6>
                        {accomodation?.amenities?.map((am, index) => (
                          <div>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name={am.id}
                                  value={am.id.toString()}
                                  checked={filter_amenities.includes(am.id)} // Check if the amenity is selected
                                  onChange={handleAmenitiesChange}
                                />
                              }
                              label={translate(am.name)}
                            />
                          </div>
                        ))}
                      </div>
                      <hr />
                      <div>
                        <h6 className="fw-bold">{translate("Star Rating")}</h6>
                        {[1, 2, 3, 4, 5].map((star) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={selectedStarRatings.includes(star)}
                                onChange={() => handleStarRatingChange(star)}
                              />
                            }
                            label={
                              <span>
                                {[...Array(star)].map((_, index) => (
                                  <BsStarFill
                                    key={index}
                                    style={{ color: "rgb(254, 194, 35)" }}
                                  /> // Gold-colored stars
                                ))}
                              </span>
                            }
                            key={star}
                            style={{ display: "block" }}
                          />
                        ))}
                      </div>
                    </Card>
                  </div>
                </div>
                <div className="col-lg-9 col-md-8 col-12 mt-5">
                  {section_loading ? (
                    // Use ContentLoader for loading state
                    <ContentLoader
                      speed={2}
                      width="100%"
                      height={300} // Set height according to your needs
                      viewBox="0 0 100% 300" // Adjust the viewBox
                      backgroundColor="#f3f3f3"
                      foregroundColor="#ecebeb"
                    >
                      {/* Define the shape of the loader */}
                      <rect
                        x="0"
                        y="0"
                        rx="5"
                        ry="5"
                        width="100%"
                        height="150"
                      />
                      <rect
                        x="0"
                        y="160"
                        rx="5"
                        ry="5"
                        width="100%"
                        height="20"
                      />
                      <rect
                        x="0"
                        y="190"
                        rx="5"
                        ry="5"
                        width="100%"
                        height="20"
                      />
                      <rect
                        x="0"
                        y="220"
                        rx="5"
                        ry="5"
                        width="100%"
                        height="20"
                      />
                      <rect
                        x="0"
                        y="250"
                        rx="5"
                        ry="5"
                        width="100%"
                        height="20"
                      />
                    </ContentLoader>
                  ) : currentItems.length === 0 ? (
                    <div
                      style={{
                        width: "100%",
                        textAlign: "center",
                        backgroundColor: "#fff",
                        border: "1px solid #7c7777",
                        padding: "20px",
                      }}
                    >
                      <h2>{translate(accomodation?.empty_message)}</h2>
                    </div>
                  ) : (
                    currentItems.map((item, index) => (
                      <div className="accomodationList-left-bg" key={index}>
                        <div className="row">
                          <div className="col-lg-4 col-md-8 col-sm-12">
                            <Card style={{ borderRadius: "10px" }}>
                              <CardMedia>
                                <div
                                  id={`carouselExampleIndicators_${index}`}
                                  className="carousel slide"
                                  data-bs-ride="carousel"
                                >
                                  <div className="carousel-indicators">
                                    {item?.image?.map((image, i) => (
                                      <button
                                        type="button"
                                        data-bs-target={`#carouselExampleIndicators_${index}`}
                                        data-bs-slide-to={i}
                                        className={i === 0 ? "active" : ""}
                                        aria-current={
                                          i === 0 ? "true" : "false"
                                        }
                                        key={i}
                                      ></button>
                                    ))}
                                  </div>
                                  <div className="carousel-inner accomodation-card-image">
                                    {item?.image?.map((image, i) => (
                                      <div
                                        className={` carousel-item ${
                                          i === 0 ? "active" : ""
                                        }`}
                                        key={i}
                                      >
                                        <img
                                          src={image}
                                          className="d-block w-100"
                                          alt={` Slide ${i + 1}`}
                                        />
                                      </div>
                                    ))}
                                  </div>
                                  <div className="boat-card-offer">
                                    <h4>
                                      {item?.discount} {translate("off")}
                                    </h4>
                                    <Wishlist id={item.id} type={"property"} />
                                  </div>
                                </div>
                              </CardMedia>
                            </Card>
                          </div>
                          <div className="col-lg-8 col-md-8 col-sm-12 p-0">
                            <CardContent className="pb-0 pt-0">
                              <div
                                className={` accomodation-nameWrapper ${
                                  item?.is_complete === 1
                                    ? "accomodation-nameWrapper-bagde"
                                    : ""
                                }  `}
                              >
                                <div className="accomodation-card-location">
                                  <h1>{item?.name}</h1>
                                  <p>
                                    <FaMapMarkerAlt
                                      style={{
                                        color: "#8BC53E",
                                        marginRight: "7px",
                                        fontSize: "15px",
                                      }}
                                    />
                                    <span>{item?.location}</span>
                                  </p>
                                </div>
                                <div className="accomdation-discount-wrapper">
                                  {item?.top_rated === 1 && (
                                    <p className="accomdation-discount">
                                      {translate("Top Rated")}
                                    </p>
                                  )}
                                  {item?.top_reviewed === 1 && (
                                    <p className="accomdation-discount">
                                      {translate("Top Reviewed")}
                                    </p>
                                  )}
                                  {item?.is_complete === 1 && (
                                    <p className="accomdation-discount-sole">
                                      {translate("Sole")}
                                    </p>
                                  )}
                                  {!item?.is_complete && (
                                    <div className="accomdation-discount-star">
                                      {renderStars(item?.star)}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="shiplist-card-discription">
                                {item?.extra_features?.map((feature, i) => (
                                  <p key={i}>
                                    <BiCheck
                                      style={{
                                        color: "#8BC53E",
                                        fontSize: "25px",
                                      }}
                                    />
                                    {feature}
                                  </p>
                                ))}
                              </div>
                              <div className="accomodation-card-night-wrapper">
                                <div className="accomodation-card-Night">
                                  {item?.is_complete === 1 && (
                                    <h3>
                                      {accomodation?.curr_sym}
                                      {showAmount(item?.price)}/{" "}
                                      <span>{translate(item.label)}</span>
                                    </h3>
                                  )}
                                  {!item?.is_complete && (
                                    <h3>
                                      {accomodation?.curr_sym}
                                      {showAmount(item?.price1)} -{" "}
                                      {accomodation?.curr_sym}
                                      {showAmount(item?.price2)}/{" "}
                                      <span>{translate(item.label)}</span>
                                    </h3>
                                  )}
                                  <p>{translate("Includes taxes & fees")}</p>
                                </div>
                              </div>
                              <div className="accomodation-card-deatil-button">
                                <p>
                                  <BsFillStarFill
                                    style={{
                                      color: "#FFF",
                                      marginRight: "7px",
                                      fontSize: "20px",
                                      background: "#8BC53E",
                                      padding: "4px",
                                      borderRadius: "5px",
                                    }}
                                  />
                                  {item?.rating}
                                  <span className="review">
                                    ({item?.review || 0} {translate("Reviews")})
                                  </span>
                                </p>
                                <button onClick={() => handleDetails(item)}>
                                  {translate("View Detail")}
                                </button>
                              </div>
                            </CardContent>
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                  <ReactPaginate
                    previousLabel={
                      currentPage > 0 ? (
                        <span className="pagination-button">Previous</span>
                      ) : null
                    }
                    nextLabel={
                      currentPage < totalPages - 1 ? (
                        <span className="pagination-button">Next</span>
                      ) : null
                    }
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={totalPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
            )}
          </div>
        </>
      )}

      {!loading && (
        <div className="accomodationPage-footer">
          <Footer />
        </div>
      )}
    </div>
  );
}

export default AccomodationPage;
