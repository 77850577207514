import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useLanguage } from "../../utils/LanguageContext";
import makeHttpRequest from "../../utils/api";
import { setStateBasedOnDomain } from "../../utils/helpers";
import { clearUser } from "../UserProfile/userSlice";
import Requestest from "./Requestest";
import Wishlist from "../Wishlist/Wishlist";
import PropertyHistory from "../PropertyHistory/PropertyHistory";
import BoatBookingHistory from "../BoatBookingHistory/BoatBookingHistory";
import Reviews from "../Reviews/Reviews";
import SupportTickets from "../SupportTickets/SupportTickets";
import ChangePassword from "../ChangePassword/ChangePassword";
import UserProfile from "../UserProfile/UserProfile";
import { MdOutlineMessage } from "react-icons/md";
import UserChat from "../../Pages/chatbot/user/UserChat";
import UserProfileEdit from "../UserProfileEdit/UserProfileEdit";

const UserDashboard = () => {
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState(
    location.state?.selectedTab || 0
  ); // Default to 0 or the passed tab
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // State for controlling sidebar visibility
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("lang") || setStateBasedOnDomain()
  );
  const { translate, fetchLangData } = useLanguage();
  const [languages, setLanguages] = useState([]);

  const fetchLanguages = useCallback(async () => {
    try {
      const res = await makeHttpRequest("GET", "language");
      setLanguages(res?.data?.languages || []);
    } catch (error) {}
  }, []);

  useEffect(() => {
    fetchLanguages();
  }, [fetchLanguages]);

  const handleTabChange = (index) => {
    setSelectedTab(index);
    setIsSidebarOpen(false); // Close sidebar when a tab is selected in mobile view
  };

  const handleToggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value;
    setSelectedLanguage(newLanguage);
    fetchLangData(newLanguage);
  };

  const handleLogout = () => {
    dispatch(clearUser());
    localStorage.removeItem("bearerToken");
    localStorage.removeItem("userType");
    localStorage.removeItem("userEmail");
    navigate("/");
  };

  const handleMesssage = () => {
    setSelectedTab(8); // Set the selected tab to the chat tab
  };

  const handleImageLink = () => {
    navigate("/");
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen); // Toggle sidebar open/close
  };

  const tabs = [
    "Availability Request",
    "Wishlist",
    "Property Booking History",
    "Boat Booking History",
    "Reviews",
    "Support Tickets",
    "Change Password",
    "Profile",
    "Chats",
    "Logout",
  ];

  const renderContent = () => {
    switch (selectedTab) {
      case 0:
        return <Requestest />;
      case 1:
        return <Wishlist />;
      case 2:
        return <PropertyHistory />;
      case 3:
        return <BoatBookingHistory />;
      case 4:
        return <Reviews />;
      case 5:
        return <SupportTickets />;
      case 6:
        return <ChangePassword />;
      case 7:
        return <UserProfileEdit />;
      case 8:
        return <UserChat />;
      case 9:
        handleLogout();
        return null;
      default:
        return null;
    }
  };

  return (
    <div className="dashboard-container">
      {/* Sidebar */}
      <div className={`user-dashboard-sidebar ${isSidebarOpen ? "open" : ""}`}>
        <Link to="/">
          <img
            src="/assets/icons/dashboardLogo.png"
            alt="Logo"
            className="sidebar-logo"
            onClick={handleImageLink}
          />
        </Link>
        <ul className="tab-list">
          {tabs.map((tab, index) => (
            <li
              key={index}
              onClick={() => handleTabChange(index)}
              className={selectedTab === index ? "active" : ""}
            >
              {tab}
            </li>
          ))}
        </ul>
      </div>

      {/* Main Content */}
      <div className={`main-content ${isSidebarOpen ? "full-width" : ""}`}>
        <header className="user-dashboard-header">
          {/* Hamburger Menu */}
          <div className="hamburger" onClick={toggleSidebar}>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div>
            <select
              value={selectedLanguage}
              onChange={handleLanguageChange}
              className="language-dropdown mr-3"
            >
              {languages.map((lan) => (
                <option key={lan.code} value={lan.code}>
                  {translate(lan.name)}
                </option>
              ))}
            </select>
            <button onClick={handleMesssage} className="logout-btn">
              <span>
                <MdOutlineMessage />
              </span>
            </button>
          </div>
        </header>

        {isSidebarOpen && (
          <div className="sidebar-backdrop" onClick={handleToggleSidebar}></div>
        )}

        {/* Render Tab Content */}
        <div style={{ marginTop: "16px" }}>{renderContent()}</div>
      </div>
    </div>
  );
};

export default UserDashboard;
