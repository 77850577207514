import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import classes from "./UserChat.module.css";
import { MdClose } from "react-icons/md";
import makeHttpRequest from "../../../utils/api";
import Toaster from "../../../Components/Toaster";
import { useLanguage } from "../../../utils/LanguageContext";
import { showAmount } from "../../../utils/showAmount";
import "../ChatStyle.css";
import "react-chat-elements/dist/main.css";
import "bootstrap/dist/css/bootstrap.min.css";

function PaymentModal() {
  const [preview_open, setPreviewOpen] = useState(false);
  const { translate } = useLanguage();
  const handlePreviewClose = () => setPreviewOpen(false);
  const [loading, setLoading] = useState(false);
  const [preview_data, setPreviewData] = useState();
  const [payment_open, setPaymentOpen] = useState(false);
  const handlePaymentClose = () => setPaymentOpen(false);
  const [booked_payment_gateway, setBookedPaymentGateway] = useState();
  const [booked_id, setBookedId] = useState();
  const [booked_type, setBookedType] = useState();

  let style = {};
  let style2 = {};
  const isMobile = window.innerWidth <= 767;
  if (isMobile) {
    style = {
      width: "90%",
      bgcolor: "background.paper",
      boxShadow: "0px 4px 32px 0px rgba(26, 26, 26, 0.10)",
      borderRadius: "16px",
      border: "none",
      outline: "none",
    };
    style2 = {
      width: "90%",
      bgcolor: "background.paper",
      boxShadow: "0px 4px 32px 0px rgba(26, 26, 26, 0.10)",
      borderRadius: "16px",
      border: "none",
      outline: "none",
    };
  } else {
    style = {
      width: "40%",
      bgcolor: "background.paper",
      boxShadow: "0px 4px 32px 0px rgba(26, 26, 26, 0.10)",
      borderRadius: "16px",
      border: "none",
      outline: "none",
    };
    style2 = {
      width: "60%",
      bgcolor: "background.paper",
      boxShadow: "0px 4px 32px 0px rgba(26, 26, 26, 0.10)",
      borderRadius: "16px",
      border: "none",
      outline: "none",
    };
  }

  const handlePayNow = async (currency, method_code) => {
    try {
      setLoading(true);
      const res = await makeHttpRequest("POST", "user/custom-payment/insert", {
        id: booked_id,
        type: booked_type,
        currency: currency,
        method_code: method_code,
      });
      setLoading(false);
      if (res.success) {
        setPreviewData(res.data);
        setPreviewOpen(true);
      } else {
        Toaster("error", "Error");
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div>
      <Modal
        open={payment_open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className={classes["user-chat-modal-wrapper"]}>
          <Box sx={style2}>
            <div className={classes["user-chat-modal-heading"]}>
              <h1>{translate("Payment Gateways")}</h1>
              <MdClose
                onClick={handlePaymentClose}
                style={{
                  color: "#FFF",
                  cursor: "pointer",
                  fontSize: "38px",
                  paddingRight: "10px",
                }}
              />
            </div>
            <div className={classes["user-chat-modal-inputs"]}>
              <div className={classes["user-chat-modal-content"]}>
                <div className="row">
                  {booked_payment_gateway?.gatewayCurrency?.map(
                    (gateway, index) => (
                      <div key={index} className="col-6">
                        <div className="row">
                          <div className="col-12 m-2">
                            <h4 className="text-center">{gateway?.name}</h4>
                          </div>
                          <div className="col-12 d-flex justify-content-center m-2">
                            <img
                              src={gateway?.image}
                              alt={gateway?.name}
                              width={200}
                              height={200}
                            />
                          </div>
                          <div className="col-12 d-flex justify-content-center  m-2">
                            <button
                              className="btn btn-outline-info"
                              onClick={() =>
                                handlePayNow(
                                  gateway?.currency,
                                  gateway?.method_code
                                )
                              }
                            >
                              {translate("Pay Now")}
                            </button>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </Box>
        </div>
      </Modal>
    </div>
  );
}

export default PaymentModal;
